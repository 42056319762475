import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'values',
})
export class ValuesPipe implements PipeTransform {
    transform(object: { [key: string]: string }, delimiter: string): string {
        return Object.values(object).join(delimiter);
    }
}
