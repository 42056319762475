import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { InfoModalComponent } from './info-modal.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [InfoModalComponent],
    exports: [InfoModalComponent],
    imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class InfoModalModule {}
