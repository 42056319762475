import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RatioBarWidgetComponent } from './ratio-bar-widget.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [RatioBarWidgetComponent],
    imports: [CommonModule, MatIconModule],
    exports: [RatioBarWidgetComponent],
})
export class RatioBarWidgetModule {}
