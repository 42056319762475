import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'replaceString' })
export class ReplaceStringPipe implements PipeTransform {
    transform(
        string: string,
        searchValue: RegExp,
        replaceValue: string,
    ): string {
        return string.replace(searchValue, replaceValue).trim();
    }
}
