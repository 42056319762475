import * as moment from 'moment';
import { IParticlesProps } from 'ng-particles';
import {
    MoveDirection,
    OutMode,
    StartValueType,
    DestroyType,
} from 'tsparticles-engine';
import { DateRange } from '../models/date-range.enum';
import { AnchorCountV2Modal } from '../../components/metric-modals/anchor-count-v2.modal';
import { ComponentType } from '@angular/cdk/portal';
import { TagBatteriesV2Modal } from '../../components/metric-modals/tag-batteries-v2.modal';
import { HddV1Modal } from '../../components/metric-modals/hdd-v1.modal';
import { UptimeV1Modal } from '../../components/metric-modals/uptime-v1.modal';
import { SystemPerformanceV1Modal } from '../../components/metric-modals/system-performance-v1.modal';

export const COMPANIES_API_URL = '/api/companies';
export const SITES_API_URL = '/api/sites';
export const PERMISSIONS_API_URL = '/api/permissions';
export const VERSION_API_URL = '/api/version';
export const USERS_ENDPOINT = 'users';
export const INVITE_MODAL_WIDTH = '530px';
export const RTLS_PLAYER_MODAL_WIDTH = '530px';
export const CUSTOM_MODAL_WIDTH = '200px';
export const INFO_MODAL_WIDTH = '600px';
export const LARGE_MODAL_WIDTH = '800px';
export const VERY_LARGE_MODAL_WIDTH = '1000px';
export const FEATURES_ENDPOINT = 'features';
export const SMTP_ENDPOINT = 'smtp';
export const LICENSE_ENDPOINT = 'license';
export const METRICS_ENDPOINT = 'metrics';
export const PLAYERS_ENDPOINT = 'rtls-players';
export const PLAYERS_OPTIONS_ENDPOINT = 'options';
export const DEVICE_CARE_ENDPOINT = 'device-care';
export const METRIC_OVERRIDES_ENDPOINT = 'overrides';
export const PHONE_CALL_ENDPOINT = 'phone-call';
export const TEST_ENDPOINT = 'test';
export const ACTIVATE_ENDPOINT = 'activate';
export const DEPLOYMENTS_ENDPOINT = 'deployments';
export const BUNDLES_ENDPOINT = 'bundles';
export const RELEASES_ENDPOINT = 'releases';
export const CLEAR_ENDPOINT = 'clear';
export const CREATE_TEMPLATES_ENDPOINT = 'create-templates';
export const ALERTS_ENDPOINT = 'alerts';
export const INSTALLATION_ENDPOINT = 'installation';
export const CURRENT_ALERTS_ENDPOINT = 'current';
export const REPORTS_ENDPOINT = 'reports';
export const ACTIVITIES_ENDPOINT = 'system-review';
export const DOWNLOAD_ENDPOINT = 'download';

export const SNACK_BAR_DURATION = 2000;
export const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const THRESHOLD_MARK_AREA_COLOR = 'rgba(255, 173, 177, 0.2)';
export const X_AXIS_LABEL_FORMAT = 'YYYY-MM-DD HH:mm';

export const DEFAULT_PERIOD = {
    dateRange: DateRange.WEEK,
    from: moment().startOf('isoWeek').toDate(),
    to: moment().startOf('isoWeek').add(1, 'weeks').toDate(),
};

export const DIM_START_TIME = 0;
export const DIM_END_TIME = 1;
export const DIM_STATE_VALUE = 2;
export const DIM_STATE_ID = 3;
export const HEIGHT_RATIO = 0.9;

export const NUMBER_OF_DAYS_TO_START_EXPIRING = 31;

export const XSmall = '(max-width: 1000px)';
export const Small = '(max-width: 1000px)';
export const Medium = '(max-width: 1280px)';

export const PARTICLES_OPTIONS: IParticlesProps = {
    fullScreen: {
        zIndex: 127,
    },
    fpsLimit: 120,
    particles: {
        color: {
            value: ['#059b39', '#21f86b', '#024a1b'],
        },
        life: {
            duration: {
                sync: true,
                value: 5,
            },
            count: 1,
        },
        move: {
            enable: true,
            gravity: {
                enable: true,
                acceleration: 10,
            },
            speed: {
                min: 10,
                max: 50,
            },
            direction: MoveDirection.none,
            straight: false,
            outModes: {
                default: OutMode.destroy,
                top: OutMode.none,
            },
        },
        number: {
            value: 0,
        },
        opacity: {
            value: 1,
            animation: {
                enable: true,
                minimumValue: 0,
                speed: 2,
                startValue: StartValueType.max,
                destroy: DestroyType.min,
            },
        },
        shape: {
            type: ['circle', 'square'],
        },
        size: {
            value: { min: 1, max: 5 },
        },
        rotate: {
            value: {
                min: 0,
                max: 360,
            },
            direction: 'random',
            move: true,
            animation: {
                enable: true,
                speed: 60,
            },
        },
        tilt: {
            direction: 'random',
            enable: true,
            move: true,
            value: {
                min: 0,
                max: 360,
            },
            animation: {
                enable: true,
                speed: 60,
            },
        },
        roll: {
            darken: {
                enable: true,
                value: 25,
            },
            enable: true,
            speed: {
                min: 15,
                max: 25,
            },
        },
        wobble: {
            distance: 30,
            enable: true,
            move: true,
            speed: {
                min: -15,
                max: 15,
            },
        },
    },
    autoPlay: true,
    emitters: {
        startCount: 400,
        position: {
            x: 50,
            y: 0,
        },
        size: {
            width: 0,
            height: 0,
        },
        rate: {
            delay: 0,
            quantity: 0,
        },
        life: {
            duration: 0.1,
            count: 1,
        },
    },
    detectRetina: true,
};

export const METRIC_MODAL_MAPPINGS: { [key: string]: ComponentType<unknown> } =
    {
        'anchor-count:2024.08.27': AnchorCountV2Modal,
        'tag-batteries:2024.08.27': TagBatteriesV2Modal,
        'hdd:1970.01.01': HddV1Modal,
        'uptime:1970.01.01': UptimeV1Modal,
        'system-performance:1970.01.01': SystemPerformanceV1Modal,
    };
