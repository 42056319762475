import { Action, createReducer, on } from '@ngrx/store';
import { RoutePath } from '../../shared/models/route-path.enum';
import { updateRouteAction } from './route.actions';

export interface RouteState {
    page: RoutePath;
    id?: string;
    feature?: string;
    hidePanels?: boolean;
}

const initialState: RouteState = {
    page: RoutePath.SITES,
};

const reducer = createReducer(
    initialState,
    on(updateRouteAction, (state, { page, id, feature, hidePanels, start }) => {
        if (start) {
            return state;
        }

        return {
            ...state,
            page,
            id,
            feature,
            hidePanels,
        };
    }),
);

export function routeReducer(
    state: RouteState | undefined,
    action: Action,
): RouteState {
    return reducer(state, action);
}
