import { NgModule } from '@angular/core';
import { SitesView } from './sites.view';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild([]),
        MatInputModule,
        MatExpansionModule,
    ],
    declarations: [SitesView],
    providers: [],
})
export class SitesModule {}
