import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { SnackBarType } from '../../shared/models/snack-bar-config.model';
import { getCompaniesFailureAction } from '../companies/companies.actions';
import { getPermissionsFailureAction } from '../permissions/permissions.actions';
import {
    activateDeviceCareFailureAction,
    clearDeviceCareFailureAction,
    createSiteTemplatesFailureAction,
    deleteUserFailureAction,
    deploySiteFailureAction,
    downloadSiteDeploymentFailureAction,
    editUserPermissionsFailureAction,
    getPhoneCallFailureAction,
    getPlayerOptionsFailureAction,
    getSiteDeviceCareConfigFailureAction,
    getSiteFailureAction,
    getSiteFeaturesFailureAction,
    getSiteLicenseRawFailureAction,
    getSiteMetricDataFailureAction,
    getSiteMetricsConfigFailureAction,
    getSitePlayerFailureAction,
    getSitesFailureAction,
    getSiteUsersFailureAction,
    initializeSitePlayerFailureAction,
    inviteUserFailureAction,
    updateSiteMetricSettingsFailureAction,
    updateSitePlayerFailureAction,
    updateSiteReportSettingsFailureAction,
} from '../sites/sites.actions';
import { showSnackBarAction } from '../snack-bar/snack-bar.actions';

@Injectable()
export class ErrorsEffects {
    handleError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                getCompaniesFailureAction,
                getPermissionsFailureAction,
                getSitesFailureAction,
                deleteUserFailureAction,
                getSiteUsersFailureAction,
                editUserPermissionsFailureAction,
                inviteUserFailureAction,
                getSiteFailureAction,
                getSiteFeaturesFailureAction,
                getSiteMetricsConfigFailureAction,
                getSiteDeviceCareConfigFailureAction,
                updateSiteMetricSettingsFailureAction,
                updateSiteReportSettingsFailureAction,
                updateSitePlayerFailureAction,
                initializeSitePlayerFailureAction,
                createSiteTemplatesFailureAction,
                clearDeviceCareFailureAction,
                downloadSiteDeploymentFailureAction,
                deploySiteFailureAction,
                activateDeviceCareFailureAction,
                getSiteLicenseRawFailureAction,
                getSiteMetricDataFailureAction,
                getPhoneCallFailureAction,
                getPlayerOptionsFailureAction,
                getSitePlayerFailureAction,
            ),
            map(({ error, type }) => {
                console.error(type);
                console.error(error);
                return showSnackBarAction({
                    config: {
                        type: SnackBarType.FAILED,
                        description: error.error.message,
                        actionLabel: 'Dismiss',
                    },
                });
            }),
        ),
    );

    constructor(private readonly actions$: Actions) {}
}
