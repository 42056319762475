import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { AlertCalendarWidgetModule } from '../alert-calendar-widget/alert-calendar-widget.module';
import { RatioBarWidgetModule } from '../ratio-bar-widget/ratio-bar-widget.module';
import { DashboardReportsModule } from '../dashboard-reports/dashboard-reports.module';
import { SingleStatWidgetModule } from '../single-stat-widget/single-stat-widget.module';
import { DeviceCareDashboardComponent } from './device-care-dashboard.component';
import { StateTimelineModule } from '../state-timeline/state-timeline.module';

@NgModule({
    declarations: [DeviceCareDashboardComponent],
    exports: [DeviceCareDashboardComponent],
    imports: [
        CommonModule,
        SingleStatWidgetModule,
        RatioBarWidgetModule,
        AlertCalendarWidgetModule,
        DashboardReportsModule,
        MatButtonModule,
        StateTimelineModule,
    ],
})
export class DeviceCareDashboardModule {}
