import { Component, OnInit } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { SiteDeploymentType } from '@myrtls/api-interfaces';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { AppState } from '../../store';
import { activateDeviceCareRequestAction } from '../../store/sites/sites.actions';
import {
    $deviceCareInstallation,
    $metricsStatus,
    $settingsData,
} from '../../store/sites/sites.selectors';

@Component({
    selector: 'myrtls-device-care-installation',
    templateUrl: './device-care-installation.component.html',
    styleUrls: ['./device-care-installation.component.scss'],
})
export class DeviceCareInstallationComponent implements OnInit {
    installationRequirementsFormGroup!: UntypedFormGroup;
    installationTypeFormGroup!: UntypedFormGroup;

    settings$ = this.store.select($settingsData);
    metricsStatus$ = this.store.select($metricsStatus);
    deviceCareInstallation$ = this.store.select($deviceCareInstallation);

    deployment = environment.deployment;
    SiteDeploymentType = SiteDeploymentType;

    selectedTabIndex = 0;

    constructor(
        private readonly store: Store<AppState>,
        private formBuilder: UntypedFormBuilder,
    ) {}

    ngOnInit() {
        this.installationTypeFormGroup = this.formBuilder.group({
            studioType: ['native', Validators.required],
        });

        this.installationRequirementsFormGroup = this.formBuilder.group({
            osVersion: [false, Validators.requiredTrue],
            rtlsStudioVersion: [false, Validators.requiredTrue],
            networkConnection: [false, Validators.requiredTrue],
            deviceLogsEnabled: [false, Validators.requiredTrue],
        });
    }

    finish() {
        this.store.dispatch(activateDeviceCareRequestAction());
    }
}
