import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
    RtlsPlayerOptions,
    RtlsPlayerStates,
    SiteFeature,
} from '@myrtls/api-interfaces';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { SnackBarType } from '../../shared/models/snack-bar-config.model';
import { RTLS_PLAYER_MODAL_WIDTH } from '../../shared/utils/constants';
import { AppState } from '../../store';
import {
    initializeSitePlayerRequestAction,
    stopPoolingSitePlayerRequestAction,
    updateSitePlayerRequestAction,
} from '../../store/sites/sites.actions';
import {
    $playerOptions,
    $siteFeature,
    $sitePlayer,
} from '../../store/sites/sites.selectors';
import { showSnackBarAction } from '../../store/snack-bar/snack-bar.actions';
import {
    RtlsPlayerModalType,
    StartRtlsPlayerModalComponent,
} from '../start-rtls-player-modal/start-rtls-player-modal.component';

@Component({
    selector: 'myrtls-rtls-player',
    templateUrl: './rtls-player.component.html',
    styleUrls: ['./rtls-player.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RtlsPlayerComponent implements OnDestroy {
    sitePlayer$ = this.store.select($sitePlayer);
    playerOptions$ = this.store.select($playerOptions);
    playerEditFeature$ = this.store.select(
        $siteFeature(SiteFeature.RTLS_PLAYER_EDIT),
    );

    nextRenewal = moment().endOf('month').diff(moment(), 'days') + 1;
    playerStates = RtlsPlayerStates;
    modalTypes = RtlsPlayerModalType;

    constructor(
        public dialog: MatDialog,
        private readonly store: Store<AppState>,
    ) {}

    ngOnDestroy() {
        this.store.dispatch(stopPoolingSitePlayerRequestAction());
    }

    getPlayerStateClass(state: RtlsPlayerStates) {
        switch (state) {
            case RtlsPlayerStates.STOPPED:
            case RtlsPlayerStates.DELETED:
                return 'player-state-undefined';
            case RtlsPlayerStates.STOPPING:
            case RtlsPlayerStates.STOP_REQUEST:
            case RtlsPlayerStates.STARTING:
            case RtlsPlayerStates.START_REQUEST:
            case RtlsPlayerStates.DELETE_RUNNING_REQUEST:
            case RtlsPlayerStates.DELETE_STOPPED_REQUEST:
            case RtlsPlayerStates.DELETING:
            case RtlsPlayerStates.INSTALLING:
            case RtlsPlayerStates.INSTALL_REQUEST:
                return 'player-state-warning';
            case RtlsPlayerStates.RUNNING:
                return 'player-state-ok';
            default:
                return '';
        }
    }

    getPlayerStateText(state: RtlsPlayerStates) {
        switch (state) {
            case RtlsPlayerStates.STOPPED:
                return 'STOPPED';
            case RtlsPlayerStates.DELETED:
                return 'DELETED';
            case RtlsPlayerStates.STOPPING:
            case RtlsPlayerStates.STOP_REQUEST:
                return 'STOPPING';
            case RtlsPlayerStates.STARTING:
            case RtlsPlayerStates.START_REQUEST:
                return 'STARTING';
            case RtlsPlayerStates.DELETE_RUNNING_REQUEST:
            case RtlsPlayerStates.DELETE_STOPPED_REQUEST:
            case RtlsPlayerStates.DELETING:
                return 'DELETING';
            case RtlsPlayerStates.INSTALLING:
            case RtlsPlayerStates.INSTALL_REQUEST:
                return 'INITIALIZING';
            case RtlsPlayerStates.RUNNING:
                return 'RUNNING';
            default:
                return '';
        }
    }

    isPlayerStateActionFinished(state: RtlsPlayerStates) {
        switch (state) {
            case RtlsPlayerStates.STOPPING:
            case RtlsPlayerStates.STOP_REQUEST:
            case RtlsPlayerStates.STARTING:
            case RtlsPlayerStates.START_REQUEST:
            case RtlsPlayerStates.DELETE_RUNNING_REQUEST:
            case RtlsPlayerStates.DELETE_STOPPED_REQUEST:
            case RtlsPlayerStates.DELETING:
            case RtlsPlayerStates.INSTALLING:
            case RtlsPlayerStates.INSTALL_REQUEST:
                return false;
            case RtlsPlayerStates.STOPPED:
            case RtlsPlayerStates.DELETED:
            case RtlsPlayerStates.RUNNING:
                return true;
            default:
                return false;
        }
    }

    getPlayerVersion(version: string) {
        const regex = /(\d|\.)+/g;
        const found = version.match(regex);

        if (!found?.length) {
            return 'UNKNOWN';
        }

        return found[0];
    }

    openPlayer(link: string) {
        window.open(link, '_blank');
    }

    updatePlayer(action: RtlsPlayerStates) {
        this.store.dispatch(
            updateSitePlayerRequestAction({ parameters: { action } }),
        );
    }

    openDialog(
        options: RtlsPlayerOptions,
        dialogType: RtlsPlayerModalType,
        maxTime?: number,
    ) {
        if (maxTime !== undefined && maxTime < 10) {
            return this.store.dispatch(
                showSnackBarAction({
                    config: {
                        type: SnackBarType.FAILED,
                        duration: 5000,
                        description: `There is not enough minutes remaining to ${
                            dialogType === RtlsPlayerModalType.INITIALIZE ||
                            dialogType === RtlsPlayerModalType.START
                                ? 'start'
                                : 'prolong'
                        } an RTLS Player instance. The minimum of 10 minutes is required.`,
                    },
                }),
            );
        }

        this.dialog
            .open(StartRtlsPlayerModalComponent, {
                width: RTLS_PLAYER_MODAL_WIDTH,
                data: {
                    options,
                    maxTime: Math.min(maxTime || 0, 480),
                    type: dialogType,
                },
            })
            .afterClosed()
            .pipe(take(1))
            .subscribe((result: { version?: string; time: number }) => {
                if (result) {
                    switch (dialogType) {
                        case RtlsPlayerModalType.INITIALIZE:
                            if (!result.version) {
                                return;
                            }

                            this.store.dispatch(
                                initializeSitePlayerRequestAction({
                                    parameters: {
                                        time: result.time,
                                        version: result.version,
                                    },
                                }),
                            );

                            break;
                        case RtlsPlayerModalType.PROLONG:
                            this.store.dispatch(
                                updateSitePlayerRequestAction({
                                    parameters: {
                                        action: RtlsPlayerStates.RUNNING,
                                        time: result.time,
                                    },
                                }),
                            );

                            break;
                        case RtlsPlayerModalType.START:
                            this.store.dispatch(
                                updateSitePlayerRequestAction({
                                    parameters: {
                                        action: RtlsPlayerStates.START_REQUEST,
                                        time: result.time,
                                    },
                                }),
                            );

                            break;
                    }
                }
            });
    }
}
