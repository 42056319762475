<div class="content" *ngIf="currentLicense$ | async as license">
    <div class="current-license" *ngIf="!isExpired(license.expiration)">
        Your site is guarded by <span class="license-detail text-success">{{ license.type }}</span
        >. Your license is valid until <span class="license-detail text-success">{{ license.expiration | unixTimeToDate: 'YYYY/MM' }}</span
        ><span class="text-error ml-1" *ngIf="isExpiring(license.expiration)">({{ license.expiration | unixTimeToDaysFromNow }} {{ (license.expiration | unixTimeToDaysFromNow) > 1 ? 'days' : 'day' }})</span>.<br /><br />
        <span *ngIf="isExpiring(license.expiration)">Once the license expires, the alerts and reports will be stopped and the site data will no longer be stored.</span>
    </div>
    <div class="current-license" *ngIf="isExpired(license.expiration)">Your license has <span class="text-error license-detail">EXPIRED</span>.<br /><br />Alerts and reports has been stopped. Data from your site are no longer stored.</div>
    <ng-container *ngIf="siteEditFeature$ | async as siteEditFeature">
        <div class="plans" *ngIf="siteEditFeature.state === 'enabled'">
            <div class="cards">
                <div class="card" [ngClass]="{ featured: license.type === 'myRTLS Care' && !isExpired(license.expiration) }">
                    <div class="active-indicator">ACTIVE</div>
                    <div class="header">myRTLS Care</div>
                    <div class="subheader">FEATURES</div>
                    <div class="feature"><mat-icon class="check-icon" fontSet="material-icons-outlined">check</mat-icon>Device Care in Cloud</div>
                    <div class="feature"><mat-icon class="check-icon" fontSet="material-icons-outlined">check</mat-icon>1 month of history</div>
                    <div class="feature"><mat-icon class="check-icon" fontSet="material-icons-outlined">check</mat-icon>Email Alerts</div>
                    <div class="feature"><mat-icon class="check-icon" fontSet="material-icons-outlined">check</mat-icon>Automatic reports</div>
                    <div class="feature"><mat-icon class="check-icon" fontSet="material-icons-outlined">check</mat-icon>Access to Service Desk</div>
                    <div class="feature"><mat-icon class="check-icon" fontSet="material-icons-outlined">check</mat-icon>System review every 6 months</div>
                    <div class="feature"><mat-icon class="check-icon" fontSet="material-icons-outlined">check</mat-icon>Remote support</div>
                    <div class="feature"><mat-icon class="check-icon" fontSet="material-icons-outlined">check</mat-icon>Hardware warranty</div>
                    <div class="feature"><mat-icon class="check-icon" fontSet="material-icons-outlined">check</mat-icon>Access to latest RTLS Studio</div>
                    <div class="feature"><mat-icon class="check-icon" fontSet="material-icons-outlined">check</mat-icon>RTLS Player - 4 hours / month</div>
                    <div class="feature"><mat-icon class="check-icon" fontSet="material-icons-outlined">check</mat-icon>SLA - Next Business Day (CET)</div>
                    <div class="subheader">PRICE</div>
                    <div class="price">
                        <div class="price-main">15%</div>
                        <div class="price-secondary">Anchors & License Price /<br />Year</div>
                    </div>
                </div>
                <div class="card" [ngClass]="{ featured: license.type === 'myRTLS Care+' && !isExpired(license.expiration) }">
                    <div class="active-indicator">ACTIVE</div>
                    <div class="header dark">myRTLS Care+</div>
                    <div class="subheader">FEATURES</div>
                    <div class="feature"><mat-icon class="check-icon" fontSet="material-icons-outlined">west</mat-icon><b>Features from myRTLS Care</b></div>
                    <div class="feature"><mat-icon class="check-icon" fontSet="material-icons-outlined">check</mat-icon>6 months of history</div>
                    <!--div class="feature"><mat-icon class="check-icon" fontSet="material-icons-outlined">add</mat-icon>Device Care On-Premise</div-->
                    <div class="feature"><mat-icon class="check-icon" fontSet="material-icons-outlined">add</mat-icon>Advanced Analytics</div>
                    <div class="feature subfeature"><mat-icon class="check-icon" fontSet="material-icons-outlined">add</mat-icon>User-defined metrics, dashboards</div>
                    <div class="feature subfeature"><mat-icon class="check-icon" fontSet="material-icons-outlined">add</mat-icon>Webhook Alerts</div>
                    <div class="feature"><mat-icon class="check-icon" fontSet="material-icons-outlined">check</mat-icon>System review every 3 months</div>
                    <div class="feature"><mat-icon class="check-icon" fontSet="material-icons-outlined">add</mat-icon>Assisted system maintenance</div>
                    <div class="feature"><mat-icon class="check-icon" fontSet="material-icons-outlined">add</mat-icon>Instant hardware replacement</div>
                    <div class="feature"><mat-icon class="check-icon" fontSet="material-icons-outlined">add</mat-icon>Phone call support</div>
                    <div class="feature"><mat-icon class="check-icon" fontSet="material-icons-outlined">check</mat-icon>RTLS Player - unlimited</div>
                    <div class="feature"><mat-icon class="check-icon" fontSet="material-icons-outlined">check</mat-icon>SLA - 4 Business Hours (CET)</div>
                    <div class="subheader">PRICE</div>
                    <div class="price">
                        <div class="price-main">20%</div>
                        <div class="price-secondary">Anchors & License Price /<br />Year</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sales-contact" *ngIf="siteEditFeature.state === 'enabled'">
            Valid prices can be found in the Price List on Sewio Portal. <br />
            For upgrade / renewal, contact your sales contact or <a href="mailto: sales@sewio.net" class="license-detail text-success">sales&#64;sewio.net</a>.
        </div>
    </ng-container>
</div>
