import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { CustomDatePickerModule } from './components/custom-date-picker/custom-date-picker.module';
import { CustomModalModule } from './components/custom-modal/custom-modal.module';
import { InfoModalModule } from './components/info-modal/info-modal.module';
import { AlertsModalModule } from './components/alerts-modal/alerts-modal.module';
import { ClearDeviceCareModalModule } from './components/clear-device-care-modal/clear-device-care-modal.module';
import { DeviceCareInstallationModule } from './components/device-care-installation/device-care-installation.module';
import { DeviceCareDashboardModule } from './components/device-care-dashboard/device-care-dashboard.module';
import { DeviceCareHistoryModule } from './components/device-care-history/device-care-history.module';
import { DeviceCareSettingsModule } from './components/device-care-settings/device-care-settings.module';
import { RtlsPlayerModule } from './components/rtls-player/rtls-player.module';
import { PhoneCallModule } from './components/phone-call/phone-call.module';
import { SupportActivitiesModule } from './components/support-activities/support-activities.module';
import { StartRtlsPlayerModalModule } from './components/start-rtls-player-modal/start-rtls-player-modal.module';
import { CustomSnackBarModule } from './components/custom-snack-bar/custom-snack-bar.module';
import { ReportModule } from './components/report/report.module';
import { InviteUserModalModule } from './components/invite-user-modal/invite-user-modal.module';
import { LicenseModule } from './components/site-overview/site-overview.module';
import { UsersModule } from './components/users/users.module';
import { CoreModule } from './core/core.module';
import { CompaniesModule } from './components/companies/companies.module';
import { SitesModule } from './components/sites/sites.module';
import { AppRoutes } from './app.routes';
import { SharedModule } from './shared/shared.module';
import { effects, reducers } from './store';
import { NgxEchartsModule } from 'ngx-echarts';
import { SiteDeploymentModule } from './components/site-deployment/site-deployment.module';
import { AdvancedAnalyticsModule } from './components/advanced-analytics/advanced-analytics.module';
import { LoginModule } from './components/login/login.module';
import { ResetPasswordModule } from './components/reset-password/reset-password.module';
import { MatChipsModule } from '@angular/material/chips';
import { NgParticlesModule } from 'ng-particles';
import { SiteModule } from './components/site/site.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { SiteDeploymentType } from '@myrtls/api-interfaces';
import { DeviceCareAdvancedModalModule } from './components/device-care-advanced-modal/device-care-advanced-modal.module';
import { MetricModalSModule } from './components/metric-modals/metric-modals.module';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule,
        SharedModule,
        AppRoutes,

        // routes
        SitesModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({
            maxAge: 100,
            logOnly: environment.production,
            connectInZone: true,
        }),
        EffectsModule.forRoot(effects),

        MatListModule,
        MatIconModule,
        MatDividerModule,
        InviteUserModalModule,
        ClearDeviceCareModalModule,
        UsersModule,
        CompaniesModule,
        CustomModalModule,
        InfoModalModule,
        AlertsModalModule,
        DeviceCareInstallationModule,
        DeviceCareDashboardModule,
        DeviceCareHistoryModule,
        DeviceCareSettingsModule,
        DeviceCareAdvancedModalModule,
        MetricModalSModule,
        AdvancedAnalyticsModule,
        RtlsPlayerModule,
        PhoneCallModule,
        SupportActivitiesModule,
        StartRtlsPlayerModalModule,
        SiteDeploymentModule,
        LicenseModule,
        MatTooltipModule,
        MatChipsModule,
        CustomSnackBarModule,
        LoginModule,
        SiteModule,
        ResetPasswordModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
        CustomDatePickerModule,
        ReportModule,
        NgParticlesModule,
        MenuItemComponent,
    ],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                subscriptSizing: 'dynamic',
            },
        },
        ...(environment.deployment !== SiteDeploymentType.ON_PREM
            ? [
                  {
                      provide: ErrorHandler,
                      useValue: Sentry.createErrorHandler({
                          showDialog: false,
                      }),
                  },
                  {
                      provide: Sentry.TraceService,
                      deps: [Router],
                  },
                  {
                      provide: APP_INITIALIZER,
                      useFactory: () => () => {},
                      deps: [Sentry.TraceService],
                      multi: true,
                  },
              ]
            : []),
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
