import { Component, Input } from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { CommonModule } from '@angular/common';
import { FeatureState, SiteDeploymentType } from '@myrtls/api-interfaces';
import { environment } from '../../../environments/environment';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'myrtls-menu-item[icon][title][expanded][state][active]',
    templateUrl: './menu-item.component.html',
    standalone: true,
    imports: [CommonModule, IconComponent, MatTooltipModule],
})
export class MenuItemComponent {
    @Input() title!: string;
    @Input() icon!: string;
    @Input() expanded!: boolean;
    @Input() state!: FeatureState;
    @Input() active!: boolean;

    private onprem = environment.deployment === SiteDeploymentType.ON_PREM;

    FeatureState = FeatureState;

    getMenuItemTooltip(label: string, state: FeatureState) {
        if (state === FeatureState.ENABLED) {
            return label;
        }

        return `${label}   [${this.getIconTooltip(state)}]`;
    }

    getIconTooltip(state: FeatureState) {
        switch (state) {
            case FeatureState.LOCKED:
                return 'License is not sufficient for this feature.';
            case FeatureState.EXPIRED:
                return 'License has expired for this feature.';
            case FeatureState.OTHER_DEPLOYMENT:
                return this.onprem
                    ? 'This feature is only available in CLOUD instance of myRTLS.'
                    : 'This feature is currently available in ON-PREMISE instance of myRTLS.';
            case FeatureState.BLOCKED:
                return 'This feature is blocked.';
            case FeatureState.NOT_PERMITTED:
                return 'You do not have appropriate permissions to access this feature.';
        }

        return '';
    }

    getIcon(state: FeatureState) {
        switch (state) {
            case FeatureState.LOCKED:
                return 'lock';
            case FeatureState.EXPIRED:
                return 'lock_clock';
            case FeatureState.OTHER_DEPLOYMENT:
                return 'vpn_lock';
            case FeatureState.BLOCKED:
                return 'block';
            case FeatureState.NOT_PERMITTED:
                return 'lock_person';
        }

        return '';
    }
}
