import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'groupByProperty' })
export class GroupByPropertyPipe<T extends { [key: string]: unknown }>
    implements PipeTransform
{
    transform(array: T[], property: string): { [key: string]: T[] } {
        const grouped = array.reduce(
            (acc, current) => {
                const key = current[property];

                if (typeof key !== 'string') {
                    return acc;
                }

                if (!acc[key]) {
                    acc[key] = [];
                }

                acc[key].push(current);
                return acc;
            },
            {} as { [key: string]: T[] },
        );

        return grouped;
    }
}
