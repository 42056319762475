<div class="activities-content">
    <div class="activities-header">
        <div class="activities-headline">Activity Log</div>
        <div class="activities-next" *ngIf="nextReview$ | async as nextReview">
            <div class="font-bold mr-1">Next Review In:</div>
            <div class="no-wrap vertical-center">{{ nextReview | unixTimeToDaysFromNow }} {{ (nextReview | unixTimeToDaysFromNow) === 1 ? 'day' : 'days' }}</div>
        </div>
    </div>
    <div class="activity-card" *ngFor="let activity of siteActivities$ | async">
        <div class="activity-header" [ngClass]="{ 'with-content': activity.content !== '' }">
            <div class="activity-header-left">
                <div class="activity-icon">
                    <mat-icon fontSet="material-icons-outlined">{{ getTypeIcon(activity.type) }}</mat-icon>
                </div>
                <div class="activity-info">
                    <div class="activity-type">{{ activity.createdAt | unixTimeToDate: 'MMMM D, YYYY' }} - {{ getTypeName(activity.type) }}</div>
                    <div class="activity-details">{{ activity.author }}</div>
                </div>
            </div>
            <div class="activity-header-right">
                <div (click)="openLink(activity.link.url)" class="activity-link" *ngIf="activity.link"><mat-icon class="activity-link-icon" fontSet="material-icons-outlined">link</mat-icon>{{ activity.link.name }}</div>
            </div>
        </div>
        <div class="activity-content" class="prose max-w-none" [innerHtml]="activity.content"></div>
    </div>
</div>
