import { Injectable } from '@angular/core';
import { InfluxDB, QueryApi } from '@influxdata/influxdb-client-browser';
import * as moment from 'moment';
import { DateRange } from '../../shared/models/date-range.enum';
import {
    InfluxDateOverridesConfig,
    InlfluxOverridesConfig,
} from '../../shared/models/influx-config.model';
import {
    MetricDashboardDataFragment,
    MetricDataFragment,
} from '../../shared/models/metric-data-fragment.model';
import { escapeRegExp } from '../../shared/utils/utils';

const BUCKET_VAR = '$BUCKET';
const RANGE_VAR = '$RANGE';
const RANGE_START_VAR = '$RANGE_START';
const RANGE_STOP_VAR = '$RANGE_STOP';
const RANGE_AGGREGATE_VAR = '$RANGE_AGGREGATE';
const OFFSET_VAR = '$OFFSET';
const OVERRIDES_VAR = '$OVERRIDES';

const RANGE_AGGREGATE_DAY = '10m';
const RANGE_AGGREGATE_WEEK = '1h';
const RANGE_AGGREGATE_MONTH = '6h';
const RANGE_AGGREGATE_YEAR = '2d';

const RANGE_AGGREGATE_DAY_SECONDS = 600;
const RANGE_AGGREGATE_WEEK_SECONDS = 3600;
const RANGE_AGGREGATE_MONTH_SECONDS = 21600;
const RANGE_AGGREGATE_YEAR_SECONDS = 172800;

@Injectable({ providedIn: 'root' })
export class InfluxdbService {
    dbs: { [key: string]: InfluxDB } = {};
    apis: { [key: string]: QueryApi } = {};

    static mapRowsToFilteringData(
        rows: { [key: string]: any }[],
        keys: string[],
    ): { [key: string]: string }[] {
        const result: { [key: string]: string }[] = [];

        rows.forEach(row => {
            const resultRow: { [key: string]: string } = {};

            keys.forEach(key => {
                if (typeof row === 'object' && row[key] !== undefined) {
                    resultRow[key] = row[key];
                }
            });

            result.push(resultRow);
        });

        return result;
    }

    static getRangeAggregate(dateRange?: DateRange): {
        range: string;
        seconds: number;
    } {
        switch (dateRange) {
            case DateRange.DAY:
                return {
                    range: RANGE_AGGREGATE_DAY,
                    seconds: RANGE_AGGREGATE_DAY_SECONDS,
                };
            case DateRange.WEEK:
                return {
                    range: RANGE_AGGREGATE_WEEK,
                    seconds: RANGE_AGGREGATE_WEEK_SECONDS,
                };
            case DateRange.MONTH:
                return {
                    range: RANGE_AGGREGATE_MONTH,
                    seconds: RANGE_AGGREGATE_MONTH_SECONDS,
                };
            case DateRange.YEAR:
                return {
                    range: RANGE_AGGREGATE_YEAR,
                    seconds: RANGE_AGGREGATE_YEAR_SECONDS,
                };
            default:
                return {
                    range: RANGE_AGGREGATE_MONTH,
                    seconds: RANGE_AGGREGATE_MONTH_SECONDS,
                };
        }
    }

    getQueryAPI(token: string, apiUrl: string, org: string): QueryApi {
        if (this.dbs[apiUrl + ';' + token] === undefined) {
            this.dbs[apiUrl + ';' + token] = new InfluxDB({
                url: apiUrl,
                token,
            });
        }

        if (this.apis[apiUrl + ';' + token + ';' + org] === undefined) {
            this.apis[apiUrl + ';' + token + ';' + org] =
                this.dbs[apiUrl + ';' + token].getQueryApi(org);
        }

        return this.apis[apiUrl + ';' + token + ';' + org];
    }

    getChartData({
        apiUrl,
        org,
        query,
        token,
        bucket,
        from,
        to,
        dateRange,
        overrides,
    }: InfluxDateOverridesConfig): Promise<MetricDataFragment[]> {
        const queryApi = this.getQueryAPI(token, apiUrl, org);
        const isSoFar = moment().isSame(moment(from), dateRange);
        const fluxQuery = this.createFluxQuery(
            query,
            bucket,
            from,
            to,
            dateRange,
            overrides,
            isSoFar,
        );

        return queryApi.collectRows<MetricDataFragment>(fluxQuery);
    }

    async getDashboardChartData({
        apiUrl,
        org,
        query,
        token,
        bucket,
        overrides,
        to,
    }: InlfluxOverridesConfig): Promise<MetricDashboardDataFragment[]> {
        const queryApi = this.getQueryAPI(token, apiUrl, org);
        let fluxQuery = query
            .replace(new RegExp(escapeRegExp(BUCKET_VAR), 'g'), bucket)
            .replace(
                new RegExp(escapeRegExp(OVERRIDES_VAR), 'g'),
                JSON.stringify(overrides),
            );

        if (to !== undefined) {
            fluxQuery = fluxQuery.replace(/now\(\)/g, to.toISOString());
        }

        const rows =
            await queryApi.collectRows<MetricDashboardDataFragment>(fluxQuery);

        return rows;
    }

    async getCSVChartData({
        apiUrl,
        org,
        query,
        token,
        bucket,
        from,
        to,
        dateRange,
        overrides,
    }: InfluxDateOverridesConfig): Promise<string> {
        const queryApi = this.getQueryAPI(token, apiUrl, org);
        const fluxQuery = this.createFluxQuery(
            query,
            bucket,
            from,
            to,
            dateRange,
            overrides,
        );

        const lines = await queryApi.collectLines(fluxQuery);
        return lines.join('\n');
    }

    createFluxQuery(
        query: string,
        bucket: string,
        from: Date,
        to: Date,
        dateRange: DateRange,
        overrides: string[],
        offset = false,
    ): string {
        const rangeAggregate = InfluxdbService.getRangeAggregate(dateRange);
        const rangeStart = moment(from)
            .subtract(rangeAggregate.seconds, 'seconds')
            .toISOString();
        const rangeStop = to.toISOString();
        const rangeValue = `start: ${rangeStart}, stop: ${rangeStop}`;

        return query
            .replace(new RegExp(escapeRegExp(BUCKET_VAR), 'g'), bucket)
            .replace(
                new RegExp(escapeRegExp(RANGE_AGGREGATE_VAR), 'g'),
                rangeAggregate.range,
            )
            .replace(new RegExp(escapeRegExp(RANGE_START_VAR), 'g'), rangeStart)
            .replace(new RegExp(escapeRegExp(RANGE_STOP_VAR), 'g'), rangeStop)
            .replace(new RegExp(escapeRegExp(RANGE_VAR), 'g'), rangeValue)
            .replace(
                new RegExp(escapeRegExp(OFFSET_VAR), 'g'),
                offset ? '1' : '0',
            )
            .replace(
                new RegExp(escapeRegExp(OVERRIDES_VAR), 'g'),
                JSON.stringify(overrides),
            );
    }
}
