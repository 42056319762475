import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { AlertIntervalWithMetricSettings } from '../models/alerts.model';
import { SettingsMetricModel } from '../models/settings-data.model';

export interface MergedAlertInterval {
    metric: string;
    metricSettings: SettingsMetricModel;
    metadata: string;
    count: number;
    pending: boolean;
    from: Date;
    to: Date | null;
    duration: string;
}

@Pipe({
    name: 'mergeAlerts',
})
export class MergeAlertsPipe implements PipeTransform {
    transform(
        alerts: AlertIntervalWithMetricSettings[],
    ): MergedAlertInterval[] {
        const mergedAlerts: Map<string, MergedAlertInterval> = new Map();

        for (const alert of alerts) {
            const mergedAlert = mergedAlerts.get(alert.metadata);

            if (!mergedAlert) {
                mergedAlerts.set(alert.metadata, {
                    metric: alert.metric,
                    metadata: alert.metadata,
                    metricSettings: alert.metricSettings,
                    count: 1,
                    pending: alert.to === null,
                    from: alert.from,
                    to: alert.to,
                    duration: moment
                        .duration(
                            moment(alert.from).diff(
                                alert.to ? moment(alert.to) : moment(),
                                'seconds',
                            ),
                            'seconds',
                        )
                        .humanize(),
                });
            } else {
                mergedAlert.count++;
            }
        }

        return Array.from(mergedAlerts.values());
    }
}
