import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoutePath } from '../../shared/models/route-path.enum';
import { AppState } from '../../store';
import { $sitesList } from '../../store/sites/sites.selectors';
import * as moment from 'moment';
import {
    isBeforeNDaysFromTheDate,
    isInThePast,
} from '../../shared/utils/utils';
import { NUMBER_OF_DAYS_TO_START_EXPIRING } from '../../shared/utils/constants';
import { SiteFeature } from '@myrtls/api-interfaces';

@Component({
    selector: 'myrtls-dashboard-sites',
    templateUrl: './sites.view.html',
    styleUrls: ['./sites.view.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SitesView {
    private readonly filterValueSubject$ = new BehaviorSubject<string>('');

    readonly sites$ = combineLatest([
        this.store.pipe(select($sitesList)),
        this.filterValueSubject$.asObservable(),
    ]).pipe(
        map(([sites, filterValue]) =>
            sites
                .filter(site => site?.name != null)
                .filter(({ name }) => name.toLowerCase().includes(filterValue)),
        ),
    );

    constructor(
        private readonly store: Store<AppState>,
        private readonly router: Router,
    ) {}

    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.filterValueSubject$.next(filterValue.trim().toLowerCase());
    }

    navigateToSite(siteId: string): void {
        this.router.navigateByUrl(
            `/${RoutePath.SITE}/${siteId}/${SiteFeature.DEVICE_CARE_DASHBOARD}`,
        );
    }

    formatLicense(license: { type: string; expiration: number }): string {
        const type = license.type.replace(/\d{4}\.\d{2}/, '').trim();

        if (license.expiration === 0) {
            return `${type} (Not Activated)`;
        }

        const date = moment(license.expiration * 1000).format('YYYY/MM');
        return `${type} (${date})`;
    }

    isExpired(expiration: number) {
        return isInThePast(expiration);
    }

    isExpiring(expiration: number) {
        return !isBeforeNDaysFromTheDate(
            expiration,
            NUMBER_OF_DAYS_TO_START_EXPIRING,
        );
    }
}
