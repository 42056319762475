import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { RtlsPlayerOptions } from '@myrtls/api-interfaces';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SnackBarType } from '../../shared/models/snack-bar-config.model';
import { AppState } from '../../store';
import { $sitePlayer } from '../../store/sites/sites.selectors';
import { showSnackBarAction } from '../../store/snack-bar/snack-bar.actions';

export enum RtlsPlayerModalType {
    INITIALIZE = 'initialize',
    PROLONG = 'prolong',
    START = 'start',
}

export interface StartRtlsPlayerModalData {
    options: RtlsPlayerOptions;
    type: RtlsPlayerModalType;
    maxTime: number;
}

@Component({
    selector: 'myrtls-start-rtls-player-modal',
    templateUrl: './start-rtls-player-modal.component.html',
    styleUrls: ['./start-rtls-player-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartRtlsPlayerModalComponent implements OnDestroy {
    private readonly unsubscribe$ = new Subject<void>();

    value = 30;
    endTime = '';
    types: string[];
    maxTime: number;

    type: RtlsPlayerModalType;
    modalTypes = RtlsPlayerModalType;

    typeValidator: UntypedFormControl;

    @ViewChild('typeSelect', { static: false }) typeSelect!: MatSelect;

    sitePlayer$ = this.store.select($sitePlayer);

    constructor(
        private readonly store: Store<AppState>,
        public dialogRef: MatDialogRef<string>,
        @Inject(MAT_DIALOG_DATA) public data: StartRtlsPlayerModalData,
    ) {
        this.types = [...data.options.versions].reverse() || [];
        this.typeValidator = new UntypedFormControl(
            this.types[0],
            Validators.required,
        );
        this.type = data.type;
        this.maxTime = Math.min(
            Math.floor(
                data.maxTime -
                    (data.type === RtlsPlayerModalType.PROLONG ? 1 : 0),
            ),
            480,
        );
        this.updateMaxValues();
        this.sitePlayer$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(player => {
                if (player) {
                    this.maxTime = Math.min(
                        Math.floor(
                            player.remainingTime +
                                (player.machine?.remainingTime || 0) / 60,
                        ) - (data.type === RtlsPlayerModalType.PROLONG ? 1 : 0),
                        480,
                    );

                    if (this.maxTime < 10) {
                        this.store.dispatch(
                            showSnackBarAction({
                                config: {
                                    type: SnackBarType.FAILED,
                                    duration: 5000,
                                    description: `There is not enough minutes remaining to ${
                                        this.type ===
                                            RtlsPlayerModalType.INITIALIZE ||
                                        this.type === RtlsPlayerModalType.START
                                            ? 'start'
                                            : 'prolong'
                                    } an RTLS Player instance. The minimum of 10 minutes is required.`,
                                },
                            }),
                        );

                        this.dialogRef.close();
                        return;
                    }

                    this.updateMaxValues();
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    updateMaxValues() {
        if (this.value > this.maxTime) {
            this.value = this.maxTime;
        }

        this.endTime = this.minutesFromNowToDateTime(this.value);
    }

    minutesFromNowToDateTime(minutes: number) {
        return moment().add(minutes, 'minutes').format('YYYY/MM/DD HH:mm');
    }

    formatMinutes(minutes: number) {
        if (minutes >= 60) {
            return (
                Math.floor(minutes / 60) +
                ':' +
                ('0' + (minutes % 60)).slice(-2)
            );
        } else {
            return '0:' + ('0' + (minutes % 60)).slice(-2);
        }
    }

    onSliderChange() {
        this.endTime = this.minutesFromNowToDateTime(this.value);
    }

    getDialogTitle(dialogType: RtlsPlayerModalType) {
        switch (dialogType) {
            case RtlsPlayerModalType.INITIALIZE:
                return 'Initialize RTLS Player';
            case RtlsPlayerModalType.START:
                return 'Start RTLS Player';
            case RtlsPlayerModalType.PROLONG:
                return 'Prolong RTLS Player';
            default:
                return '';
        }
    }

    getConfirmButtonText(dialogType: RtlsPlayerModalType) {
        switch (dialogType) {
            case RtlsPlayerModalType.INITIALIZE:
                return 'INITIALIZE';
            case RtlsPlayerModalType.START:
                return 'START';
            case RtlsPlayerModalType.PROLONG:
                return 'PROLONG';
            default:
                return '';
        }
    }

    create() {
        if (this.type === RtlsPlayerModalType.INITIALIZE) {
            if (this.typeValidator.invalid) {
                this.typeSelect.open();
                return;
            }

            this.dialogRef.close({
                version: this.typeValidator.value,
                time: this.value,
            });
        } else {
            this.dialogRef.close({
                time: this.value,
            });
        }
    }
}
