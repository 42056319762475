import { Component, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
    Permission,
    PermissionGroup,
    Role,
    SiteFeature,
    User,
} from '@myrtls/api-interfaces';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, from } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
    CUSTOM_MODAL_WIDTH,
    INVITE_MODAL_WIDTH,
} from '../../shared/utils/constants';
import { AppState } from '../../store';
import { $permissionGroups } from '../../store/permissions/permissions.selectors';
import {
    deleteUserRequestAction,
    editUserPermissionsRequestAction,
    inviteUserRequestAction,
} from '../../store/sites/sites.actions';
import {
    $siteFeature,
    $siteUsers,
    $smtpStatus,
} from '../../store/sites/sites.selectors';
import { CustomModalComponent } from '../custom-modal/custom-modal.component';
import { InviteUserModal } from '../invite-user-modal/invite-user.modal';
import * as _ from 'lodash';
import { OnPremAuthService } from '../../core/modules/auth/on-prem-auth.service';
import { AuthService } from '@auth0/auth0-angular';

@Component({
    selector: 'myrtls-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
})
export class UsersComponent {
    readonly roles = Object.values(Role);
    displayedColumns: string[] = ['email', 'edit', 'delete'];
    private readonly filterValueSubject$ = new BehaviorSubject<string>('');
    readonly permissionGroups$ = this.store.pipe(select($permissionGroups));
    readonly smtpStatus$ = this.store.pipe(select($smtpStatus));

    editUsersFeature$ = this.store.pipe(
        select($siteFeature(SiteFeature.SITE_USERS_EDIT)),
    );

    currentUser$;
    readonly users$ = combineLatest([
        this.store.pipe(select($siteUsers)),
        this.filterValueSubject$.asObservable(),
    ]).pipe(
        map(([sites, filterValue]) =>
            sites
                .filter(user => user?.email != null)
                .filter(({ email }) =>
                    email.toLowerCase().includes(filterValue),
                ),
        ),
    );

    private idsToRolesMap?: { [key: string]: string[] };

    constructor(
        public dialog: MatDialog,
        private readonly store: Store<AppState>,
        @Optional()
        private readonly cloudAuth: AuthService,
        @Optional() private readonly onPremAuth: OnPremAuthService,
    ) {
        if (this.cloudAuth) {
            this.currentUser$ = cloudAuth.user$;
        } else if (this.onPremAuth) {
            this.currentUser$ = from(onPremAuth.getUser());
        } else {
            throw new Error('Missing AUTH provider.');
        }
    }

    openDialog(permissionGroups: PermissionGroup[], userToEdit?: User) {
        this.dialog
            .open(InviteUserModal, {
                width: INVITE_MODAL_WIDTH,
                data: {
                    permissionGroups,
                    userToEdit,
                },
            })
            .afterClosed()
            .pipe(take(1))
            .subscribe(user => {
                if (user) {
                    this.store.dispatch(
                        userToEdit != null
                            ? editUserPermissionsRequestAction({
                                  user: {
                                      ...userToEdit,
                                      name: user.name,
                                      permissions: user.permissions,
                                  },
                              })
                            : inviteUserRequestAction({ user }),
                    );
                }
            });
    }

    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.filterValueSubject$.next(filterValue.trim().toLowerCase());
    }

    editUser(permissionGroups: PermissionGroup[], user: User): void {
        this.openDialog(permissionGroups, user);
    }

    deleteUser(userId: string): void {
        this.dialog
            .open(CustomModalComponent, {
                width: CUSTOM_MODAL_WIDTH,
                data: {
                    question: 'Do you really want to remove this user?',
                    positiveAnswer: 'Yes',
                    negativeAnswer: 'No',
                },
            })
            .afterClosed()
            .pipe(take(1))
            .subscribe(answer => {
                if (answer) {
                    this.store.dispatch(deleteUserRequestAction({ userId }));
                }
            });
    }

    computeUserRole(user: User, permissionGroups: PermissionGroup[]) {
        if (!this.idsToRolesMap) {
            const permissions = permissionGroups.reduce<Permission[]>(
                (acc, { permissions }) => [...acc, ...permissions],
                [],
            );

            this.idsToRolesMap = Object.values(Role).reduce(
                (acc, role) => ({
                    ...acc,
                    [role]: permissions
                        .map(({ roles, id }) =>
                            roles.includes(role) ? id : null,
                        )
                        .filter(id => id != null),
                }),
                {},
            );
        }

        for (const role of this.roles) {
            if (
                _.isEqual(
                    this.idsToRolesMap[role]
                        .map(item => parseInt(item, 10))
                        .sort(),
                    user.permissions.map(item => parseInt(item.id, 10)).sort(),
                )
            ) {
                return role;
            }
        }

        return 'other';
    }
}
