<h2 mat-dialog-title class="dialog-title">Invite User</h2>
<form [formGroup]="inviteUserForm">
    <div mat-dialog-content>
        <mat-form-field class="form-field">
            <mat-label>Email</mat-label>
            <input type="email" matInput formControlName="email" />
            <mat-error *ngIf="inviteUserForm.controls['email'].hasError('email') && !inviteUserForm.controls['email'].hasError('required')"> Please enter a valid email address </mat-error>
            <mat-error *ngIf="inviteUserForm.controls['email'].hasError('required')"> Email is <strong>required</strong> </mat-error>
        </mat-form-field>

        <mat-form-field class="form-field">
            <mat-label>Name</mat-label>
            <input type="text" matInput formControlName="name" />
            <mat-error *ngIf="inviteUserForm.controls['name'].hasError('required')"> Name is <strong>required</strong> </mat-error>
        </mat-form-field>

        <div class="permissions-wrapper mb-2">
            <h3 class="permissions-title">Site Permissions</h3>
            <mat-button-toggle-group (change)="toggleButtonChange($event)" name="permissions">
                <mat-button-toggle class="mat-button-toggle" [checked]="selectedRoleButton === role" [value]="role" *ngFor="let role of roles">
                    <span class="role-toggle-button">{{ role }}</span>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>

        <div class="permissions-container" *ngIf="data.permissionGroups">
            <div class="permission-row" *ngFor="let permissionGroup of data.permissionGroups">
                <div class="permission-groups-column">{{ permissionGroup.group }}</div>
                <div class="checkboxes-column">
                    <span *ngFor="let permission of permissionGroup.permissions">
                        <mat-checkbox [checked]="activePermissionsIds.includes(permission.id) || permission.name === 'site_read'" (change)="checkboxChange($event)" [id]="permission.id" [disabled]="permission.name === 'site_read'" color="primary" class="mr-4">{{ permission.category }} </mat-checkbox>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-wrapper" mat-dialog-actions>
        <button color="primary" mat-button (click)="changePermissions()">{{ editMode ? 'Edit' : 'Invite' }}</button>
    </div>
</form>
