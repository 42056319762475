<div class="content-wrapper" *ngIf="selectedSiteId$ | async as siteId">
    <div class="date-controls-wrapper mb-2">
        <myrtls-custom-date-picker [disabled]="loading" (periodSelectionChange)="periodSelectionChange($event, siteId)"></myrtls-custom-date-picker>

        <div>
            <mat-checkbox [disabled]="loading" class="alerting-checkbox" [checked]="(alertingOnly$ | async) || false" (change)="onAlertingOnlyChanged($event.checked)">Show Alerting Only</mat-checkbox>
            <mat-form-field class="range-dropdown">
                <mat-label>Visible Range</mat-label>
                <mat-select [disabled]="loading" [formControl]="selectedRangeControl">
                    <mat-option *ngFor="let range of ranges" [value]="range">
                        {{ range }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <mat-accordion multi>
        <mat-expansion-panel class="expansion-panel" *ngFor="let group of metricGroups$ | async; let i = index" [expanded]="true" [attr.group]="group">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ group }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container>
                <div *ngFor="let metric of metrics; trackBy: metricById">
                    <div *ngIf="metric && metric.group === group" class="chart-container">
                        <div class="header-container" *ngIf="metric.id">
                            <h3 class="title" [attr.id]="metric.id">
                                {{ metric.name }} <span class="text-xs mx-1 text-dark-600">{{ metric.nameSuffix }}</span>
                            </h3>
                            <!--button
                                class="export-button"
                                mat-button
                                color="primary"
                                (click)="exportCSV(metric.id)"
                            >
                                Export
                            </button-->
                        </div>
                        <div *ngIf="metric.options as options" class="canvas-wrapper" echarts [style.height]="getChartHeight(metric.id, options)" [options]="options"></div>
                        <div *ngIf="metric.tableOptions as options" class="table-container">
                            <table mat-table [dataSource]="options.rows">
                                <ng-container *ngFor="let column of options.columns" [matColumnDef]="column.name">
                                    <th mat-header-cell *matHeaderCellDef>{{ column.alias }}</th>
                                    <td mat-cell *matCellDef="let element" [style.color]="element[column.name].color ? element[column.name].color : '#000'">
                                        <div *ngIf="Array.isArray(element[column.name].value)">
                                            <myrtls-state-timeline [data]="element[column.name].value" [colorMapping]="element[column.name].colorMapping"></myrtls-state-timeline>
                                        </div>
                                        <div *ngIf="!Array.isArray(element[column.name].value)">{{ element[column.name].value }}</div>
                                    </td>
                                </ng-container>
                                <tr class="no-data-row" *matNoDataRow>
                                    <td class="mat-cell no-data" colspan="9999">{{ options.noDataText || 'No data found.' }}</td>
                                </tr>
                                <tr mat-header-row *matHeaderRowDef="getColumns(options); sticky: true"></tr>
                                <tr mat-row *matRowDef="let row; columns: getColumns(options)"></tr>
                            </table>
                        </div>
                    </div>
                </div>
            </ng-container>
        </mat-expansion-panel>
    </mat-accordion>
</div>
<div *ngIf="loading" class="loading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
