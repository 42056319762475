import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AlertsModalComponent } from './alerts-modal.component';
import { SharedModule } from '../../shared/shared.module';
import { DateIntervalModule } from '../date-interval/date-interval.module';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';

@NgModule({
    declarations: [AlertsModalComponent],
    exports: [AlertsModalComponent],
    imports: [
        SharedModule,
        MatDialogModule,
        MatButtonModule,
        DateIntervalModule,
        MatTableModule,
        MatExpansionModule,
        MatChipsModule,
    ],
})
export class AlertsModalModule {}
