import { PermissionGroup } from '@myrtls/api-interfaces';
import { createSelector } from '@ngrx/store';
import { AppState } from '../index';

const $permissionsState = (state: AppState) => state.permissions;

export const $permissionGroups = createSelector(
    $permissionsState,
    ({ groups }): PermissionGroup[] => groups,
);
