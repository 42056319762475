<div
    class="rounded-full py-1 px-3 text-sm"
    [ngClass]="{
        'bg-error-100 text-error-600': type === 'error',
        'bg-info-100 text-info-600': type === 'info',
        'bg-brand-100 text-brand-600': type === 'success',
        'bg-dark-100 text-dark-600': type === 'default',
    }"
>
    {{ text }}
</div>
