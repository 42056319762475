<mat-toolbar *ngIf="(hidePanels$ | async) !== true; else fullSize" class="toolbar">
    <div
        class="logo-container"
        [ngClass]="{
            dev: !production,
            expanded: menuExpanded && (isMobile$ | async) !== true,
        }"
    >
        <div class="logo-wrapper">
            <img class="sewio-logo" src="/assets/logos/white.full.svg" alt="logo" />
        </div>
    </div>

    <div class="toolbar-right-wrapper">
        <div class="header-label">
            <mat-chip-listbox class="ml-2"><mat-chip *ngIf="onprem" class="unselectable" color="primary" (click)="deploymentClicked($event)">On-Premise</mat-chip></mat-chip-listbox>
        </div>
        <div class="selected-item-display-wrapper mr-1" *ngIf="(isMobile$ | async) === false">
            <div>
                <div class="selected-item-display-label">SITE</div>
                <div class="selected-item-display font-normal">
                    {{ (selectedSiteName$ | async) || '' }}
                </div>
            </div>

            <div class="toolbar-horizontal-divider mx-3"></div>
            <div>
                <div class="selected-item-display-label">COMPANY</div>
                <div class="selected-item-display font-normal">
                    {{ (selectedCompanyName$ | async) || '' }}
                </div>
            </div>
            <ng-container *ngIf="currentLicense$ | async as license">
                <div class="toolbar-horizontal-divider mx-3"></div>
                <div>
                    <div class="selected-item-display-label">LICENSE</div>
                    <div class="selected-item-display font-normal" *ngIf="!isLicenseExpired(license.expiration)">
                        {{ license.type }} <span class="ml-1" [ngClass]="{ 'text-warning': isLicenseExpiring(license.expiration, hidePanels$ | async) }">[{{ license.expiration | unixTimeToDate: 'YYYY/MM' }}]</span>
                    </div>
                    <div class="selected-item-display font-normal text-error" *ngIf="isLicenseExpired(license.expiration) && license.expiration !== 0">{{ license.type }} [{{ license.expiration | unixTimeToDate: 'YYYY/MM' }}]</div>
                    <div class="selected-item-display font-normal text-error" *ngIf="isLicenseExpired(license.expiration) && license.expiration === 0">Not Activated</div>
                </div>
            </ng-container>
        </div>

        <button mat-icon-button *ngIf="isMobile$ | async; else profileMenuTemplate" (click)="toggleMenu()">
            <mat-icon>menu</mat-icon>
        </button>
    </div>
</mat-toolbar>
<mat-sidenav-container
    *ngIf="(hidePanels$ | async) !== true"
    [ngClass]="{
        mobile: isMobile$ | async,
    }"
    [autosize]="true"
>
    <mat-sidenav
        class="sidenav-container"
        [ngClass]="{
            expanded: menuExpanded,
            hidden: menuHidden,
            mobile: isMobile$ | async,
        }"
        [disableClose]="true"
        [opened]="isMenuOpen"
        [mode]="(isMobile$ | async) ? 'over' : 'side'"
        [autoFocus]="false"
        (openedChange)="isMenuOpen = $event"
    >
        <div class="sidenav-content">
            <div class="my-1" *ngIf="isMobile$ | async">
                <ng-container *ngTemplateOutlet="profileMenuTemplate"></ng-container>
                <mat-divider></mat-divider>
                <div class="side-menu-selected-item-display">
                    <div class="selected-item-display-label">COMPANY</div>
                    <div class="selected-item-display font-normal">
                        {{ (selectedCompanyName$ | async) || '' }}
                    </div>
                </div>
                <div class="side-menu-selected-item-display">
                    <div class="selected-item-display-label">SITE</div>
                    <div class="selected-item-display font-normal">
                        {{ (selectedSiteName$ | async) || '' }}
                    </div>
                </div>
                <ng-container *ngIf="currentLicense$ | async as license">
                    <div class="side-menu-selected-item-display">
                        <div class="selected-item-display-label">LICENSE</div>
                        <div class="selected-item-display font-normal" *ngIf="!isLicenseExpired(license.expiration)">
                            {{ license.type }} <span class="ml-1" [ngClass]="{ expiring: isLicenseExpiring(license.expiration, hidePanels$ | async) }">[{{ license.expiration | unixTimeToDate: 'YYYY/MM' }}]</span>
                        </div>
                        <div class="selected-item-display font-normal" *ngIf="isLicenseExpired(license.expiration)">Expired [{{ license.expiration | unixTimeToDate: 'YYYY/MM' }}]</div>
                    </div>
                </ng-container>
                <mat-divider></mat-divider>
            </div>
            <div *ngFor="let menuCategory of menuCategories$ | async">
                <div
                    *ngIf="menuCategory.name"
                    class="category-header"
                    [ngClass]="{
                        dev: !production,
                        expanded: menuExpanded || (isMobile$ | async) === true,
                    }"
                >
                    <!--mat-icon class="category-header-icon" fontSet="material-icons-outlined">{{ menuCategory.icon }}</mat-icon-->
                    <div class="category-header-text text-dark-500" [style.visibility]="menuExpanded || (isMobile$ | async) === true ? 'visible' : 'hidden'">{{ menuCategory.name | uppercase }}</div>
                </div>
                <div class="p-2 gap-1 flex flex-col">
                    <ng-container *ngFor="let menuItem of menuItems$ | async | filterByProperty: 'category' : menuCategory.name">
                        <myrtls-menu-item
                            *ngIf="!menuItem.hidden"
                            #rla="routerLinkActive"
                            [routerLink]="menuItem.external || menuItem.state !== FeatureState.ENABLED ? undefined : menuItem.link"
                            (click)="menuItemClick($event, menuItem)"
                            [icon]="menuItem.icon"
                            [title]="menuItem.label"
                            [active]="rla.isActive"
                            [expanded]="menuExpanded"
                            [state]="menuItem.state"
                            [routerLinkActive]="menuItem.external || menuItem.state !== FeatureState.ENABLED ? '' : 'active'"
                        ></myrtls-menu-item>
                    </ng-container>
                </div>
                <!-- <mat-list>
                    <div *ngFor="let menuItem of menuItems$ | async | filterByProperty: 'category':menuCategory.name">
                        <mat-list-item
                            *ngIf="!menuItem.hidden"
                            class="sidenav-item"
                            [routerLink]="menuItem.external || menuItem.state !== FeatureState.ENABLED ? undefined : menuItem.link"
                            (click)="menuItemClick($event, menuItem)"
                            [matTooltipDisabled]="menuExpanded"
                            [matTooltip]="getMenuItemTooltip(menuItem.label, menuItem.state)"
                            [matTooltipPosition]="'right'"
                        >
                            <div class="menu-item-container" [ngClass]="{ disabled: menuItem.state !== FeatureState.ENABLED }" [routerLinkActive]="menuItem.external || menuItem.state !== FeatureState.ENABLED ? '' : 'active'">
                                <mat-icon matListIcon class="sidenav-item-icon" fontSet="material-icons-outlined">{{ menuItem.icon }}</mat-icon>
                                <mat-icon *ngIf="!menuExpanded && menuItem.state !== FeatureState.ENABLED" class="locked-icon wrapped" (click)="iconClick($event, menuItem.state !== FeatureState.ENABLED)" [matTooltipPosition]="'right'" [matTooltip]="getIconTooltip(menuItem.state)" [color]="'warn'"
                                    >{{ getIcon(menuItem.state) }}
                                </mat-icon>
                                <h3 class="menu-item-label" matLine>
                                    {{ menuItem.label }}
                                </h3>
                            </div>
                            <mat-icon *ngIf="menuExpanded && menuItem.state !== FeatureState.ENABLED" (click)="iconClick($event, menuItem.disabled)" [matTooltipPosition]="'right'" [matTooltip]="getIconTooltip(menuItem.state)" [color]="'warn'">{{ getIcon(menuItem.state) }}</mat-icon>
                        </mat-list-item>
                    </div>
                </mat-list> -->
            </div>
        </div>
        <button mat-flat-button disableRipple class="sidenav-expand-toggle-button" [ngClass]="{ expanded: menuExpanded }" (click)="toggleMenuExpand()">
            <mat-icon>double_arrow</mat-icon>
        </button>
    </mat-sidenav>
    <mat-sidenav-content id="sidenav-content" class="sidenav-content">
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #profileMenuTemplate>
    <div *ngIf="user$ | async as user" class="profile-button-container">
        <div [matMenuTriggerFor]="profileMenu" class="rounded-full cursor-pointer ml-2">
            <span class="profile-button-content">
                <myrtls-profile-picture [src]="user.picture" [size]="'small'" [rounded]="true"></myrtls-profile-picture>
                <span class="profile-button-email ml-2">
                    {{ user['https://sewio.net/email'] || user['email'] }}
                </span>
            </span>
        </div>

        <mat-menu #profileMenu="matMenu" xPosition="before">
            <div class="profile-menu-header" (click)="$event.stopPropagation()">
                {{ user['https://sewio.net/email'] || user['email'] }}
            </div>
            <button mat-menu-item (click)="logOut()">
                <mat-icon aria-hidden="true">logout</mat-icon>
                Log Out
            </button>
            <div *ngIf="version$ | async as version" class="version">{{ version }}</div>
        </mat-menu>
    </div>
</ng-template>

<ng-template #fullSize>
    <div class="full-size">
        <router-outlet></router-outlet>
    </div>
</ng-template>

<ng-particles *ngIf="showEasterEgg" [options]="particlesOptions" [particlesInit]="particlesInit" (particlesLoaded)="particlesLoaded($event)"></ng-particles>
