import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Report } from '@myrtls/api-interfaces';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
    DashboardRatioBarValueModel,
    DashboardSingleStatValueModel,
} from '../../shared/models/dashboard-value.model';
import { AppState } from '../../store';
import {
    $dashboardSingleStatData,
    $dashboardStackedChartData,
    $selectedSiteId,
    $siteReports,
    $siteAlertDays,
} from '../../store/sites/sites.selectors';

@Component({
    selector: 'myrtls-device-care-dashboard',
    templateUrl: './device-care-dashboard.component.html',
    styleUrls: ['./device-care-dashboard.component.scss'],
})
export class DeviceCareDashboardComponent {
    readonly selectedSiteId$ = this.store.pipe(select($selectedSiteId));

    readonly singleStatWidgetData$: Observable<
        DashboardSingleStatValueModel[]
    > = this.store.pipe(select($dashboardSingleStatData));

    readonly stackedChartData$: Observable<DashboardRatioBarValueModel[]> =
        this.store.pipe(select($dashboardStackedChartData));

    readonly alerts$ = this.store.pipe(select($siteAlertDays));

    readonly reports$: Observable<Report[]> = this.store.pipe(
        select($siteReports),
    );

    constructor(
        private readonly store: Store<AppState>,
        private readonly router: Router,
    ) {}
}
