import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { SupportActivitiesComponent } from './support-activities.component';

@NgModule({
    declarations: [SupportActivitiesComponent],
    exports: [SupportActivitiesComponent],
    imports: [SharedModule],
})
export class SupportActivitiesModule {}
