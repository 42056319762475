import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface CustomModalData {
    question: string;
    positiveAnswer: string;
    negativeAnswer: string;
}

@Component({
    selector: 'myrtls-custom-modal',
    templateUrl: './custom-modal.component.html',
    styleUrls: ['./custom-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomModalComponent {
    constructor(
        public dialogRef: MatDialogRef<string>,
        @Inject(MAT_DIALOG_DATA) public data: CustomModalData,
    ) {}
}
