import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { DeviceCareDashboardComponent } from './components/device-care-dashboard/device-care-dashboard.component';
import { DeviceCareHistoryComponent } from './components/device-care-history/device-care-history.component';
import { DeviceCareSettingsComponent } from './components/device-care-settings/device-care-settings.component';
import { ReportComponent } from './components/report/report.component';
import { SiteOverviewComponent } from './components/site-overview/site-overview.component';
import { CompaniesView } from './components/companies/companies.view';
import { UsersComponent } from './components/users/users.component';
import { SitesView } from './components/sites/sites.view';
import { RedirectGuard } from './shared/guards/redirect.guard';
import { RoutePath } from './shared/models/route-path.enum';
import { DeviceCareInstallationComponent } from './components/device-care-installation/device-care-installation.component';
import { RtlsPlayerComponent } from './components/rtls-player/rtls-player.component';
import { SupportActivitiesComponent } from './components/support-activities/support-activities.component';
import { PhoneCallComponent } from './components/phone-call/phone-call.component';
import { SiteDeploymentComponent } from './components/site-deployment/site-deployment.component';
import { SiteFeature } from '@myrtls/api-interfaces';
import { FeaturesGuard } from './shared/guards/features.guard';
import { AdvancedAnalyticsComponent } from './components/advanced-analytics/advanced-analytics.component';
import { environment } from '../environments/environment';
import { LoginComponent } from './components/login/login.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SiteComponent } from './components/site/site.component';

const routes: Routes = [
    {
        path: `${RoutePath.AUTH}/${RoutePath.LOGIN}`,
        component: LoginComponent,
        canActivate: [...environment.auth.guards],
        data: {
            hidePanels: true,
        },
    },
    {
        path: `${RoutePath.AUTH}/${RoutePath.RESET_PASSWORD}`,
        component: ResetPasswordComponent,
        canActivate: [...environment.auth.guards],
        data: {
            hidePanels: true,
        },
    },
    {
        path: RoutePath.COMPANIES,
        component: CompaniesView,
        canActivate: [...environment.auth.guards, RedirectGuard],
    },
    {
        path: `${RoutePath.SITES}/:companyId`,
        component: SitesView,
        canActivate: [...environment.auth.guards, RedirectGuard],
    },
    {
        path: `${RoutePath.SITE}/:id/${SiteFeature.DEVICE_CARE_INSTALLATION}`,
        component: DeviceCareInstallationComponent,
        canActivate: [...environment.auth.guards, FeaturesGuard],
        data: {
            requiredFeatures: [SiteFeature.DEVICE_CARE_INSTALLATION],
        },
    },
    {
        path: `${RoutePath.SITE}/:id/${SiteFeature.DEVICE_CARE_DASHBOARD}`,
        component: DeviceCareDashboardComponent,
        canActivate: [...environment.auth.guards, FeaturesGuard],
        data: {
            requiredFeatures: [SiteFeature.DEVICE_CARE_DASHBOARD],
        },
    },
    {
        path: `${RoutePath.SITE}/:id/${SiteFeature.DEVICE_CARE_HISTORY}`,
        component: DeviceCareHistoryComponent,
        canActivate: [...environment.auth.guards, FeaturesGuard],
        data: {
            requiredFeatures: [SiteFeature.DEVICE_CARE_HISTORY],
        },
    },
    {
        path: `${RoutePath.SITE}/:id/${SiteFeature.DEVICE_CARE_SETTINGS}`,
        component: DeviceCareSettingsComponent,
        canActivate: [...environment.auth.guards, FeaturesGuard],
        data: {
            requiredFeatures: [SiteFeature.DEVICE_CARE_SETTINGS],
        },
    },
    {
        path: `${RoutePath.SITE}/:id/${SiteFeature.DEVICE_CARE_GRAFANA}`,
        component: AdvancedAnalyticsComponent,
        canActivate: [...environment.auth.guards, FeaturesGuard],
        data: {
            requiredFeatures: [SiteFeature.DEVICE_CARE_GRAFANA],
        },
    },
    {
        path: `${RoutePath.SITE}/:id/${SiteFeature.SITE_OVERVIEW}`,
        component: SiteOverviewComponent,
        canActivate: [...environment.auth.guards, FeaturesGuard],
        data: {
            requiredFeatures: [],
        },
    },
    {
        path: `${RoutePath.SITE}/:id/${SiteFeature.SITE_USERS}`,
        component: UsersComponent,
        canActivate: [...environment.auth.guards, FeaturesGuard],
        data: {
            requiredFeatures: [SiteFeature.SITE_USERS],
        },
    },
    {
        path: `${RoutePath.SITE}/:id/${SiteFeature.SITE_DEPLOYMENT}`,
        component: SiteDeploymentComponent,
        canActivate: [...environment.auth.guards, FeaturesGuard],
        data: {
            requiredFeatures: [SiteFeature.SITE_DEPLOYMENT],
        },
    },
    {
        path: `${RoutePath.SITE}/:id/${SiteFeature.SITE_OVERVIEW}`,
        component: SiteComponent,
        canActivate: [...environment.auth.guards, FeaturesGuard],
        data: {
            requiredFeatures: [SiteFeature.SITE_OVERVIEW],
        },
    },
    {
        path: `${RoutePath.SITE}/:id/${SiteFeature.RTLS_PLAYER}`,
        component: RtlsPlayerComponent,
        canActivate: [...environment.auth.guards, FeaturesGuard],
        data: {
            requiredFeatures: [SiteFeature.RTLS_PLAYER],
        },
    },
    {
        path: `${RoutePath.SITE}/:id/${SiteFeature.SYSTEM_REVIEW}`,
        component: SupportActivitiesComponent,
        canActivate: [...environment.auth.guards, FeaturesGuard],
        data: {
            requiredFeatures: [SiteFeature.SYSTEM_REVIEW],
        },
    },
    {
        path: `${RoutePath.SITE}/:id/${SiteFeature.PHONE_CALL}`,
        component: PhoneCallComponent,
        canActivate: [...environment.auth.guards, FeaturesGuard],
        data: {
            requiredFeatures: [SiteFeature.PHONE_CALL],
        },
    },
    {
        path: `${RoutePath.SITE}/:id/${SiteFeature.DEVICE_CARE_REPORT}`,
        component: ReportComponent,
        canActivate: [],
        data: {
            hidePanels: true,
        },
    },
    { path: '**', redirectTo: RoutePath.COMPANIES, pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutes {}
