import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { DashboardReportsComponent } from './dashboard-reports.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
    declarations: [DashboardReportsComponent],
    exports: [DashboardReportsComponent],
    imports: [SharedModule, MatButtonModule],
})
export class DashboardReportsModule {}
