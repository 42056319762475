import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

export interface StateTimelineData {
    value: number | null;
    at: string;
}

export interface StateTimelineColorMapping {
    [key: number]: string;
    default: string;
}

export interface StateTimelineGroupedData {
    id: number;
    value: number | null;
    from: string;
    to: string;
    count: number;
}

@Component({
    selector: 'myrtls-state-timeline[data]',
    templateUrl: './state-timeline.component.html',
    styleUrls: ['./state-timeline.component.scss'],
})
export class StateTimelineComponent {
    private _data!: StateTimelineData[];

    @Input()
    set data(value: StateTimelineData[]) {
        this._data = value;
        this.groupData();
    }

    get data(): StateTimelineData[] {
        return this._data;
    }

    @Input() colorMapping: StateTimelineColorMapping = {
        0: 'bg-error',
        1: 'bg-brand',
        default: 'bg-dark-400',
    };

    groupedData: StateTimelineGroupedData[] = [];
    hovered: number | null = null;

    groupData() {
        this.groupedData = [];

        if (this.data.length < 2) {
            return;
        }

        let index = 0;
        let currentValue = this.data[0].value;
        let from = this.data[0].at;
        let count = 1;

        for (let i = 1; i < this.data.length; i++) {
            const currentPoint = this.data[i];

            if (currentPoint.value === currentValue) {
                count++;
            } else {
                this.groupedData.push({
                    id: index++,
                    value: currentValue,
                    from: moment(from).format('YYYY-MM-DD HH:mm:ss'),
                    to: moment(currentPoint.at).format('YYYY-MM-DD HH:mm:ss'),
                    count,
                });
                currentValue = currentPoint.value;
                from = currentPoint.at;
                count = 1;
            }
        }

        const timeDiff = moment(this.data[this.data.length - 1].at).diff(
            moment(this.data[this.data.length - 2].at),
            'seconds',
        );

        const momentFrom = moment(from);

        this.groupedData.push({
            id: index++,
            value: currentValue,
            from: momentFrom.format('YYYY-MM-DD HH:mm:ss'),
            to: moment(this.data[this.data.length - 1].at)
                .add(timeDiff, 'seconds')
                .format('YYYY-MM-DD HH:mm:ss'),
            count,
        });
    }
}
