import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from '../../shared/shared.module';
import { DateIntervalModule } from '../date-interval/date-interval.module';
import { StartRtlsPlayerModalComponent } from './start-rtls-player-modal.component';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [StartRtlsPlayerModalComponent],
    exports: [StartRtlsPlayerModalComponent],
    imports: [
        SharedModule,
        MatDialogModule,
        MatSliderModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCheckboxModule,
        DateIntervalModule,
    ],
})
export class StartRtlsPlayerModalModule {}
