<h2 mat-dialog-title>Advanced Configuration - {{ (metric$ | async)?.name }}</h2>

<div mat-dialog-content>
    <ng-container *ngIf="columns.length !== 0; else noDataTemplate">
        <div class="mb-4 flex flex-col justify-between items-end">
            <mat-form-field>
                <mat-label>Filter data</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Search..." #input />
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </div>

        <table mat-table [dataSource]="controls || []" multiTemplateDataRows matSort (matSortChange)="sortData($event)">
            <ng-container *ngFor="let column of dynamicColumns" [matColumnDef]="column">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column | mapping: { title: 'mac' } | uppercase }}</th>
                <td mat-cell *matCellDef="let element" [formGroup]="element">
                    {{ element.get(column)?.value }}
                </td>
            </ng-container>

            <ng-container matColumnDef="enabled">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Enabled</th>
                <td mat-cell *matCellDef="let element" [formGroup]="element">
                    <mat-checkbox color="primary" [formControlName]="'enabled'"></mat-checkbox>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns" class="font-semibold"></tr>
            <tr mat-row *matRowDef="let element; columns: columns"></tr>
        </table>
    </ng-container>
    <ng-template #noDataTemplate>
        <ng-container *ngIf="!loading; else loadingTemplate">
            <myrtls-info-card type="info">'No data to display.'</myrtls-info-card>
        </ng-container>
        <ng-template #loadingTemplate>
            <div class="w-full h-24 pt-10 flex justify-center items-center">
                <mat-progress-spinner [diameter]="30" [mode]="'indeterminate'"></mat-progress-spinner>
            </div>
        </ng-template>
    </ng-template>
</div>

<div class="flex items-center justify-end" mat-dialog-actions>
    <button mat-button mat-dialog-close>CANCEL</button>
    <button color="primary" mat-button (click)="save()" [disabled]="!enabledFormGroup?.valid || columns.length === 0">SAVE</button>
</div>
