import { NgModule } from '@angular/core';
import { SiteOverviewComponent } from './site-overview.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
    declarations: [SiteOverviewComponent],
    exports: [SiteOverviewComponent],
    imports: [SharedModule, MatCardModule, MatIconModule],
})
export class LicenseModule {}
