import { PermissionGroup } from '@myrtls/api-interfaces';
import { Action, createReducer, on } from '@ngrx/store';
import { getPermissionsSuccessAction } from './permissions.actions';

export interface PermissionsState {
    groups: PermissionGroup[];
}

const initialState: PermissionsState = {
    groups: [],
};

const reducer = createReducer(
    initialState,
    on(getPermissionsSuccessAction, (state, { groups }) => ({
        ...state,
        groups,
    })),
);

export function permissionsReducer(
    state: PermissionsState | undefined,
    action: Action,
): PermissionsState {
    return reducer(state, action);
}
