import { DOCUMENT } from '@angular/common';
import {
    AfterViewInit,
    Component,
    Inject,
    OnDestroy,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { EChartsOption } from 'echarts';
import * as moment from 'moment';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import { DateRange } from '../../shared/models/date-range.enum';
import { MetricGroup } from '../../shared/models/metric-group.enum';
import { DEFAULT_PERIOD } from '../../shared/utils/constants';
import { AppState } from '../../store';
import {
    selectPeriodDateRangeAction,
    setAlertsOnly,
} from '../../store/sites/sites.actions';
import {
    $alertsOnly,
    $chartOptions,
    $metricGroups,
    $selectedPeriod,
    $selectedSiteId,
    $sitesFocus,
    TableOptions,
} from '../../store/sites/sites.selectors';
import { CustomDatePickerComponent } from '../custom-date-picker/custom-date-picker.component';

@Component({
    selector: 'myrtls-device-care-history',
    templateUrl: './device-care-history.component.html',
    styleUrls: ['./device-care-history.component.scss'],
})
export class DeviceCareHistoryComponent implements OnDestroy, AfterViewInit {
    @ViewChildren(CustomDatePickerComponent)
    datePickers!: QueryList<CustomDatePickerComponent>;

    @ViewChildren(MatExpansionPanel)
    expansionPanels!: QueryList<MatExpansionPanel>;

    @ViewChild(MatSort) sort!: MatSort;

    private readonly unsubscribe$ = new Subject<void>();

    readonly metricGroups$ = this.store.pipe(select($metricGroups));
    readonly chartOptions$ = this.store.pipe(
        select($chartOptions(true, false)),
    );

    readonly selectedSiteId$ = this.store.pipe(select($selectedSiteId));
    readonly selectedPeriod$ = this.store.pipe(select($selectedPeriod));
    readonly alertingOnly$ = this.store.pipe(select($alertsOnly));
    readonly focus$ = this.store.pipe(select($sitesFocus));
    readonly ranges = Object.values(DateRange);

    selectedRangeControl = new UntypedFormControl(DEFAULT_PERIOD.dateRange);
    focus: string | null = null;

    public metrics: (
        | {
              id: string;
              name: string;
              nameSuffix?: string;
              group: MetricGroup;
              options?: EChartsOption;
              tableOptions?: TableOptions;
          }
        | null
        | false
    )[] = [];

    public loading = true;
    public selectedDate?: Date;
    public selectedRange?: DateRange;

    Array = Array;

    constructor(
        private readonly store: Store<AppState>,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.chartOptions$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(metrics => {
                this.metrics = metrics;

                if (metrics.length === 0 || metrics.indexOf(null) !== -1) {
                    this.loading = true;
                } else if (this.focus) {
                    this.openAllPanels();

                    setTimeout(() => {
                        this.loading = false;
                        this.focusOnChart(this.focus as string);
                    }, 1000);
                } else {
                    this.loading = false;
                }
            });

        this.selectedRangeControl.valueChanges
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(value => {
                this.selectedRange = value;
                this.datePickers.first.setPeriod(value);
            });

        this.focus$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
            this.focus = value;
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngAfterViewInit(): void {
        this.selectedPeriod$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(period => {
                if (period) {
                    this.selectedRangeControl.setValue(period.dateRange, {
                        emitEvent: false,
                    });

                    this.datePickers.first.setPeriod(
                        period.dateRange,
                        moment(period.from),
                        false,
                    );
                }
            });
    }

    openAllPanels() {
        for (const panel of this.expansionPanels) {
            panel.open();
        }
    }

    focusOnChart(id: string) {
        const title = this.document.getElementById(id);
        const content = this.document.getElementById('sidenav-content');

        if (content && title) {
            content.scrollTop =
                title.offsetTop +
                (title.closest<HTMLElement>('mat-expansion-panel')?.offsetTop ||
                    0) -
                20;
        }
    }

    onAlertingOnlyChanged(state: boolean) {
        this.store.dispatch(setAlertsOnly({ alertsOnly: state }));
        this.datePickers.last.changeSelectedPeriod();
    }

    periodSelectionChange(currentDate: moment.Moment, siteId: string): void {
        if (siteId) {
            const selectedRange =
                this.selectedRangeControl.value === DateRange.WEEK
                    ? 'isoWeek'
                    : this.selectedRangeControl.value;
            const from = moment(currentDate).startOf(selectedRange).toDate();
            const to = moment(currentDate).endOf(selectedRange).toDate();

            this.store.dispatch(
                selectPeriodDateRangeAction({
                    siteId,
                    dateRange: this.selectedRangeControl.value,
                    from,
                    to,
                }),
            );
        }
    }

    /*exportCSV(metricId: string): void {
        //this.store.dispatch(exportCSVChartDataAction({ metricId }));
    }*/

    metricById(index: number, metric: { id: string } | null | false) {
        return metric ? metric.id : undefined;
    }

    getChartHeight(metric: string, options: EChartsOption) {
        if (
            !Array.isArray(options.legend) &&
            metric === 'master-states' &&
            options.legend?.data?.length
        ) {
            return options.legend.data.length * 20 + 80 + 'px';
        }

        return '400px';
    }

    getColumns(options: TableOptions) {
        return options.columns.map(column => column.name);
    }
}
