<h2 mat-dialog-title>Free HDD</h2>
<div mat-dialog-content class="h-full flex flex-col gap-4 mt-4">
    <ng-container *ngIf="!loading; else loadingTemplate">
        <div class="flex flex-row items-center justify-between">
            <div>Available storage</div>
            <div class="px-3 py-1 rounded-md text-white bg-{{ warn ? 'error' : 'success' }}">{{ freeStorage }}</div>
        </div>
        <div class="flex flex-row items-center justify-between">
            <div>Max storage</div>
            <div class="px-3 py-1 rounded-md bg-light-200">{{ totalStorage }}</div>
        </div>
        <myrtls-info-card *ngIf="warn" [type]="'error'"> Please free up additional space on your hard drive. </myrtls-info-card>
    </ng-container>
    <ng-template #loadingTemplate>
        <div class="w-full flex justify-center items-center">Loading...</div>
    </ng-template>
</div>
<div class="flex items-center justify-end" mat-dialog-actions>
    <button mat-button mat-dialog-close>CLOSE</button>
</div>
