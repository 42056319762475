import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'myrtls-icon[icon]',
    templateUrl: './icon.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class IconComponent {
    @Input() icon!: string;
    @Input() fill = false;
    @Input() size: 'sm' | 'md' | 'lg' | 'xl' = 'lg';

    getSizeInPx(size: 'sm' | 'md' | 'lg' | 'xl') {
        switch (size) {
            case 'sm':
                return '12px';
            case 'md':
                return '16px';
            case 'lg':
                return '24px';
            case 'xl':
                return '32px';
        }
    }
}
