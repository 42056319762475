import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { UiModule } from '@myrtls/ui';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { SortMetricsByIdsArrayPipe } from './pipes/sort-metrics-by-ids-array.pipe';
import { AlertDateToStringPipe } from './pipes/alert-date-to-string.pipe';
import { UnixTimeToDatePipe } from './pipes/unix-time-to-date.pipe';
import { UnixTimeToDaysFromNowPipe } from './pipes/unix-time-to-days-from-now.pipe';
import { HumanizeDurationPipe } from './pipes/humanize-duration.pipe';
import { SplitStringPipe } from './pipes/split-string.pipe';
import { ReformatTimePipe } from './pipes/reformat-time.pipe';
import { FilterByPropertyPipe } from './pipes/filter-by-property.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { GroupByPropertyPipe } from './pipes/group-by-property.pipe';
import { RevertArrayPipe } from './pipes/revert-array.pipe';
import { MergeAlertsPipe } from './pipes/merge-alerts.pipe';
import { DebugPipe } from './pipes/debug.pipe';
import { ValuesPipe } from './pipes/values.pipe';
import { MappingPipe } from './pipes/mapping.pipe';
import { ReplaceStringPipe } from './pipes/replace-string.pipe';

@NgModule({
    imports: [
        CommonModule,
        UiModule,
        MatToolbarModule,
        MatSidenavModule,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        MatCardModule,
        MatTooltipModule,
        RouterModule,
    ],
    declarations: [
        SortMetricsByIdsArrayPipe,
        AlertDateToStringPipe,
        UnixTimeToDatePipe,
        HumanizeDurationPipe,
        UnixTimeToDaysFromNowPipe,
        SplitStringPipe,
        ReformatTimePipe,
        FilterByPropertyPipe,
        SafeUrlPipe,
        GroupByPropertyPipe,
        RevertArrayPipe,
        MergeAlertsPipe,
        ValuesPipe,
        MappingPipe,
        DebugPipe,
        ReplaceStringPipe,
    ],
    exports: [
        CommonModule,
        UiModule,
        MatToolbarModule,
        MatSidenavModule,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        MatCardModule,
        SortMetricsByIdsArrayPipe,
        AlertDateToStringPipe,
        UnixTimeToDatePipe,
        HumanizeDurationPipe,
        UnixTimeToDaysFromNowPipe,
        SplitStringPipe,
        ReformatTimePipe,
        FilterByPropertyPipe,
        SafeUrlPipe,
        GroupByPropertyPipe,
        RevertArrayPipe,
        MergeAlertsPipe,
        ValuesPipe,
        MappingPipe,
        DebugPipe,
        ReplaceStringPipe,
    ],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [],
        };
    }
}
