import { Component, OnDestroy, Optional } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from '@auth0/auth0-angular';
import { select, Store } from '@ngrx/store';
import {
    filter,
    from,
    mergeMap,
    Subject,
    takeUntil,
    withLatestFrom,
} from 'rxjs';
import { OnPremAuthService } from '../../core/modules/auth/on-prem-auth.service';
import { AppState } from '../../store';
import { DeviceCareInfo } from '../../store/sites/sites.reducer';
import { $deviceCareConfig } from '../../store/sites/sites.selectors';

@Component({
    selector: 'myrtls-advanced-analytics',
    templateUrl: './advanced-analytics.component.html',
    styleUrls: ['./advanced-analytics.component.scss'],
})
export class AdvancedAnalyticsComponent implements OnDestroy {
    private readonly unsubscribe$ = new Subject<void>();

    url: SafeResourceUrl | null = null;

    constructor(
        private readonly sanitizer: DomSanitizer,
        private readonly store: Store<AppState>,
        @Optional()
        private readonly cloudAuth: AuthService,
        @Optional() private readonly onPremAuth: OnPremAuthService,
    ) {
        let tokenObservable;

        if (this.cloudAuth) {
            tokenObservable = this.cloudAuth.getAccessTokenSilently();
        } else if (this.onPremAuth) {
            tokenObservable = from(this.onPremAuth.getAccessToken());
        } else {
            throw Error('Missing AUTH provider.');
        }

        this.store
            .pipe(
                select($deviceCareConfig),
                filter(
                    (deviceCare): deviceCare is DeviceCareInfo =>
                        deviceCare != null,
                ),
                withLatestFrom(tokenObservable),
                mergeMap(merged => {
                    if (merged[1] !== null) {
                        this.url =
                            this.sanitizer.bypassSecurityTrustResourceUrl(
                                merged[0].grafanaUrl +
                                    '?orgId=' +
                                    merged[0].grafana?.id +
                                    '&auth_token=' +
                                    merged[1],
                            );
                    }

                    return merged;
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(() => undefined);
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
