<div mat-dialog-content class="h-full flex flex-col gap-4">
    <div class="flex flex-row justify-between items-start">
        <h2 mat-dialog-title>Inactive Tags</h2>
        <mat-form-field *ngIf="!loading && dataSource.data.length !== 0">
            <mat-label>Filter data</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Search..." #input />
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
    </div>
    <ng-container *ngIf="!loading; else loadingTemplate">
        <myrtls-info-card *ngIf="dataSource.data.length === 0" [type]="'success'"> All tags are active. No action is required. </myrtls-info-card>
        <div class="table-container flex-grow overflow-auto">
            <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort (matSortChange)="sortData($event)" *ngIf="dataSource.data.length !== 0">
                <ng-container matColumnDef="alias" sticky>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="whitespace-nowrap">Alias</th>
                    <td mat-cell *matCellDef="let element" class="whitespace-nowrap">{{ element.alias }}</td>
                </ng-container>

                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>MAC</th>
                    <td mat-cell *matCellDef="let element">{{ element.title }}</td>
                </ng-container>

                <ng-container matColumnDef="platform">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="whitespace-nowrap">Tag Type</th>
                    <td mat-cell *matCellDef="let element" class="whitespace-nowrap">
                        <span class="px-3 py-1 bg-light-200 rounded-md text-sm">
                            {{ element.platform }}
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="status" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="px-3 py-1 bg-error rounded-md text-white text-sm">{{ element.status | uppercase }}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="inactiveFor">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Inactive For</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.inactiveFor }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columns" class="font-semibold"></tr>
                <tr mat-row *matRowDef="let element; columns: columns"></tr>
            </table>
        </div>
        <myrtls-info-card *ngIf="dataSource.data.length !== 0" [type]="'error'"> Please locate these tags and turn them on or return them to the RTLS area. </myrtls-info-card>
    </ng-container>
    <ng-template #loadingTemplate>
        <div class="w-full flex justify-center items-center">Loading...</div>
    </ng-template>
</div>
<div class="flex items-center justify-end" mat-dialog-actions>
    <button mat-button mat-dialog-close>CLOSE</button>
</div>
