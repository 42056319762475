import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RtlsPlayerComponent } from './rtls-player.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '../../shared/shared.module';
import { ClipboardModule } from '@angular/cdk/clipboard';

@NgModule({
    declarations: [RtlsPlayerComponent],
    exports: [RtlsPlayerComponent],
    imports: [
        SharedModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatTableModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatSortModule,
        MatCardModule,
        ClipboardModule,
    ],
})
export class RtlsPlayerModule {}
