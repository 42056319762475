<h2 mat-dialog-title>Uptime</h2>
<div mat-dialog-content class="h-full flex flex-col mt-4">
    <p *ngIf="since">
        The system is running since
        <span class="font-semibold"> {{ since }}</span
        >.
    </p>
</div>
<div class="flex items-center justify-end" mat-dialog-actions>
    <button mat-button mat-dialog-close>CLOSE</button>
</div>
