<div [@listAnimation]="(data || []).length" class="ratio-bar-widget-container">
    <ng-container *ngFor="let item of data; trackBy: trackByIndex">
        <div class="ratio-bar-header">
            {{ item.label }} <span class="text-xs mx-2 text-dark-400">{{ item.nameSuffix }}</span>
            <mat-icon *ngIf="showInfo && item.info" class="helper-icon" (click)="displayInfo(item.info)" fontSet="material-icons-outlined">help_outline</mat-icon>
        </div>
        <div class="ratio-bar" (click)="showPopup(item.id, item.version)">
            <div *ngFor="let part of item.data; trackBy: trackByIndex" @scaleAnimation class="ratio-bar-part" [style.width.%]="part.label !== 'NO DATA' ? part.value : 100" [style.background-color]="part.color"></div>
        </div>
        <div class="ratio-bar-text-container">
            <div *ngFor="let part of item.data" class="ratio-bar-part-text" [style.color]="part.color">
                <div [style.background-color]="part.color" class="ratio-bar-dot"></div>
                {{ part.label }}
                <span *ngIf="part.label !== 'NO DATA'">{{ part.count }}</span>
            </div>
        </div>
    </ng-container>
</div>
