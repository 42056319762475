import { Component } from '@angular/core';
import { SiteFeature } from '@myrtls/api-interfaces';
import { Store } from '@ngrx/store';
import { NUMBER_OF_DAYS_TO_START_EXPIRING } from '../../shared/utils/constants';
import {
    isBeforeNDaysFromTheDate,
    isInThePast,
} from '../../shared/utils/utils';
import { AppState } from '../../store';
import {
    $currentLicense,
    $siteFeature,
} from '../../store/sites/sites.selectors';

@Component({
    selector: 'myrtls-site-overview',
    templateUrl: './site-overview.component.html',
    styleUrls: ['./site-overview.component.scss'],
})
export class SiteOverviewComponent {
    currentLicense$ = this.store.select($currentLicense);
    siteEditFeature$ = this.store.select($siteFeature(SiteFeature.SITE_EDIT));

    versionRegexp = /\d{4}\.\d{2}/;

    constructor(private readonly store: Store<AppState>) {}

    isExpired(expiration: number) {
        return isInThePast(expiration);
    }

    isExpiring(expiration: number) {
        return !isBeforeNDaysFromTheDate(
            expiration,
            NUMBER_OF_DAYS_TO_START_EXPIRING,
        );
    }
}
