import {
    AuthGuard,
    AuthHttpInterceptor,
    AuthModule,
} from '@auth0/auth0-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SiteDeploymentType } from '@myrtls/api-interfaces';
import { Environment } from './environment.model';

export const environment: Environment = {
    production: false,
    deployment: SiteDeploymentType.CLOUD,
    auth: {
        guards: [AuthGuard],
        module: AuthModule.forRoot({
            domain: 'auth.sewio.net',
            clientId: '2xOWuWqgI9vY2QKSr2KSI2T0ItjA3Q9T',
            authorizationParams: {
                audience: 'myrtls-dashboard-api',
                scope: 'openid profile email',
                redirect_uri: window.location.origin,
            },
            httpInterceptor: {
                allowedList: [
                    {
                        tokenOptions: {
                            authorizationParams: {
                                audience: 'myrtls-dashboard-api',
                            },
                        },
                        uri: '/api/*',
                    },
                ],
            },
        }),
        interceptors: [
            {
                provide: HTTP_INTERCEPTORS,
                useClass: AuthHttpInterceptor,
                multi: true,
            },
        ],
        providers: [],
    },
};
