import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { NgxEchartsModule } from 'ngx-echarts';
import { ReportComponent } from './report.component';
import { SharedModule } from '../../shared/shared.module';
import { MatTableModule } from '@angular/material/table';
import { StateTimelineModule } from '../state-timeline/state-timeline.module';
import { SingleStatWidgetModule } from '../single-stat-widget/single-stat-widget.module';
import { RatioBarWidgetModule } from '../ratio-bar-widget/ratio-bar-widget.module';

@NgModule({
    declarations: [ReportComponent],
    exports: [ReportComponent],
    imports: [
        SharedModule,
        NgxEchartsModule,
        MatButtonModule,
        MatTableModule,
        StateTimelineModule,
        SingleStatWidgetModule,
        RatioBarWidgetModule,
    ],
})
export class ReportModule {}
