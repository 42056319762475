import { Component, OnInit, OnDestroy } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
    Bundle,
    BundleType,
    FeatureState,
    Release,
    SiteDeploymentType,
    SiteFeature,
} from '@myrtls/api-interfaces';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { AppState } from '../../store';
import {
    createSiteDeploymentBundleAction,
    deploySiteRequestAction,
    downloadSiteDeploymentAction,
    getSiteDeploymentBundlesAction,
    getSiteLicenseRawRequestAction,
    getSiteReleasesAction,
    startPoolingSiteDeploymentBundlesAction,
    stopPoolingSiteDeploymentBundlesAction,
} from '../../store/sites/sites.actions';
import { SiteDetail } from '../../store/sites/sites.reducer';
import {
    $downloadingDeployment,
    $site,
    $siteDeployments,
    $siteFeature,
    $siteReleases,
    $siteReleasesLoading,
    $version,
} from '../../store/sites/sites.selectors';
import { CustomModalComponent } from '../custom-modal/custom-modal.component';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'myrtls-site-deployment',
    templateUrl: './site-deployment.component.html',
    styleUrls: ['./site-deployment.component.scss'],
})
export class SiteDeploymentComponent implements OnInit, OnDestroy {
    private readonly unsubscribe$ = new Subject<void>();

    readonly site$: Observable<SiteDetail | null> = this.store.pipe(
        select($site),
    );

    downloading = this.store.select($downloadingDeployment);
    deployments$ = this.store.select($siteDeployments);
    releases$ = this.store.select($siteReleases);
    releasesLoading$ = this.store.select($siteReleasesLoading);

    cloudDeploymentFeature$ = this.store.select(
        $siteFeature(SiteFeature.DEPLOYMENT_CLOUD),
    );

    onPremDeploymentFeature$ = this.store.select(
        $siteFeature(SiteFeature.DEPLOYMENT_ONPREM),
    );

    rtlsStudioDeploymentFeature$ = this.store.select(
        $siteFeature(SiteFeature.DEPLOYMENT_STUDIO),
    );

    deploymentTypes = [
        {
            name: 'Cloud',
            value: SiteDeploymentType.CLOUD,
            feature: this.cloudDeploymentFeature$,
        },
        {
            name: 'On-Premise',
            value: SiteDeploymentType.ON_PREM,
            feature: this.onPremDeploymentFeature$,
        },
    ];

    studioTypes: { name: string; value: BundleType }[] = [
        { name: 'Virtual Box Bundle', value: 'studio-vbox' },
        { name: 'Native Install Bundle', value: 'studio-install' },
        { name: 'Native Update Bundle', value: 'studio-update' },
        { name: 'Docker Bundle', value: 'studio-docker' },
    ];

    studioVersions: { [key: string]: Release } = {};

    version$ = this.store.select($version);

    FeatureState = FeatureState;
    SiteDeploymentType = SiteDeploymentType;

    deploymentSelectionFormGroup!: UntypedFormGroup;

    studioVersion = this.formBuilder.nonNullable.control<string | null>(null, [
        Validators.required,
    ]);

    studioType = this.formBuilder.nonNullable.control<BundleType | null>(null, [
        Validators.required,
    ]);

    studioReleaseFormGroup!: UntypedFormGroup;

    deployments: { [key in BundleType]?: Bundle[] } = {};

    selectedTabIndex = 0;

    deploymentSelectionLoading = true;

    Object = Object;

    constructor(
        public dialog: MatDialog,
        private readonly store: Store<AppState>,
        private formBuilder: UntypedFormBuilder,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
    ) {}

    ngOnInit() {
        this.deploymentSelectionFormGroup = this.formBuilder.group({
            deploymentType: [null, Validators.required],
        });

        this.studioReleaseFormGroup = this.formBuilder.group({
            studioVersion: this.studioVersion,
            studioType: this.studioType,
        });

        this.site$.pipe(takeUntil(this.unsubscribe$)).subscribe(site => {
            this.deploymentSelectionLoading = false;

            if (site && !this.deploymentSelectionFormGroup) {
                this.deploymentSelectionFormGroup = this.formBuilder.group({
                    deploymentType: [site.deploymentType, Validators.required],
                });
            }
        });

        this.onPremDeploymentFeature$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(feature => {
                if (feature?.state === 'enabled') {
                    this.store.dispatch(getSiteLicenseRawRequestAction());
                    this.store.dispatch(
                        getSiteDeploymentBundlesAction({
                            bundleType: 'onprem',
                        }),
                    );
                    this.store.dispatch(
                        startPoolingSiteDeploymentBundlesAction({
                            intervalTime: 10000,
                            bundleType: 'onprem',
                        }),
                    );
                }
            });

        this.rtlsStudioDeploymentFeature$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(feature => {
                if (feature?.state === 'enabled') {
                    this.store.dispatch(getSiteLicenseRawRequestAction());
                    this.store.dispatch(getSiteReleasesAction());

                    for (const type of this.studioTypes) {
                        this.store.dispatch(
                            getSiteDeploymentBundlesAction({
                                bundleType: type.value,
                            }),
                        );

                        this.store.dispatch(
                            startPoolingSiteDeploymentBundlesAction({
                                intervalTime: 10000,
                                bundleType: type.value,
                            }),
                        );
                    }
                }
            });

        this.deployments$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(bundles => {
                this.deployments = bundles;
            });

        this.releases$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(releases => {
                this.studioVersions = {};

                for (const release of releases) {
                    this.studioVersions[release.version] = release;
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.store.dispatch(stopPoolingSiteDeploymentBundlesAction());
    }

    deploy() {
        const value: SiteDeploymentType | undefined =
            this.deploymentSelectionFormGroup?.get('deploymentType')?.value;

        if (value === undefined) {
            return;
        }

        this.dialog
            .open(CustomModalComponent, {
                width: '500px',
                data: {
                    question: `Are you sure you want to deploy your site in <b class="text-success">${
                        value === SiteDeploymentType.CLOUD
                            ? 'CLOUD'
                            : 'ON-PREMISE'
                    }</b> environment?<br /><br /><span class="text-error">You will not be able to change this later without contacting our support team.</span>`,
                    positiveAnswer: 'Yes',
                    negativeAnswer: 'No',
                },
            })
            .afterClosed()
            .pipe(take(1))
            .subscribe(answer => {
                if (answer) {
                    this.store.dispatch(
                        deploySiteRequestAction({
                            deploymentType: value,
                        }),
                    );
                    this.deploymentSelectionLoading = true;
                }
            });
    }

    downloadLicense(siteId: string, licenseRaw: string) {
        const data = new Blob([licenseRaw], { type: 'text/plain' });
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');

        link.setAttribute('href', url);
        link.setAttribute('download', `license-${siteId}.jwt`);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    generate(version: string | null, bundleType: BundleType | null) {
        if (!version || !bundleType) {
            return;
        }

        this.store.dispatch(
            createSiteDeploymentBundleAction({ bundleType, version }),
        );
    }

    download(uuid: string, bundleType: BundleType) {
        this.store.dispatch(downloadSiteDeploymentAction({ uuid, bundleType }));
    }

    finish() {
        this.router.navigate([`../${SiteFeature.DEVICE_CARE_DASHBOARD}`], {
            relativeTo: this.route,
        });
    }

    generateShouldBeDisabled(type: BundleType) {
        return (
            this.deployments[type]?.find(
                bundle =>
                    bundle.state === 'requested' || bundle.state === 'creating',
            ) !== undefined
        );
    }

    goToStudioDeployment() {
        this.selectedTabIndex = 1;
    }
}
