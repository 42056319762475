import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../../shared/shared.module';
import { PhoneCallComponent } from './phone-call.component';

@NgModule({
    declarations: [PhoneCallComponent],
    exports: [PhoneCallComponent],
    imports: [SharedModule, MatCardModule, MatIconModule],
})
export class PhoneCallModule {}
