import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { StateTimelineComponent } from './state-timeline.component';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
    declarations: [StateTimelineComponent],
    imports: [SharedModule, OverlayModule],
    exports: [StateTimelineComponent],
})
export class StateTimelineModule {}
