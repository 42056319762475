import { Component } from '@angular/core';
import { InfluxdbService } from '../../core/services/influxdb.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import {
    $deviceCareConfig,
    $metricWithData,
} from '../../store/sites/sites.selectors';
import { filter, withLatestFrom } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import * as moment from 'moment';

interface TagAction {
    from: number;
    to: string;
    color?: string;
}

interface TagActivity {
    alias: string | number;
    title: string | number;
    platform: string | number;
    inactiveFor: string;
    inactiveMinutes: number;
    status: string;
    color?: string;
}
@Component({
    selector: 'myrtls-inactive-tags-v2-modal',
    templateUrl: './inactive-tags-v2.modal.html',
    styleUrls: [],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class InactiveTagsV2Modal {
    metric = 'innactive-tags' as const;

    tagActivity: TagActivity[] = [];
    dataSource = new MatTableDataSource<TagActivity>([]);
    tagActions: TagAction[] = [];
    columns = ['alias', 'title', 'platform', 'status', 'inactiveFor'];
    loading = true;

    private inactiveTagsQuery = `from(bucket: "$BUCKET")
    |> range(start: 0)
    |> filter(fn: (r) => r["_measurement"] == "tag_batteries")
    |> filter(fn: (r) => r["_field"] == "batteryVoltage" )
    |> filter(fn: (r) => exists r["platform"])
    |> last()
    |> group(columns: ["title"])
    |> sort(columns: ["_time"])
    |> last()
    |> filter(fn: (r) => not contains(value: r.title, set: $OVERRIDES))
    |> map(fn: (r) => ({
        r with _value: int(v: float(v: uint(v: now()) - uint(v: r._time)) / 60000000000.0)
    }))`;

    constructor(
        private store: Store<AppState>,
        private influxdbService: InfluxdbService,
    ) {
        this.store
            .select($deviceCareConfig)
            .pipe(
                filter(config => !!config),
                withLatestFrom(this.store.select($metricWithData(this.metric))),
            )
            .subscribe(async ([config, metric]) => {
                if (!config || !metric) return;

                this.tagActivity = [];
                this.tagActions = [];

                const field = metric.historyTemplate?.fields[3];
                if (field && 'mappings' in field) {
                    const mapping = field.mappings;
                    if (typeof mapping !== 'string') {
                        mapping.forEach(action => {
                            const tagAction: TagAction = {
                                from: action.from,
                                to: action.to,
                                color: action.color,
                            };
                            this.tagActions.push(tagAction);
                        });
                    }
                }

                await this.influxdbService
                    .getDashboardChartData({
                        apiUrl: config.influx.host,
                        query: this.inactiveTagsQuery,
                        org: config.influx.organization,
                        token: config.influx.readToken,
                        bucket: config.influx.bucket,
                        overrides:
                            metric.overrides?.map(override => override.value) ||
                            [],
                    })
                    .then(data => {
                        data.forEach(tag => {
                            const tagActivity: TagActivity = {
                                alias: tag.alias || '',
                                title: tag.title || '',
                                status: 'inactive',
                                platform: tag.platform || '3rd party / unknown',
                                color: this.tagActions.find(
                                    action => action.from === tag._value,
                                )?.color,
                                inactiveFor: moment
                                    .duration(tag._value || 0, 'minutes')
                                    .humanize(),
                                inactiveMinutes: tag._value || 0,
                            };

                            if (
                                tag._value !== undefined &&
                                tag._value > metric.thresholdValue
                            ) {
                                this.tagActivity.push(tagActivity);
                            }
                        });
                    });

                this.dataSource.data = [...this.tagActivity];
                this.sortData({
                    active: 'inactiveFor',
                    direction: 'desc',
                });
                this.loading = false;
            });
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    sortData(sort: Sort) {
        const isAsc = sort.direction === 'asc';
        let key = sort.active as keyof TagActivity;

        this.dataSource.data = this.tagActivity
            .sort((a, b) => {
                if (key === 'inactiveFor') {
                    key = 'inactiveMinutes';
                }

                const aValue = String(a[key]);
                const bValue = String(b[key]);

                if (aValue == null && bValue == null) return 0;
                if (aValue == null) return isAsc ? -1 : 1;
                if (bValue == null) return isAsc ? 1 : -1;

                return this.compare(aValue, bValue, isAsc);
            })
            .slice();
    }

    private compare(
        a: string | boolean,
        b: string | boolean,
        isAsc: boolean,
    ): number {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
}
