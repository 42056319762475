import { Component } from '@angular/core';
import { InfluxdbService } from '../../core/services/influxdb.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import {
    $deviceCareConfig,
    $metricWithData,
} from '../../store/sites/sites.selectors';
import { filter, withLatestFrom } from 'rxjs';

interface Data {
    loading: boolean;
    color?: string;
    value?: string;
}

@Component({
    selector: 'myrtls-system-performance-v1-modal',
    templateUrl: './system-performance-v1.modal.html',
    styleUrls: [],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SystemPerformanceV1Modal {
    metric = 'system-performance' as const;

    cores: Data = {
        loading: true,
    };

    cpuUsed: Data = {
        loading: true,
    };

    ramUsed: Data = {
        loading: true,
    };

    ramUsedPercentage: Data = {
        loading: true,
    };

    ramTotal: Data = {
        loading: true,
    };

    cpuState: Data = {
        loading: true,
    };

    ramState: Data = {
        loading: true,
    };

    state: 'ok' | 'error' | 'warning' = 'ok';

    private coresQuery = `from(bucket: "$BUCKET")
|> range(start: -15m)
|> filter(fn: (r) => r["_measurement"] == "system")
|> filter(fn: (r) => r["_field"] == "n_cpus")
|> last()
`;

    private cpuQuery = `from(bucket: "$BUCKET")
|> range(start: -15m)
|> filter(fn: (r) => r["_measurement"] == "cpu")
|> filter(fn: (r) => r["_field"] == "usage_active")
|> filter(fn: (r) => r["cpu"] != "cpu-total")
|> min()
|> group()
|> max()
|> map(fn: (r) => ({r with _value: if r._value >= 95 then 2 else if r._value >= 75 and r._value < 95 then 1 else 0, originalValue: r._value}))
`;

    private ramQuery = `from(bucket: "$BUCKET")
|> range(start: -5m)
|> filter(fn: (r) => r["_measurement"] == "mem")
|> filter(fn: (r) => r["_field"] == "available" or r["_field"] == "total")
|> max()
|> map(fn: (r) => ({r with _value: r._value / 1000000000, originalValue: r._value / 1000000000}))
|> map(fn: (r) => ({r with _value: if r._value < 1 then 2 else if r._value >= 1 and r._value <= 4 then 1 else 0}))
|> group()`;

    constructor(
        private store: Store<AppState>,
        private influxdbService: InfluxdbService,
    ) {
        this.store
            .select($deviceCareConfig)
            .pipe(
                filter(config => !!config),
                withLatestFrom(this.store.select($metricWithData(this.metric))),
            )
            .subscribe(async ([config, metric]) => {
                if (!config || !metric) return;

                const mappings = metric.dashboardTemplate?.parameters?.mappings;

                await this.influxdbService
                    .getDashboardChartData({
                        apiUrl: config.influx.host,
                        query: this.coresQuery,
                        org: config.influx.organization,
                        token: config.influx.readToken,
                        bucket: config.influx.bucket,
                        overrides:
                            metric.overrides?.map(override => override.value) ||
                            [],
                    })
                    .then(data => {
                        if (data.length > 0) {
                            this.cores = {
                                loading: false,
                                value: (data[0]._value || 0).toString(),
                            };
                        }
                    });

                await this.influxdbService
                    .getDashboardChartData({
                        apiUrl: config.influx.host,
                        query: this.cpuQuery,
                        org: config.influx.organization,
                        token: config.influx.readToken,
                        bucket: config.influx.bucket,
                        overrides:
                            metric.overrides?.map(override => override.value) ||
                            [],
                    })
                    .then(data => {
                        if (data.length !== 1) {
                            return;
                        }

                        const cpu = data[0];

                        let originalValue = cpu.originalValue || 0;

                        if (typeof originalValue === 'string') {
                            originalValue = parseInt(originalValue, 10);
                        }

                        this.cpuUsed = {
                            loading: false,
                            value: this.getValue(originalValue, '', '%', 0),
                        };

                        const mapping = mappings?.find(
                            value => value.from === cpu._value,
                        );

                        this.cpuState = {
                            loading: false,
                            value: mapping?.to,
                            color: mapping?.color,
                        };

                        const mappingState = mapping?.state || 'ok';

                        if (this.isWorseState(mappingState, this.state)) {
                            this.state = mappingState;
                        }
                    });

                await this.influxdbService
                    .getDashboardChartData({
                        apiUrl: config.influx.host,
                        query: this.ramQuery,
                        org: config.influx.organization,
                        token: config.influx.readToken,
                        bucket: config.influx.bucket,
                        overrides:
                            metric.overrides?.map(override => override.value) ||
                            [],
                    })
                    .then(data => {
                        const available = data.find(
                            line => line._field === 'available',
                        );
                        const total = data.find(
                            line => line._field === 'total',
                        );

                        if (!available || !total) {
                            return;
                        }

                        let originalAvailableValue =
                            available.originalValue || 0;

                        let originalTotalValue = total.originalValue || 0;

                        if (typeof originalAvailableValue === 'string') {
                            originalAvailableValue = parseInt(
                                originalAvailableValue,
                                10,
                            );
                        }

                        if (typeof originalTotalValue === 'string') {
                            originalTotalValue = parseInt(
                                originalTotalValue,
                                10,
                            );
                        }

                        this.ramUsed = {
                            loading: false,
                            value: this.getValue(
                                originalTotalValue - originalAvailableValue,
                                'G',
                                'B',
                                1,
                            ),
                        };

                        this.ramUsedPercentage = {
                            loading: false,
                            value: this.getValue(
                                ((originalTotalValue - originalAvailableValue) /
                                    originalTotalValue) *
                                    100,
                                '',
                                '%',
                                0,
                            ),
                        };

                        this.ramTotal = {
                            loading: false,
                            value: this.getValue(
                                originalTotalValue,
                                'G',
                                'B',
                                1,
                            ),
                        };

                        const mapping = mappings?.find(
                            value => value.from === available._value,
                        );

                        this.ramState = {
                            loading: false,
                            value: mapping?.to,
                            color: mapping?.color,
                        };

                        const mappingState = mapping?.state || 'ok';

                        if (this.isWorseState(mappingState, this.state)) {
                            this.state = mappingState;
                        }
                    });
            });
    }

    private getValue(
        value: number,
        prefix: string,
        unit: string,
        fixedDigits: number,
    ): string {
        return value.toFixed(fixedDigits) + ' ' + prefix + unit;
    }

    private isWorseState(
        state: 'ok' | 'warning' | 'error',
        than: 'ok' | 'warning' | 'error',
    ): boolean {
        const order = ['ok', 'warning', 'error'];
        return order.indexOf(state) > order.indexOf(than);
    }
}
