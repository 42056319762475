import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    AlertInterval,
    Bundle,
    DeviceCare,
    DeviceCareInstallation,
    Feature,
    FeatureState,
    LicenseRaw,
    Metric,
    MetricsStatus,
    PhoneCall,
    Release,
    Report,
    RtlsPlayerOptions,
    Site,
    SiteFeature,
    SiteRtlsPlayer,
    SmtpStatus,
    SupportActivity,
    User,
    UserToken,
} from '@myrtls/api-interfaces';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
    catchError,
    filter,
    first,
    map,
    mapTo,
    mergeMap,
    switchMap,
    take,
    takeUntil,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { InfluxdbService } from '../../core/services/influxdb.service';
import { MetricDataFetchType } from '../../shared/models/metric-data-fetch-type.enum';
import { MetricWithData } from '../../shared/models/metric-with-data.model';
import {
    ACTIVATE_ENDPOINT,
    ACTIVITIES_ENDPOINT,
    ALERTS_ENDPOINT,
    BUNDLES_ENDPOINT,
    CLEAR_ENDPOINT,
    CREATE_TEMPLATES_ENDPOINT,
    CURRENT_ALERTS_ENDPOINT,
    DEPLOYMENTS_ENDPOINT,
    DEVICE_CARE_ENDPOINT,
    FEATURES_ENDPOINT,
    INSTALLATION_ENDPOINT,
    LICENSE_ENDPOINT,
    METRIC_OVERRIDES_ENDPOINT,
    METRICS_ENDPOINT,
    PERMISSIONS_API_URL,
    PHONE_CALL_ENDPOINT,
    PLAYERS_ENDPOINT,
    PLAYERS_OPTIONS_ENDPOINT,
    RELEASES_ENDPOINT,
    REPORTS_ENDPOINT,
    SITES_API_URL,
    SMTP_ENDPOINT,
    SNACK_BAR_DURATION,
    TEST_ENDPOINT,
    USERS_ENDPOINT,
    VERSION_API_URL,
} from '../../shared/utils/constants';
import { AppState } from '../index';
import { showSnackBarAction } from '../snack-bar/snack-bar.actions';
import {
    deleteUserFailureAction,
    deleteUserRequestAction,
    deleteUserSuccessAction,
    editUserPermissionsFailureAction,
    editUserPermissionsRequestAction,
    editUserPermissionsSuccessAction,
    getSiteReportsFailureAction,
    getSiteReportsRequestAction,
    getSiteReportsSuccessAction,
    getSiteAlertsFailureAction,
    getSiteAlertsRequestAction,
    getSiteAlertsSuccessAction,
    getSiteDeviceCareConfigFailureAction,
    getSiteDeviceCareConfigRequestAction,
    getSiteDeviceCareConfigSuccessAction,
    getSiteFailureAction,
    getSiteFeaturesFailureAction,
    getSiteFeaturesRequestAction,
    getSiteFeaturesSuccessAction,
    getSiteMetricDataFailureAction,
    getSiteMetricDataRequestAction,
    getSiteMetricDataSuccessAction,
    getSiteMetricsConfigFailureAction,
    getSiteMetricsConfigRequestAction,
    getSiteMetricsConfigSuccessAction,
    getSiteMetricsDataRequestAction,
    getSiteRequestAction,
    getSitesFailureAction,
    getSitesRequestAction,
    getSitesSuccessAction,
    getSiteSuccessAction,
    getSiteUsersFailureAction,
    getSiteUsersRequestAction,
    getSiteUsersSuccessAction,
    inviteUserFailureAction,
    inviteUserRequestAction,
    inviteUserSuccessAction,
    selectPeriodDateRangeAction,
    updateSiteMetricSettingsFailureAction,
    updateSiteMetricSettingsRequestAction,
    updateSiteMetricSettingsSuccessAction,
    updateSiteReportSettingsRequestAction,
    updateSiteReportSettingsSuccessAction,
    updateSiteReportSettingsFailureAction,
    getSiteMetricDashboardDataRequestAction,
    getSiteMetricDashboardDataSuccessAction,
    getSiteMetricDashboardDataFailureAction,
    getSiteReportRequestAction,
    getCurrentSiteAlertsRequestAction,
    getCurrentSiteAlertsSuccessAction,
    getCurrentSiteAlertsFailureAction,
    startPoolingSiteMetricsStatusAction,
    getSiteMetricsStatusSuccessAction,
    getSiteMetricsStatusFailureAction,
    stopPoolingSiteMetricsStatusAction,
    activateDeviceCareRequestAction,
    activateDeviceCareSuccessAction,
    activateDeviceCareFailureAction,
    getSitePlayerRequestAction,
    getSitePlayerSuccessAction,
    getSitePlayerFailureAction,
    getPlayerOptionsRequestAction,
    getPlayerOptionsSuccessAction,
    getPlayerOptionsFailureAction,
    initializeSitePlayerRequestAction,
    initializeSitePlayerSuccessAction,
    initializeSitePlayerFailureAction,
    startPoolingSitePlayerRequestAction,
    stopPoolingSitePlayerRequestAction,
    updateSitePlayerRequestAction,
    updateSitePlayerSuccessAction,
    updateSitePlayerFailureAction,
    getSiteActivitiesRequestAction,
    getSiteActivitiesSuccessAction,
    getSiteActivitiesFailureAction,
    getPhoneCallRequestAction,
    getPhoneCallSuccessAction,
    getPhoneCallFailureAction,
    getSiteDeviceCareInstallationRequestAction,
    getSiteDeviceCareInstallationSuccessAction,
    getSiteDeviceCareInstallationFailureAction,
    createSiteTemplatesRequestAction,
    createSiteTemplatesSuccessAction,
    createSiteTemplatesFailureAction,
    clearDeviceCareRequestAction,
    clearDeviceCareSuccessAction,
    clearDeviceCareFailureAction,
    deploySiteRequestAction,
    deploySiteSuccessAction,
    deploySiteFailureAction,
    getSiteLicenseRawRequestAction,
    getSiteLicenseRawSuccessAction,
    getSiteLicenseRawFailureAction,
    getSiteSmtpStatusRequestAction,
    getSiteSmtpStatusSuccessAction,
    getSiteSmtpStatusFailureAction,
    downloadSiteDeploymentAction,
    downloadSiteDeploymentSuccessAction,
    downloadSiteDeploymentFailureAction,
    getSiteDeploymentBundlesSuccessAction,
    getSiteDeploymentBundlesFailureAction,
    createSiteDeploymentBundleAction,
    createSiteDeploymentBundleSuccessAction,
    getVersionAction,
    getVersionSuccessAction,
    getVersionFailureAction,
    startPoolingSiteDeploymentBundlesAction,
    getSiteDeploymentBundlesAction,
    getSiteReleasesAction,
    getSiteReleasesSuccessAction,
    getSiteReleasesFailureAction,
    stopPoolingSiteDeploymentBundlesAction,
    getSiteMetricFilteringDataSuccessAction,
    getSiteMetricFilteringDataFailureAction,
    getSiteMetricFilteringDataRequestAction,
    updateSiteMetricOverridesRequestAction,
    updateSiteMetricOverridesSuccessAction,
    updateSiteMetricOverridesFailureAction,
} from './sites.actions';
import { DeviceCareInfo } from './sites.reducer';
import {
    $deviceCareConfig,
    $metricsWithData,
    $selectedPeriod,
    $selectedSiteId,
} from './sites.selectors';
import { ActivatedRoute, ActivationStart, Router } from '@angular/router';
import { RoutePath } from '../../shared/models/route-path.enum';
import { SnackBarType } from '../../shared/models/snack-bar-config.model';
import { combineLatest, EMPTY, from as fromPromise, interval, of } from 'rxjs';
import { Location } from '@angular/common';
import * as moment from 'moment';

@Injectable()
export class SitesEffects {
    private httpWithoutAuth: HttpClient;

    getSites$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(getSitesRequestAction),
                withLatestFrom(
                    this.router.events.pipe(
                        filter(
                            (event): event is ActivationStart =>
                                event instanceof ActivationStart,
                        ),
                    ),
                ),
            )
            .pipe(
                switchMap(([, event]) => {
                    const params = event.snapshot.queryParams;
                    let httpSites;

                    if (params.serverToken) {
                        httpSites = this.httpWithoutAuth.get<Site[]>(
                            SITES_API_URL,
                            {
                                headers: {
                                    Authorization:
                                        'Bearer ' + params.serverToken,
                                },
                            },
                        );
                    } else {
                        httpSites = this.http.get<Site[]>(SITES_API_URL);
                    }

                    return httpSites.pipe(
                        map(sites => {
                            return getSitesSuccessAction({ sites });
                        }),
                        catchError(error =>
                            of(getSitesFailureAction({ error })),
                        ),
                    );
                }),
            ),
    );

    getSite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSiteRequestAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((siteId): siteId is string => siteId != null),
                ),
            ),
            switchMap(([, siteId]) =>
                this.http.get<Site>(`${SITES_API_URL}/${siteId}`).pipe(
                    map(project => getSiteSuccessAction({ site: project })),
                    catchError(error => of(getSiteFailureAction({ error }))),
                ),
            ),
        ),
    );

    selectSite$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(getSiteFeaturesSuccessAction),
                tap(features => {
                    if (
                        features.features.find(
                            feature =>
                                feature.name ===
                                SiteFeature.DEVICE_CARE_GRAFANA,
                        )?.state === FeatureState.ENABLED
                    ) {
                        this.http
                            .get(
                                `${SITES_API_URL}/${features.siteId}/${DEVICE_CARE_ENDPOINT}/context`,
                            )
                            .pipe(first())
                            .subscribe();
                    }
                }),
            ),
        { dispatch: false },
    );

    getSiteUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSiteUsersRequestAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
            ),
            switchMap(([, siteId]) =>
                this.http
                    .get<User[]>(`${SITES_API_URL}/${siteId}/${USERS_ENDPOINT}`)
                    .pipe(
                        map(users =>
                            getSiteUsersSuccessAction({ siteId, users }),
                        ),
                        catchError(error =>
                            of(getSiteUsersFailureAction({ error })),
                        ),
                    ),
            ),
        ),
    );

    inviteUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(inviteUserRequestAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
            ),
            switchMap(([{ user }, siteId]) =>
                this.http
                    .post<string>(
                        `${SITES_API_URL}/${siteId}/${USERS_ENDPOINT}`,
                        user,
                    )
                    .pipe(
                        mergeMap(id => [
                            showSnackBarAction({
                                config: {
                                    type: SnackBarType.SUCCESSFUL,
                                    duration: SNACK_BAR_DURATION,
                                    description:
                                        'User was invited successfully!',
                                },
                            }),
                            inviteUserSuccessAction({
                                siteId,
                                user: { ...user, id },
                            }),
                            getSiteUsersRequestAction(),
                        ]),
                        catchError(error =>
                            of(inviteUserFailureAction({ error })),
                        ),
                    ),
            ),
        ),
    );

    editUserPermissions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(editUserPermissionsRequestAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
            ),
            switchMap(([{ user }, siteId]) =>
                this.http
                    .put<void>(
                        `${SITES_API_URL}/${siteId}/${USERS_ENDPOINT}/${user.id}`,
                        {
                            name: user.name,
                            permissions: user.permissions,
                        },
                    )
                    .pipe(
                        mergeMap(() => [
                            showSnackBarAction({
                                config: {
                                    type: SnackBarType.SUCCESSFUL,
                                    duration: SNACK_BAR_DURATION,
                                    description:
                                        "User's permissions has changed successfully!",
                                },
                            }),
                            editUserPermissionsSuccessAction({
                                siteId,
                                user,
                            }),
                            getSiteUsersRequestAction(),
                        ]),
                        catchError(error =>
                            of(editUserPermissionsFailureAction({ error })),
                        ),
                    ),
            ),
        ),
    );

    deleteUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteUserRequestAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
            ),
            switchMap(([{ userId }, siteId]) =>
                this.http
                    .delete<void>(
                        `${SITES_API_URL}/${siteId}/${USERS_ENDPOINT}/${userId}`,
                    )
                    .pipe(
                        mergeMap(() => [
                            showSnackBarAction({
                                config: {
                                    type: SnackBarType.SUCCESSFUL,
                                    duration: SNACK_BAR_DURATION,
                                    description:
                                        'User was removed successfully!',
                                },
                            }),
                            deleteUserSuccessAction({ siteId, userId }),
                        ]),
                        catchError(error =>
                            of(deleteUserFailureAction({ error })),
                        ),
                    ),
            ),
        ),
    );

    getSiteSmtpStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSiteSmtpStatusRequestAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
                this.route.queryParams,
            ),
            switchMap(([, siteId, params]) => {
                if (params.serverToken) {
                    return this.httpWithoutAuth
                        .get<SmtpStatus>(
                            `${SITES_API_URL}/${siteId}/${SMTP_ENDPOINT}`,
                            {
                                headers: {
                                    Authorization:
                                        'Bearer ' + params.serverToken,
                                },
                            },
                        )
                        .pipe(
                            map(smtpStatus =>
                                getSiteSmtpStatusSuccessAction({
                                    siteId,
                                    smtpStatus,
                                }),
                            ),
                            catchError(error =>
                                of(getSiteSmtpStatusFailureAction({ error })),
                            ),
                        );
                } else {
                    return this.http
                        .get<SmtpStatus>(
                            `${SITES_API_URL}/${siteId}/${SMTP_ENDPOINT}`,
                        )
                        .pipe(
                            map(smtpStatus =>
                                getSiteSmtpStatusSuccessAction({
                                    siteId,
                                    smtpStatus,
                                }),
                            ),
                            catchError(error =>
                                of(getSiteSmtpStatusFailureAction({ error })),
                            ),
                        );
                }
            }),
        ),
    );

    getSiteFeatures$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSiteFeaturesRequestAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
                this.route.queryParams,
            ),
            switchMap(([, siteId, params]) => {
                if (params.serverToken) {
                    return this.httpWithoutAuth
                        .get<Feature[]>(
                            `${SITES_API_URL}/${siteId}/${FEATURES_ENDPOINT}`,
                            {
                                headers: {
                                    Authorization:
                                        'Bearer ' + params.serverToken,
                                },
                            },
                        )
                        .pipe(
                            map(features =>
                                getSiteFeaturesSuccessAction({
                                    siteId,
                                    features,
                                }),
                            ),
                            catchError(error =>
                                of(getSiteFeaturesFailureAction({ error })),
                            ),
                        );
                } else {
                    return this.http
                        .get<
                            Feature[]
                        >(`${SITES_API_URL}/${siteId}/${FEATURES_ENDPOINT}`)
                        .pipe(
                            map(features =>
                                getSiteFeaturesSuccessAction({
                                    siteId,
                                    features,
                                }),
                            ),
                            catchError(error =>
                                of(getSiteFeaturesFailureAction({ error })),
                            ),
                        );
                }
            }),
        ),
    );

    getSiteLicenseRaw$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSiteLicenseRawRequestAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
            ),
            switchMap(([, siteId]) => {
                return this.http
                    .get<LicenseRaw>(
                        `${SITES_API_URL}/${siteId}/${LICENSE_ENDPOINT}`,
                    )
                    .pipe(
                        map(license =>
                            getSiteLicenseRawSuccessAction({
                                siteId,
                                licenseRaw: license.licenseRaw,
                            }),
                        ),
                        catchError(error =>
                            of(getSiteLicenseRawFailureAction({ error })),
                        ),
                    );
            }),
        ),
    );

    getSiteDeviceCareConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSiteDeviceCareConfigRequestAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
                this.route.queryParams,
            ),
            switchMap(([{ period }, siteId, params]) => {
                if (params.serverToken) {
                    return this.httpWithoutAuth
                        .get<DeviceCare>(
                            `${SITES_API_URL}/${siteId}/${DEVICE_CARE_ENDPOINT}`,
                            {
                                headers: {
                                    Authorization:
                                        'Bearer ' + params.serverToken,
                                },
                            },
                        )
                        .pipe(
                            map(config =>
                                getSiteDeviceCareConfigSuccessAction({
                                    siteId,
                                    config,
                                    period,
                                }),
                            ),
                            catchError(error =>
                                of(
                                    getSiteDeviceCareConfigFailureAction({
                                        error,
                                    }),
                                ),
                            ),
                        );
                } else {
                    return this.http
                        .get<DeviceCare>(
                            `${SITES_API_URL}/${siteId}/${DEVICE_CARE_ENDPOINT}`,
                        )
                        .pipe(
                            map(config =>
                                getSiteDeviceCareConfigSuccessAction({
                                    siteId,
                                    config,
                                    period,
                                }),
                            ),
                            catchError(error =>
                                of(
                                    getSiteDeviceCareConfigFailureAction({
                                        error,
                                    }),
                                ),
                            ),
                        );
                }
            }),
        ),
    );

    getPlayerOptions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getPlayerOptionsRequestAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
            ),
            switchMap(([, siteId]) => {
                return this.http
                    .get<RtlsPlayerOptions>(
                        `${SITES_API_URL}/${siteId}/${PLAYERS_ENDPOINT}/${PLAYERS_OPTIONS_ENDPOINT}`,
                    )
                    .pipe(
                        map(options =>
                            getPlayerOptionsSuccessAction({
                                options,
                            }),
                        ),
                        catchError(error =>
                            of(
                                getPlayerOptionsFailureAction({
                                    error,
                                }),
                            ),
                        ),
                    );
            }),
        ),
    );

    initializeSitePlayer = createEffect(() =>
        this.actions$.pipe(
            ofType(initializeSitePlayerRequestAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
            ),
            switchMap(([{ parameters }, siteId]) => {
                return this.http
                    .post<SiteRtlsPlayer>(
                        `${SITES_API_URL}/${siteId}/${PLAYERS_ENDPOINT}`,
                        parameters,
                    )
                    .pipe(
                        map(player =>
                            initializeSitePlayerSuccessAction({
                                player,
                            }),
                        ),
                        catchError(error =>
                            of(
                                initializeSitePlayerFailureAction({
                                    error,
                                }),
                            ),
                        ),
                    );
            }),
        ),
    );

    updateSitePlayer = createEffect(() =>
        this.actions$.pipe(
            ofType(updateSitePlayerRequestAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
            ),
            switchMap(([{ parameters }, siteId]) => {
                return this.http
                    .put<SiteRtlsPlayer>(
                        `${SITES_API_URL}/${siteId}/${PLAYERS_ENDPOINT}`,
                        parameters,
                    )
                    .pipe(
                        map(player =>
                            updateSitePlayerSuccessAction({
                                player,
                            }),
                        ),
                        catchError(error =>
                            of(
                                updateSitePlayerFailureAction({
                                    error,
                                }),
                            ),
                        ),
                    );
            }),
        ),
    );

    startPoolingDeploymentBundles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(startPoolingSiteDeploymentBundlesAction),
            mergeMap(({ intervalTime, bundleType }) =>
                interval(intervalTime).pipe(
                    takeUntil(
                        this.actions$.pipe(
                            ofType(stopPoolingSiteDeploymentBundlesAction),
                        ),
                    ),
                    map(() =>
                        getSiteDeploymentBundlesAction({
                            bundleType,
                        }),
                    ),
                ),
            ),
        ),
    );

    getReleases$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSiteReleasesAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
            ),
            switchMap(([, siteId]) =>
                this.http
                    .get<
                        Release[]
                    >(`${SITES_API_URL}/${siteId}/${DEPLOYMENTS_ENDPOINT}/${RELEASES_ENDPOINT}`)
                    .pipe(
                        map(releases =>
                            getSiteReleasesSuccessAction({
                                releases,
                            }),
                        ),
                        catchError(error =>
                            of(
                                getSiteReleasesFailureAction({
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    getDeploymentBundles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSiteDeploymentBundlesAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
            ),
            mergeMap(([{ bundleType }, siteId]) => {
                return this.http
                    .get<
                        Bundle[]
                    >(`${SITES_API_URL}/${siteId}/${DEPLOYMENTS_ENDPOINT}/${BUNDLES_ENDPOINT}/${bundleType}`)
                    .pipe(
                        map(response =>
                            getSiteDeploymentBundlesSuccessAction({
                                bundles: response,
                                bundleType,
                            }),
                        ),
                        catchError(error =>
                            of(
                                getSiteDeploymentBundlesFailureAction({
                                    error,
                                }),
                            ),
                        ),
                    );
            }),
        ),
    );

    startPoolingSitePlayers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(startPoolingSitePlayerRequestAction),
            switchMap(({ intervalTime }) =>
                interval(intervalTime).pipe(
                    takeUntil(
                        this.actions$.pipe(
                            ofType(stopPoolingSitePlayerRequestAction),
                        ),
                    ),
                    map(getSitePlayerRequestAction),
                ),
            ),
        ),
    );

    getSitePlayer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSitePlayerRequestAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
            ),
            switchMap(([, siteId]) => {
                return this.http
                    .get<SiteRtlsPlayer>(
                        `${SITES_API_URL}/${siteId}/${PLAYERS_ENDPOINT}`,
                    )
                    .pipe(
                        map(player =>
                            getSitePlayerSuccessAction({
                                player,
                            }),
                        ),
                        catchError(error =>
                            of(
                                getSitePlayerFailureAction({
                                    error,
                                }),
                            ),
                        ),
                    );
            }),
        ),
    );

    getSiteActivities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSiteActivitiesRequestAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
            ),
            switchMap(([, siteId]) => {
                return this.http
                    .get<
                        SupportActivity[]
                    >(`${SITES_API_URL}/${siteId}/${ACTIVITIES_ENDPOINT}`)
                    .pipe(
                        map(activities =>
                            getSiteActivitiesSuccessAction({
                                activities,
                            }),
                        ),
                        catchError(error =>
                            of(
                                getSiteActivitiesFailureAction({
                                    error,
                                }),
                            ),
                        ),
                    );
            }),
        ),
    );

    getSiteMetricsConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSiteMetricsConfigRequestAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
                this.route.queryParams,
            ),
            switchMap(([{ dataFetch }, siteId, params]) => {
                if (params.serverToken) {
                    return this.httpWithoutAuth
                        .get<Metric[]>(
                            `${SITES_API_URL}/${siteId}/${DEVICE_CARE_ENDPOINT}/${METRICS_ENDPOINT}`,
                            {
                                headers: {
                                    Authorization:
                                        'Bearer ' + params.serverToken,
                                },
                            },
                        )
                        .pipe(
                            map(metrics =>
                                getSiteMetricsConfigSuccessAction({
                                    siteId,
                                    metrics,
                                    dataFetch,
                                }),
                            ),
                            catchError(error =>
                                of(
                                    getSiteMetricsConfigFailureAction({
                                        error,
                                    }),
                                ),
                            ),
                        );
                } else {
                    return this.http
                        .get<
                            Metric[]
                        >(`${SITES_API_URL}/${siteId}/${DEVICE_CARE_ENDPOINT}/${METRICS_ENDPOINT}`)
                        .pipe(
                            map(metrics =>
                                getSiteMetricsConfigSuccessAction({
                                    siteId,
                                    metrics,
                                    dataFetch,
                                }),
                            ),
                            catchError(error =>
                                of(
                                    getSiteMetricsConfigFailureAction({
                                        error,
                                    }),
                                ),
                            ),
                        );
                }
            }),
        ),
    );

    getSiteMetricsDataFromPeriod$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectPeriodDateRangeAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((siteId): siteId is string => siteId != null),
                ),
                this.store.pipe(select($metricsWithData)),
            ),
            tap(([period]) => {
                const queryParams = new URLSearchParams(window.location.search);
                queryParams.set(
                    'from',
                    moment(period.from).format('YYYY-MM-DD'),
                );
                queryParams.set('to', moment(period.to).format('YYYY-MM-DD'));
                this.location.replaceState(
                    window.location.pathname,
                    queryParams.toString(),
                );
            }),
            map(([, siteId, metrics]) => {
                return getSiteMetricsDataRequestAction({
                    siteId,
                    metrics,
                });
            }),
        ),
    );

    getSiteMetricsDashboardData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSiteMetricsConfigSuccessAction),
            filter(
                ({ dataFetch }) =>
                    dataFetch === MetricDataFetchType.DASHBOARD ||
                    dataFetch === MetricDataFetchType.ALL,
            ),
            switchMap(({ siteId, metrics }) =>
                this.store.pipe(
                    select($deviceCareConfig),
                    filter(
                        (config): config is DeviceCareInfo => config != null,
                    ),
                    take(1),
                    map(deviceCare => ({
                        deviceCare,
                        metrics,
                        siteId,
                    })),
                ),
            ),
            mergeMap(({ siteId, metrics, deviceCare }) =>
                metrics
                    .filter(metric => metric.dashboardTemplate)
                    .filter(
                        metric =>
                            metric.dashboardTemplate?.query != null &&
                            metric.dashboard,
                    )
                    .map(
                        ({
                            id,
                            dashboardTemplate,
                            overrides,
                            thresholdValue,
                        }) =>
                            getSiteMetricDashboardDataRequestAction({
                                siteId,
                                metricId: id,
                                threshold: thresholdValue,
                                query: dashboardTemplate?.query || '',
                                deviceCare,
                                overrides:
                                    overrides?.map(
                                        override => override.value,
                                    ) || [],
                            }),
                    ),
            ),
        ),
    );

    getSiteMetricDashboardData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSiteMetricDashboardDataRequestAction),
            mergeMap(
                ({
                    metricId,
                    query,
                    siteId,
                    deviceCare: {
                        influx: { host, organization, readToken, bucket },
                        selectedPeriod,
                    },
                    overrides,
                    threshold,
                }) => {
                    return fromPromise(
                        this.influxDbService.getDashboardChartData({
                            apiUrl: host,
                            org: organization,
                            token: readToken,
                            query,
                            bucket,
                            overrides,
                            to: selectedPeriod?.to,
                            threshold,
                        }),
                    ).pipe(
                        map(data => {
                            return getSiteMetricDashboardDataSuccessAction({
                                metricId,
                                siteId,
                                data,
                            });
                        }),
                        catchError(error =>
                            of(
                                getSiteMetricDashboardDataFailureAction({
                                    error,
                                }),
                            ),
                        ),
                    );
                },
            ),
        ),
    );

    getSiteMetricsHistoryData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                getSiteMetricsDataRequestAction,
                getSiteMetricsConfigSuccessAction,
            ),
            filter(action => {
                return (
                    action.type !== getSiteMetricsConfigSuccessAction.type ||
                    action.dataFetch === MetricDataFetchType.HISTORY ||
                    action.dataFetch === MetricDataFetchType.ALL
                );
            }),
            switchMap(action => {
                return combineLatest([
                    this.store.pipe(select($deviceCareConfig)),
                ]).pipe(
                    filter(
                        (config): config is [DeviceCareInfo] =>
                            config[0] !== null && config[0] !== undefined,
                    ),
                    take(1),
                    map(([deviceCare]) => ({
                        deviceCare,
                        metrics: action.metrics as MetricWithData[],
                        siteId: action.siteId,
                    })),
                );
            }),
            mergeMap(({ siteId, metrics, deviceCare }) =>
                metrics
                    .filter(
                        ({ historyTemplate }) => historyTemplate !== undefined,
                    )
                    .map(({ id, historyTemplate, overrides }) => {
                        return getSiteMetricDataRequestAction({
                            siteId,
                            metricId: id,
                            query: historyTemplate?.query || '',
                            deviceCare,
                            overrides:
                                overrides?.map(override => override.value) ||
                                [],
                        });
                    }),
            ),
        ),
    );

    clearDeviceCare$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clearDeviceCareRequestAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
            ),
            switchMap(([{ clearDeviceCareRequest }, siteId]) =>
                this.http
                    .put(
                        `${SITES_API_URL}/${siteId}/${DEVICE_CARE_ENDPOINT}/${CLEAR_ENDPOINT}`,
                        clearDeviceCareRequest,
                    )
                    .pipe(
                        mergeMap(() => [
                            clearDeviceCareSuccessAction(),
                            showSnackBarAction({
                                config: {
                                    description:
                                        'Device Care data deletion has been requested.',
                                    type: SnackBarType.SUCCESSFUL,
                                    duration: SNACK_BAR_DURATION,
                                },
                            }),
                        ]),
                        catchError(error =>
                            of(
                                clearDeviceCareFailureAction({
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    activateDeviceCare$ = createEffect(() =>
        this.actions$.pipe(
            ofType(activateDeviceCareRequestAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
            ),
            switchMap(([, siteId]) =>
                this.http
                    .put<number>(
                        `${SITES_API_URL}/${siteId}/${DEVICE_CARE_ENDPOINT}/${ACTIVATE_ENDPOINT}`,
                        {},
                    )
                    .pipe(
                        mergeMap(date => [
                            getSiteFeaturesRequestAction(),
                            activateDeviceCareSuccessAction({
                                date: date,
                                site: siteId,
                            }),
                        ]),
                        catchError(error =>
                            of(
                                activateDeviceCareFailureAction({
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    deploySite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deploySiteRequestAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
            ),
            switchMap(([request, siteId]) =>
                this.http
                    .put<void>(
                        `${SITES_API_URL}/${siteId}/${DEPLOYMENTS_ENDPOINT}`,
                        { deploymentType: request.deploymentType },
                    )
                    .pipe(
                        mergeMap(() => [
                            getSiteRequestAction(),
                            getSiteFeaturesRequestAction(),
                            deploySiteSuccessAction(),
                        ]),
                        catchError(error =>
                            of(
                                deploySiteFailureAction({
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    createDashboardTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createSiteTemplatesRequestAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
            ),
            switchMap(([, siteId]) =>
                this.http
                    .put<void>(
                        `${SITES_API_URL}/${siteId}/${DEVICE_CARE_ENDPOINT}/${CREATE_TEMPLATES_ENDPOINT}`,
                        {},
                    )
                    .pipe(
                        mergeMap(() => [createSiteTemplatesSuccessAction()]),
                        catchError(error =>
                            of(
                                createSiteTemplatesFailureAction({
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    onDashboardTemplatesCreated$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(createSiteTemplatesSuccessAction),
                withLatestFrom(
                    this.store.pipe(
                        select($selectedSiteId),
                        filter((id): id is string => id != null),
                    ),
                ),
                tap(([, siteId]) => {
                    this.router.navigateByUrl(
                        `/${RoutePath.SITE}/${siteId}/${SiteFeature.DEVICE_CARE_GRAFANA}`,
                    );
                }),
            ),
        { dispatch: false },
    );

    onDeviceCareActivated$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(activateDeviceCareSuccessAction),
                withLatestFrom(
                    this.store.pipe(
                        select($selectedSiteId),
                        filter((id): id is string => id != null),
                    ),
                ),
                tap(([, siteId]) => {
                    this.actions$
                        .pipe(
                            ofType(getSiteFeaturesSuccessAction),
                            takeUntil(
                                this.actions$.pipe(
                                    ofType(getSiteFeaturesFailureAction),
                                ),
                            ),
                            first(),
                        )
                        .subscribe(() => {
                            this.router.navigateByUrl(
                                `/${RoutePath.SITE}/${siteId}/${SiteFeature.DEVICE_CARE_DASHBOARD}`,
                            );
                        });
                }),
            ),
        { dispatch: false },
    );

    getSiteMetricsStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(startPoolingSiteMetricsStatusAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
            ),
            switchMap(([{ intervalTime }, siteId]) =>
                interval(intervalTime).pipe(
                    takeUntil(
                        this.actions$.pipe(
                            ofType(stopPoolingSiteMetricsStatusAction),
                        ),
                    ),
                    mapTo(
                        this.http
                            .get<MetricsStatus>(
                                `${SITES_API_URL}/${siteId}/${DEVICE_CARE_ENDPOINT}/${TEST_ENDPOINT}`,
                            )
                            .pipe(
                                mergeMap(data => [
                                    getSiteMetricsStatusSuccessAction({
                                        status: data,
                                    }),
                                ]),
                                catchError(error =>
                                    of(
                                        getSiteMetricsStatusFailureAction({
                                            error,
                                        }),
                                    ),
                                ),
                            ),
                    ),
                    mergeMap(merged => merged),
                ),
            ),
        ),
    );

    getSiteMetricFilteringData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSiteMetricFilteringDataRequestAction),
            withLatestFrom(
                this.store
                    .select($selectedSiteId)
                    .pipe(filter((x): x is string => !!x)),
                this.store
                    .select($deviceCareConfig)
                    .pipe(filter((x): x is DeviceCareInfo => !!x)),
                this.store.select($metricsWithData),
            ),
            mergeMap(([{ metricId }, siteId, deviceCare, metrics]) => {
                return fromPromise(
                    this.influxDbService.getDashboardChartData({
                        apiUrl: deviceCare.influx.host,
                        org: deviceCare.influx.organization,
                        token: deviceCare.influx.readToken,
                        bucket: deviceCare.influx.bucket,
                        query:
                            metrics.find(metric => metric.id === metricId)
                                ?.dashboardTemplate?.query || '',
                        overrides: [],
                    }),
                ).pipe(
                    map(data =>
                        getSiteMetricFilteringDataSuccessAction({
                            metricId,
                            siteId,
                            data: InfluxdbService.mapRowsToFilteringData(
                                data,
                                metrics.find(metric => metric.id === metricId)
                                    ?.labelGroups || [],
                            ),
                        }),
                    ),
                    catchError(error =>
                        of(
                            getSiteMetricFilteringDataFailureAction({
                                error,
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    getSiteMetricData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSiteMetricDataRequestAction),
            withLatestFrom(this.store.select($selectedPeriod)),
            mergeMap(
                ([
                    {
                        metricId,
                        query,
                        siteId,
                        deviceCare: {
                            influx: { host, organization, readToken, bucket },
                        },
                        overrides,
                    },
                    selectedPeriod,
                ]) => {
                    if (!selectedPeriod) {
                        return EMPTY;
                    }

                    return fromPromise(
                        this.influxDbService.getChartData({
                            apiUrl: host,
                            org: organization,
                            token: readToken,
                            query,
                            bucket,
                            from: selectedPeriod.from,
                            to: selectedPeriod.to,
                            dateRange: selectedPeriod.dateRange,
                            overrides,
                        }),
                    ).pipe(
                        map(data =>
                            getSiteMetricDataSuccessAction({
                                metricId,
                                siteId,
                                data,
                                range: selectedPeriod.dateRange,
                                from: selectedPeriod.from,
                                to: selectedPeriod.to,
                            }),
                        ),
                        catchError(error =>
                            of(getSiteMetricDataFailureAction({ error })),
                        ),
                    );
                },
            ),
        ),
    );

    updateSiteMetricSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateSiteMetricSettingsRequestAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
            ),
            switchMap(([{ metricPutRequest }, siteId]) =>
                this.http
                    .put<
                        Metric[]
                    >(`${SITES_API_URL}/${siteId}/${DEVICE_CARE_ENDPOINT}/${METRICS_ENDPOINT}`, metricPutRequest)
                    .pipe(
                        mergeMap(metrics => {
                            return [
                                showSnackBarAction({
                                    config: {
                                        type: SnackBarType.SUCCESSFUL,
                                        duration: SNACK_BAR_DURATION,
                                        description:
                                            'Metric settings have been updated successfully!',
                                    },
                                }),
                                updateSiteMetricSettingsSuccessAction({
                                    metrics,
                                    siteId,
                                }),
                            ];
                        }),
                        catchError(error =>
                            of(
                                updateSiteMetricSettingsFailureAction({
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    updateSiteMetricOverrides$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateSiteMetricOverridesRequestAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
            ),
            switchMap(([{ metricId, metricOverridesPutRequest }, siteId]) =>
                this.http
                    .put(
                        `${SITES_API_URL}/${siteId}/${DEVICE_CARE_ENDPOINT}/${METRICS_ENDPOINT}/${metricId}/${METRIC_OVERRIDES_ENDPOINT}`,
                        metricOverridesPutRequest,
                    )
                    .pipe(
                        mergeMap(() => {
                            return [
                                showSnackBarAction({
                                    config: {
                                        type: SnackBarType.SUCCESSFUL,
                                        duration: SNACK_BAR_DURATION,
                                        description:
                                            'Metric has been updated successfully!',
                                    },
                                }),
                                updateSiteMetricOverridesSuccessAction(),
                                getSiteMetricsConfigRequestAction({
                                    dataFetch: MetricDataFetchType.NONE,
                                }),
                            ];
                        }),
                        catchError(error =>
                            of(
                                updateSiteMetricOverridesFailureAction({
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    updateSiteReportSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateSiteReportSettingsRequestAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
            ),
            switchMap(([{ deviceCarePutRequest }, siteId]) =>
                this.http
                    .put<DeviceCare>(
                        `${SITES_API_URL}/${siteId}/${DEVICE_CARE_ENDPOINT}`,
                        deviceCarePutRequest,
                    )
                    .pipe(
                        mergeMap(config => {
                            return [
                                showSnackBarAction({
                                    config: {
                                        type: SnackBarType.SUCCESSFUL,
                                        duration: SNACK_BAR_DURATION,
                                        description:
                                            'Report settings have been updated successfully!',
                                    },
                                }),
                                updateSiteReportSettingsSuccessAction({
                                    config,
                                    siteId,
                                }),
                            ];
                        }),
                        catchError(error =>
                            of(
                                updateSiteReportSettingsFailureAction({
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    getPhoneCall$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getPhoneCallRequestAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
            ),
            switchMap(([, siteId]) =>
                this.http
                    .get<PhoneCall>(
                        `${SITES_API_URL}/${siteId}/${PHONE_CALL_ENDPOINT}`,
                    )
                    .pipe(
                        map(phoneCall =>
                            getPhoneCallSuccessAction({ phoneCall }),
                        ),
                        catchError(error =>
                            of(getPhoneCallFailureAction({ error })),
                        ),
                    ),
            ),
        ),
    );

    getDeviceCareInstallation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSiteDeviceCareInstallationRequestAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
            ),
            switchMap(([, siteId]) =>
                this.http
                    .get<DeviceCareInstallation>(
                        `${SITES_API_URL}/${siteId}/${DEVICE_CARE_ENDPOINT}/${INSTALLATION_ENDPOINT}`,
                    )
                    .pipe(
                        map(installation =>
                            getSiteDeviceCareInstallationSuccessAction({
                                installation,
                            }),
                        ),
                        catchError(error =>
                            of(
                                getSiteDeviceCareInstallationFailureAction({
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    getSiteAlerts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSiteAlertsRequestAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
                this.route.queryParams,
            ),
            switchMap(([, siteId, params]) => {
                let httpAlerts;

                if (params.serverToken) {
                    httpAlerts = this.httpWithoutAuth.get<AlertInterval[]>(
                        `${SITES_API_URL}/${siteId}/${DEVICE_CARE_ENDPOINT}/${ALERTS_ENDPOINT}`,
                        {
                            headers: {
                                Authorization: 'Bearer ' + params.serverToken,
                            },
                        },
                    );
                } else {
                    httpAlerts = this.http.get<AlertInterval[]>(
                        `${SITES_API_URL}/${siteId}/${DEVICE_CARE_ENDPOINT}/${ALERTS_ENDPOINT}`,
                    );
                }

                return httpAlerts.pipe(
                    map(alerts =>
                        getSiteAlertsSuccessAction({
                            alerts,
                        }),
                    ),
                    catchError(error =>
                        of(getSiteAlertsFailureAction({ error })),
                    ),
                );
            }),
        ),
    );

    getCurrentSiteAlerts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getCurrentSiteAlertsRequestAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
            ),
            switchMap(([, siteId]) =>
                this.http
                    .get<
                        AlertInterval[]
                    >(`${SITES_API_URL}/${siteId}/${DEVICE_CARE_ENDPOINT}/${ALERTS_ENDPOINT}/${CURRENT_ALERTS_ENDPOINT}`)
                    .pipe(
                        map(alerts =>
                            getCurrentSiteAlertsSuccessAction({ alerts }),
                        ),
                        catchError(error =>
                            of(getCurrentSiteAlertsFailureAction({ error })),
                        ),
                    ),
            ),
        ),
    );

    getSiteReports$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSiteReportsRequestAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
            ),
            switchMap(([, siteId]) =>
                this.http
                    .get<
                        Report[]
                    >(`${SITES_API_URL}/${siteId}/${DEVICE_CARE_ENDPOINT}/${REPORTS_ENDPOINT}`)
                    .pipe(
                        map(reports =>
                            getSiteReportsSuccessAction({
                                reports: reports.reverse(),
                            }),
                        ),
                        catchError(error =>
                            of(getSiteReportsFailureAction({ error })),
                        ),
                    ),
            ),
        ),
    );

    downloadReport$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(getSiteReportRequestAction),
                withLatestFrom(
                    this.store.pipe(
                        select($selectedSiteId),
                        filter((id): id is string => id != null),
                    ),
                ),
                switchMap(([{ reportId, reportName }, siteId]) =>
                    this.http
                        .get(
                            `${SITES_API_URL}/${siteId}/${DEVICE_CARE_ENDPOINT}/${REPORTS_ENDPOINT}/${reportId}`,
                            { responseType: 'blob' },
                        )
                        .pipe(
                            tap(response => {
                                const a = document.createElement('a');
                                a.href = URL.createObjectURL(response);
                                a.download = reportName;
                                a.click();
                            }),
                            catchError(error =>
                                of(getSiteReportsFailureAction({ error })),
                            ),
                        ),
                ),
            ),
        { dispatch: false },
    );

    createDeploymentBundle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createSiteDeploymentBundleAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
            ),
            switchMap(([{ bundleType, version }, siteId]) =>
                this.http
                    .post<Bundle>(
                        `${SITES_API_URL}/${siteId}/${DEPLOYMENTS_ENDPOINT}/${BUNDLES_ENDPOINT}/${bundleType}`,
                        {
                            version,
                        },
                    )
                    .pipe(
                        switchMap(response => [
                            createSiteDeploymentBundleSuccessAction({
                                bundle: response,
                            }),
                            showSnackBarAction({
                                config: {
                                    type: SnackBarType.SUCCESSFUL,
                                    description:
                                        'Bundle generation has been started. It may take a few minutes. You will be notified by email once the bundle is generated.',
                                    actionLabel: 'Dismiss',
                                },
                            }),
                        ]),
                        catchError(error =>
                            of(
                                getSiteDeploymentBundlesFailureAction({
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    downloadDeployment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(downloadSiteDeploymentAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSiteId),
                    filter((id): id is string => id != null),
                ),
            ),
            switchMap(([{ uuid, bundleType }, siteId]) =>
                this.http.get<UserToken>(`${PERMISSIONS_API_URL}/token`).pipe(
                    map(response => {
                        const url = this.router.serializeUrl(
                            this.router.createUrlTree([
                                `${SITES_API_URL}/${siteId}/${DEPLOYMENTS_ENDPOINT}/${BUNDLES_ENDPOINT}/${bundleType}/${uuid}`,
                            ]),
                        );

                        window.open(
                            url +
                                '?token=' +
                                encodeURIComponent(response.token),
                            '_blank',
                        );

                        return downloadSiteDeploymentSuccessAction();
                    }),
                    catchError(error =>
                        of(downloadSiteDeploymentFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );

    getVersion$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getVersionAction),
            withLatestFrom(
                this.router.events.pipe(
                    filter(
                        (event): event is ActivationStart =>
                            event instanceof ActivationStart,
                    ),
                ),
            ),
            switchMap(([, event]) => {
                const params = event.snapshot.queryParams;
                let httpVersion;

                if (params.serverToken) {
                    httpVersion = this.httpWithoutAuth.get<{ version: string }>(
                        VERSION_API_URL,
                        {
                            headers: {
                                Authorization: 'Bearer ' + params.serverToken,
                            },
                        },
                    );
                } else {
                    httpVersion = this.http.get<{ version: string }>(
                        VERSION_API_URL,
                    );
                }

                return httpVersion.pipe(
                    map(response => getVersionSuccessAction(response)),
                    catchError(error =>
                        of(
                            getVersionFailureAction({
                                error,
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    constructor(
        private readonly http: HttpClient,
        private readonly httpBackend: HttpBackend,
        private readonly actions$: Actions,
        private readonly store: Store<AppState>,
        private readonly influxDbService: InfluxdbService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly location: Location,
    ) {
        this.httpWithoutAuth = new HttpClient(this.httpBackend);
    }
}
