import {
    AlertInterval,
    Bundle,
    BundleType,
    DeviceCare,
    DeviceCareInstallation,
    Feature,
    MetricsStatus,
    PhoneCall,
    Release,
    Report,
    RtlsPlayerOptions,
    Site,
    SiteRtlsPlayer,
    SmtpStatus,
    SupportActivity,
    User,
} from '@myrtls/api-interfaces';
import { Action, createReducer, on } from '@ngrx/store';
import { MetricWithData } from '../../shared/models/metric-with-data.model';
import { DeviceCarePeriod } from '../../shared/models/device-care-period.model';

import {
    deleteUserSuccessAction,
    getSiteDeviceCareConfigSuccessAction,
    getSiteFailureAction,
    getSiteFeaturesSuccessAction,
    getSiteMetricDataFailureAction,
    getSiteMetricDataRequestAction,
    getSiteMetricDataSuccessAction,
    getSiteMetricsConfigSuccessAction,
    getSiteRequestAction,
    getSitesFailureAction,
    getSitesRequestAction,
    getSitesSuccessAction,
    getSiteSuccessAction,
    getSiteUsersFailureAction,
    getSiteUsersRequestAction,
    getSiteUsersSuccessAction,
    selectPeriodDateRangeAction,
    selectSiteAction,
    updateSiteMetricSettingsRequestAction,
    updateSiteMetricSettingsSuccessAction,
    updateSiteMetricSettingsFailureAction,
    updateSiteReportSettingsRequestAction,
    updateSiteReportSettingsSuccessAction,
    updateSiteReportSettingsFailureAction,
    getSiteAlertsRequestAction,
    getSiteAlertsSuccessAction,
    getSiteAlertsFailureAction,
    getSiteMetricDashboardDataSuccessAction,
    updateSiteReportMetricsAction,
    getSiteReportsRequestAction,
    getSiteReportsSuccessAction,
    getSiteReportsFailureAction,
    getSiteDeviceCareConfigRequestAction,
    getCurrentSiteAlertsRequestAction,
    getCurrentSiteAlertsSuccessAction,
    getCurrentSiteAlertsFailureAction,
    setAlertsOnly,
    getSiteMetricsStatusSuccessAction,
    activateDeviceCareSuccessAction,
    setIsReport,
    getSitePlayerSuccessAction,
    getPlayerOptionsSuccessAction,
    initializeSitePlayerSuccessAction,
    updateSitePlayerSuccessAction,
    getSiteActivitiesSuccessAction,
    getPhoneCallSuccessAction,
    getSiteDeviceCareInstallationSuccessAction,
    getSiteLicenseRawSuccessAction,
    getSiteSmtpStatusSuccessAction,
    downloadSiteDeploymentAction,
    downloadSiteDeploymentSuccessAction,
    downloadSiteDeploymentFailureAction,
    createSiteTemplatesFailureAction,
    createSiteTemplatesRequestAction,
    createSiteTemplatesSuccessAction,
    getSiteDeploymentBundlesSuccessAction,
    createSiteDeploymentBundleSuccessAction,
    getVersionSuccessAction,
    getSiteReleasesSuccessAction,
    getSiteReleasesAction,
    getSiteReleasesFailureAction,
    getSiteMetricFilteringDataSuccessAction,
} from './sites.actions';

export interface DeviceCareInfo extends DeviceCare {
    selectedPeriod: DeviceCarePeriod | null;
}

export interface SiteDetail extends Site {
    users?: User[];
    features?: Feature[];
    metrics?: MetricWithData[];
    reportMetrics?: string[];
    deviceCare: DeviceCareInfo;
    licenseRaw?: string;
}

export interface SitesState {
    sites: {
        [siteId: string]: SiteDetail;
    };
    selectedSiteId: string | null;
    currentAlerts: AlertInterval[];
    alerts: AlertInterval[] | null;
    player: SiteRtlsPlayer | null;
    deploymentBundles: { [key in BundleType]?: Bundle[] };
    releases: Release[];
    releasesLoading: boolean;
    installationParameters: DeviceCareInstallation | null;
    playerOptions: RtlsPlayerOptions | null;
    alertsOnly: boolean;
    phoneCall: PhoneCall | null;
    reports: Report[];
    activities: SupportActivity[];
    loading: boolean;
    loaded: boolean;
    focus: string | null;
    smtpStatus: SmtpStatus | null;
    metricsStatus: MetricsStatus;
    metricsUpdateLoading: boolean;
    reportUpdateLoading: boolean;
    alertsLoading: boolean;
    currentAlertsLoading: boolean;
    reportsLoading: boolean;
    isReport: boolean;
    downloadingDeployment: boolean;
    creatingTemplates: boolean;
    version: string | null;
}

const initialState: SitesState = {
    selectedSiteId: null,
    loading: false,
    loaded: false,
    focus: null,
    sites: {},
    alerts: null,
    phoneCall: null,
    installationParameters: null,
    alertsOnly: false,
    player: null,
    deploymentBundles: {},
    releases: [],
    releasesLoading: false,
    playerOptions: null,
    currentAlerts: [],
    reports: [],
    activities: [],
    metricsStatus: {
        system: false,
        studio: false,
    },
    smtpStatus: null,
    metricsUpdateLoading: false,
    reportUpdateLoading: false,
    alertsLoading: false,
    currentAlertsLoading: false,
    reportsLoading: false,
    isReport: false,
    downloadingDeployment: false,
    creatingTemplates: false,
    version: null,
};

const reducer = createReducer(
    initialState,
    on(getSitesRequestAction, state => ({
        ...state,
        loading: true,
    })),
    on(getVersionSuccessAction, (state, { version }) => ({
        ...state,
        version,
    })),
    on(getSitesSuccessAction, (state, { sites }) => ({
        ...state,
        loading: false,
        loaded: true,
        sites: {
            ...state.sites,
            ...(sites || []).reduce(
                (acc: { [id: string]: SiteDetail }, site) => ({
                    ...acc,
                    [site.id]: {
                        ...state.sites[site.id],
                        ...site,
                    },
                }),
                {},
            ),
        },
    })),
    on(setAlertsOnly, (state, { alertsOnly }) => ({
        ...state,
        alertsOnly,
    })),
    on(setIsReport, (state, { isReport }) => ({
        ...state,
        isReport,
    })),
    on(getSitesFailureAction, state => ({
        ...state,
        loading: false,
        loaded: false,
    })),
    on(getSiteFailureAction, state => ({
        ...state,
        loading: false,
    })),
    on(getSiteRequestAction, state => ({
        ...state,
        loading: true,
    })),
    on(getSiteSuccessAction, (state, { site }) => ({
        ...state,
        loading: false,
        sites: {
            ...state.sites,
            [site.id]: {
                ...state.sites[site.id],
                ...site,
            },
        },
    })),
    on(getSiteActivitiesSuccessAction, (state, { activities }) => ({
        ...state,
        activities,
    })),
    on(getPhoneCallSuccessAction, (state, { phoneCall }) => ({
        ...state,
        phoneCall,
    })),
    on(getSitePlayerSuccessAction, (state, { player }) => ({
        ...state,
        player,
    })),
    on(initializeSitePlayerSuccessAction, (state, { player }) => ({
        ...state,
        player,
    })),
    on(updateSitePlayerSuccessAction, (state, { player }) => ({
        ...state,
        player,
    })),
    on(getPlayerOptionsSuccessAction, (state, { options }) => ({
        ...state,
        playerOptions: options,
    })),
    on(getSiteUsersRequestAction, state => ({
        ...state,
        loading: true,
    })),
    on(getSiteUsersSuccessAction, (state, { siteId, users }) => ({
        ...state,
        sites: {
            ...state.sites,
            [siteId]: {
                ...state.sites[siteId],
                users,
            },
        },
        loading: false,
    })),
    on(getSiteUsersFailureAction, state => ({
        ...state,
        loading: false,
    })),
    on(selectSiteAction, (state, { siteId }) => ({
        ...state,
        selectedSiteId: siteId,
    })),
    on(
        getSiteDeviceCareInstallationSuccessAction,
        (state, { installation }) => ({
            ...state,
            installationParameters: installation,
        }),
    ),
    on(deleteUserSuccessAction, (state, { siteId, userId }) => ({
        ...state,
        sites: {
            ...state.sites,
            [siteId]: {
                ...state.sites[siteId],
                users: (state.sites[siteId].users || []).filter(
                    u => u.id !== userId,
                ),
            },
        },
    })),
    on(getSiteReleasesAction, state => ({
        ...state,
        releases: [],
        releasesLoading: true,
    })),
    on(getSiteReleasesSuccessAction, (state, { releases }) => ({
        ...state,
        releases,
        releasesLoading: false,
    })),
    on(getSiteReleasesFailureAction, state => ({
        ...state,
        releasesLoading: false,
    })),
    on(
        getSiteDeploymentBundlesSuccessAction,
        (state, { bundles, bundleType }) => {
            return {
                ...state,
                deploymentBundles: {
                    ...state.deploymentBundles,
                    [bundleType]: bundles,
                },
            };
        },
    ),
    on(createSiteDeploymentBundleSuccessAction, (state, { bundle }) => ({
        ...state,
        deploymentBundles: {
            ...state.deploymentBundles,
            [bundle.type]: [bundle],
        },
    })),
    on(getSiteFeaturesSuccessAction, (state, { siteId, features }) => ({
        ...state,
        sites: {
            ...state.sites,
            [siteId]: {
                ...state.sites[siteId],
                features,
            },
        },
        loading: false,
    })),
    on(getSiteLicenseRawSuccessAction, (state, { siteId, licenseRaw }) => ({
        ...state,
        sites: {
            ...state.sites,
            [siteId]: {
                ...state.sites[siteId],
                licenseRaw,
            },
        },
        loading: false,
    })),
    on(getSiteMetricDataRequestAction, state => ({
        ...state,
        loading: true,
    })),
    on(activateDeviceCareSuccessAction, (state, { date, site }) => ({
        ...state,
        sites: {
            ...state.sites,
            [site]: {
                ...state.sites[site],
                dcActivation: date,
            },
        },
    })),
    on(
        getSiteMetricDataSuccessAction,
        (state, { metricId, siteId, data, range, from, to }) => ({
            ...state,
            sites: {
                ...state.sites,
                [siteId]: {
                    ...state.sites[siteId],
                    metrics: state.sites[siteId].metrics?.map(metric =>
                        metric.id === metricId
                            ? {
                                  ...metric,
                                  data,
                                  range,
                                  from,
                                  to,
                              }
                            : metric,
                    ),
                },
            },
            loading: false,
        }),
    ),
    on(getSiteMetricDataFailureAction, state => ({
        ...state,
        loading: false,
    })),
    on(getSiteMetricsConfigSuccessAction, (state, { siteId, metrics }) => ({
        ...state,
        sites: {
            ...state.sites,
            [siteId]: {
                ...state.sites[siteId],
                metrics: metrics.map(metric => ({ ...metric, loading: true })),
            },
        },
    })),
    on(
        getSiteDeviceCareConfigSuccessAction,
        (state, { siteId, config, period }) => ({
            ...state,
            sites: {
                ...state.sites,
                [siteId]: {
                    ...state.sites[siteId],
                    deviceCare: {
                        ...state.sites[siteId]?.deviceCare,
                        selectedPeriod: period || null,
                        ...config,
                    },
                },
            },
        }),
    ),
    on(updateSiteMetricSettingsRequestAction, state => ({
        ...state,
        metricsUpdateLoading: true,
    })),
    on(updateSiteReportMetricsAction, (state, { siteId, metrics }) => ({
        ...state,
        sites: {
            ...state.sites,
            [siteId]: {
                ...state.sites[siteId],
                reportMetrics: metrics,
            },
        },
    })),
    on(updateSiteMetricSettingsSuccessAction, (state, { siteId, metrics }) => ({
        ...state,
        metricsUpdateLoading: false,
        sites: {
            ...state.sites,
            [siteId]: {
                ...state.sites[siteId],
                metrics,
            },
        },
    })),
    on(updateSiteMetricSettingsFailureAction, state => ({
        ...state,
        metricsUpdateLoading: false,
    })),
    on(updateSiteReportSettingsRequestAction, state => ({
        ...state,
        reportUpdateLoading: true,
    })),
    on(updateSiteReportSettingsSuccessAction, (state, { siteId, config }) => ({
        ...state,
        sites: {
            ...state.sites,
            [siteId]: {
                ...state.sites[siteId],
                deviceCare: {
                    ...state.sites[siteId]?.deviceCare,
                    ...config,
                },
            },
        },
        reportUpdateLoading: false,
    })),
    on(updateSiteReportSettingsFailureAction, state => ({
        ...state,
        reportUpdateLoading: false,
    })),
    on(
        selectPeriodDateRangeAction,
        (state, { siteId, dateRange, from, to }) => ({
            ...state,
            sites: {
                ...state.sites,
                [siteId]: {
                    ...state.sites[siteId],
                    deviceCare: {
                        ...state.sites[siteId]?.deviceCare,
                        selectedPeriod: {
                            dateRange,
                            from,
                            to,
                        },
                    },
                    metrics: (state.sites[siteId].metrics || []).map(
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        ({ data, ...metaData }) => {
                            return metaData;
                        },
                    ),
                },
            },
        }),
    ),
    on(getCurrentSiteAlertsRequestAction, state => ({
        ...state,
        currentAlertsLoading: true,
    })),
    on(getSiteMetricsStatusSuccessAction, (state, { status }) => ({
        ...state,
        metricsStatus: status,
    })),
    on(getSiteAlertsRequestAction, state => ({
        ...state,
        alertsLoading: true,
    })),
    on(getSiteDeviceCareConfigRequestAction, (state, { focus }) => ({
        ...state,
        focus: focus || null,
    })),
    on(getSiteSmtpStatusSuccessAction, (state, { smtpStatus }) => ({
        ...state,
        smtpStatus,
    })),
    on(getCurrentSiteAlertsSuccessAction, (state, { alerts }) => ({
        ...state,
        currentAlertsLoading: false,
        currentAlerts: alerts,
    })),
    on(getCurrentSiteAlertsFailureAction, state => ({
        ...state,
        currentAlertsLoading: false,
    })),
    on(getSiteAlertsSuccessAction, (state, { alerts }) => ({
        ...state,
        alertsLoading: false,
        alerts,
    })),
    on(getSiteAlertsFailureAction, state => ({
        ...state,
        alertsLoading: false,
    })),
    on(getSiteReportsRequestAction, state => ({
        ...state,
        reportsLoading: true,
    })),
    on(getSiteReportsSuccessAction, (state, { reports }) => ({
        ...state,
        reportsLoading: false,
        reports,
    })),
    on(createSiteTemplatesRequestAction, state => ({
        ...state,
        creatingTemplates: true,
    })),
    on(createSiteTemplatesSuccessAction, state => ({
        ...state,
        creatingTemplates: false,
    })),
    on(createSiteTemplatesFailureAction, state => ({
        ...state,
        creatingTemplates: false,
    })),
    on(downloadSiteDeploymentAction, state => ({
        ...state,
        downloadingDeployment: true,
    })),
    on(downloadSiteDeploymentSuccessAction, state => ({
        ...state,
        downloadingDeployment: false,
    })),
    on(downloadSiteDeploymentFailureAction, state => ({
        ...state,
        downloadingDeployment: false,
    })),
    on(getSiteReportsFailureAction, state => ({
        ...state,
        reportsLoading: false,
    })),
    on(
        getSiteMetricDashboardDataSuccessAction,
        (state, { siteId, data, metricId }) => ({
            ...state,
            sites: {
                ...state.sites,
                [siteId]: {
                    ...state.sites[siteId],
                    metrics: state.sites[siteId].metrics?.map(metric =>
                        metric.id === metricId
                            ? {
                                  ...metric,
                                  dashboardData: data,
                              }
                            : metric,
                    ),
                },
            },
        }),
    ),
    on(
        getSiteMetricFilteringDataSuccessAction,
        (state, { siteId, data, metricId }) => ({
            ...state,
            sites: {
                ...state.sites,
                [siteId]: {
                    ...state.sites[siteId],
                    metrics: state.sites[siteId].metrics?.map(metric =>
                        metric.id === metricId
                            ? {
                                  ...metric,
                                  filteringData: data,
                              }
                            : metric,
                    ),
                },
            },
        }),
    ),
);

export function sitesReducer(
    state: SitesState | undefined,
    action: Action,
): SitesState {
    return reducer(state, action);
}
