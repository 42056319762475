// eslint-disable-next-line @typescript-eslint/ban-types
export type Nullable<T> = T extends Function
    ? T
    : T extends Array<infer U>
      ? _NullableArray<U>
      : T extends object
        ? _NullableObject<T>
        : T | null;

// tslint:disable-next-line:class-name
export interface _NullableArray<T> extends Array<Nullable<T>> {}
export type _NullableObject<T> = { [P in keyof T]?: Nullable<T[P]> };

export interface Data {
    id: string;
}

export interface Company extends Data {
    name: string;
    email: string;
}

export enum SiteState {
    NEW = 'new',
    ACTIVE = 'active',
    EXPIRED = 'expired',
}

export enum SiteDeploymentType {
    CLOUD = 'cloud',
    ON_PREM = 'onprem',
}

export interface SiteEditables {
    playerMinutesRemaining: number | null;
    deploymentType: SiteDeploymentType | null;
}

export interface Site extends Data, SiteEditables {
    companyId: string;
    name: string;
    state: SiteState;
    license: {
        id: number;
        type: string;
        expiration: number;
    };
    nextReviewDate: number | null;
    opportunities: number[];
}

export type BundleType =
    | 'onprem'
    | 'studio-install'
    | 'studio-update'
    | 'studio-vbox'
    | 'studio-docker';

export type BundleState = 'requested' | 'creating' | 'created';

export interface Bundle {
    uuid: string;
    until?: string;
    version: string;
    userEmail: string;
    size?: string;
    state: BundleState;
    type: BundleType;
    [key: string]: string | undefined;
}

export interface Release {
    name: string;
    version: string;
    release: string;
    type: BundleType;
}

export interface User extends Data {
    name: string;
    email: string;
    permissions: {
        id: string;
        name?: string;
    }[];
    site: {
        id: string;
    };
    isAdmin?: boolean;
}

export interface UserPostRequest {
    email: string;
    name: string;
    permissions: string[];
}

export interface UserPutRequest {
    permissions: string[];
    name: string;
}

export interface License extends Data {
    type: string;
    expiration: number;
}

export enum Role {
    ADMIN = 'admin',
    EDITOR = 'editor',
    VIEWER = 'viewer',
    NONE = 'none',
}

export interface PermissionGroup {
    group: string;
    permissions: Permission[];
}

export interface Permission extends Data {
    name: string;
    category: string;
    roles: Role[];
    group: string;
}

export enum FeatureState {
    ENABLED = 'enabled',
    LOCKED = 'locked',
    OTHER_DEPLOYMENT = 'other_deployment',
    EXPIRED = 'expired',
    BLOCKED = 'blocked',
    NOT_PERMITTED = 'not_permitted',
}

export interface Feature extends Data {
    name: string;
    state: FeatureState;
    activationDate: string | null;
    expirationDate: string | null;
    configuration: null | { [key: string]: number };
    permission: {
        id: number;
        name: string;
    };
    deployment: {
        onprem: boolean;
        cloud: boolean;
        siteType: boolean;
    };
    fallback?: SiteFeature;
    hidden: boolean;
}

export interface Plan extends Data {
    name: string;
    featureIds: string[];
    playerHoursPerMonth: number;
    deviceCareHistoryDays: number;
}

export enum ChartType {
    LINE = 'line',
    BAR = 'bar',
    SINGLE_STAT = 'single_stat',
    TIMELINE = 'timeline',
    STACKED_BAR = 'stacked_bar',
    TABLE = 'table',
}

export enum ThresholdType {
    ABOVE = 'above',
    BELOW = 'below',
    EQUAL_OR_ABOVE = 'equal_or_above',
}

export enum FormatType {
    DURATION = 'duration',
    DECIMAL = 'decimals',
}

export interface MetricThresholdProperties {
    type: ThresholdType;
    state?: boolean;
    unit?: string;
    editable: boolean;
    tooltip?: string;
}

export interface MetricMapping {
    from: number;
    to: string;
    color?: string;
    alerting?: boolean; // if set to true the value is taken as alerting
    state?: 'ok' | 'warning' | 'error';
}

export interface MetricSorting {
    field: string;
    direction: 'asc' | 'desc';
}

export interface MetricParameters {
    min?: number; // if set the lowest visible value should be this, if not select it based on lowest value
    max?: number; // if set the highest visible value should be this, if not select it based on highest value
    interval?: number; // if set the labels on the y axis should be visible only in this interval
    abbreviation?: { value: number; prefix: string }; // if set divide the values by the value and add prefix to the unit
    unit?: string; // if set add this as suffix to the value (e.g. "%")
    decimals?: number; // if set round the value to this number of decimals
    mappings: MetricMapping[]; // maps values to string (e.g. 0 => "BAD", 1 => "GOOD")
    format?: FormatType; // formats the result
    sorting?: MetricSorting[]; // sorts the results by this field
    multiline?: boolean; // if set to true the values have history
}

export interface MetricField {
    name: string; // name of the field with data
    alias: string; // name template for each chart line, can use tags ($tag_XXX)
    tags: string[]; // grouped by these tags (also part of the query)
}

export enum MappingFunction {
    TIME = 'time',
    THRESHOLD = 'threshold',
    DURATION_THRESHOLD = 'duration_threshold',
}

export interface MetricTableField {
    name: string;
    alias: string;
    unit?: string;
    mappings: MetricMapping[] | MappingFunction;
}

export interface MetricTableHistory {
    query: string;
    type: ChartType.TABLE;
    fields: MetricTableField[];
    valueField: string;
    parameters: MetricParameters;
    info?: string;
    noDataText?: string;
}

export interface MetricDefaultHistory {
    query: string;
    type: Exclude<ChartType, ChartType.TABLE>;
    parameters: MetricParameters;
    info?: string;
    fields: MetricField[];
    showLegend?: boolean;
}

export type MetricHistory = MetricTableHistory | MetricDefaultHistory;

export interface MetricDashboard {
    query: string;
    type: ChartType;
    parameters: MetricParameters;
    fields: MetricField[];
    info?: string;
    full?: boolean; // ensures the width of the widget is 100%
    showThreshold?: boolean; // in the single stat chart show the threshold value
}

export interface GrafanaAlertTemplate {
    noDataState: string;
    execErrState: string;
    for: string;
    condition: string;
    folderUID: string;
    orgID: number;
    ruleGroup: string;
    title: string;
    data: any[];
    isPaused: boolean;
    annotations?: { [key: string]: string };
}

export interface MetricOverridables {
    alert: boolean;
    report: boolean;
    dashboard: boolean;
    history: boolean;
    thresholdValue: number;
}

export interface MetricOverride extends Nullable<MetricOverridables> {
    tag: string;
    value: string;
}

export interface Metric extends Data, MetricOverridables {
    name: string;
    nameSuffix?: string;
    group: string;
    version: string;
    historyTemplate?: MetricHistory;
    dashboardTemplate?: MetricDashboard;
    panelId?: number;
    thresholdProperties?: MetricThresholdProperties;
    alertTemplate?: {
        alertText: string | ((ids: string[]) => string);
        resolveText: string | ((ids: string[]) => string);
        type: 'error' | 'warning';
        action?: string;
        link?: string;
    };
    noDataTemplate?: string;
    state?: string;
    labelGroups?: string[];
    overrides?: MetricOverride[];
    overridable?: boolean;
    groupLabel?: string;
    labelsParsing?: (labels: { [key: string]: string }) => string | null;
    getGrafanaAlert?: (
        threshold: number,
        folderUID: string,
        dataSourceUID: string,
        orgID: number,
        isPaused: boolean,
        overrides: string[],
        annotations?: { [key: string]: string },
    ) => GrafanaAlertTemplate;
}

export interface MetricVersions {
    id: string;
    versions: Metric[];
}

export enum State {
    OK = 'ok',
    WARNING = 'warning',
    ERROR = 'error',
    UNDEFINED = 'undefined',
}

export type DeploySitePostRequest = {
    deploymentType: SiteDeploymentType | null;
};
export type DeviceCarePutRequest = Partial<DeviceCareEditables>;

export interface DeviceCareEditables {
    reportFrequency: ReportFrequency;
    alertRecipients: string[];
    reportRecipients: string[];
    grafana?: {
        id: string;
    };
    dcActivation: number | null;
}

export interface DeviceCare extends Data, DeviceCareEditables {
    site: {
        id: string;
    };
    influx: {
        readToken: string;
        writeToken?: string;
        host: string;
        organization: string;
        bucket: string;
    };
    qos: { text: string; state: State; lastIncident?: number };
    grafanaUrl: string;
    version: string | null;
}

export enum ReportFrequency {
    WEEK = 'week',
    MONTH = 'month',
}

export interface MetricPutRequest {
    id: string;
    alert: boolean;
    report: boolean;
    dashboard: boolean;
    history: boolean;
    thresholdValue?: number;
}

export interface MetricOverridesPutRequest {
    overrides: string[];
}

export interface AlertInterval {
    metric: string;
    metadata: string;
    from: Date;
    to: null | Date;
}

export interface Alerts {
    [key: string]: {
        partial: boolean;
        state: State;
        alerts: AlertInterval[];
    };
}

export interface Report extends Data {
    date: string;
    size: string;
}

export interface ReportLink extends Data {
    url: string;
}

export type List<T> = Partial<T>[];

export interface MetricsStatus {
    system: boolean;
    studio: boolean;
}

export interface DeviceCareConfiguration {
    siteId: number;
    sitePassword: string;
    databaseUrl: string;
    databaseOrganization: string;
}

export interface DeviceCareInstallation {
    native: string;
    docker: string;
    writeToken: string;
    bucket: string;
}

export enum RtlsPlayerStates {
    DELETED = 'deleted',
    RUNNING = 'running',
    STOPPED = 'stopped',
    INSTALLING = 'installing',
    STOPPING = 'stopping',
    DELETING = 'deleting',
    STARTING = 'starting',
    START_REQUEST = 'start_request',
    STOP_REQUEST = 'stop_request',
    INSTALL_REQUEST = 'install_request',
    DELETE_RUNNING_REQUEST = 'delete_running_request',
    DELETE_STOPPED_REQUEST = 'delete_stopped_request',
}

export interface SiteRtlsPlayer {
    machine: {
        id: string;
        link: string;
        user: string;
        password: string;
        state: RtlsPlayerStates;
        version: string;
        endTime: number;
        owner: string;
        remainingTime: number;
    } | null;
    remainingTime: number;
}

export interface RtlsPlayerOptions {
    versions: string[];
}

export interface PhoneCall {
    number: string;
    active: boolean;
    businessHours: {
        [key: number]: { start: [number, number]; end: [number, number] };
    };
}

export interface LicenseRaw {
    licenseRaw: string;
}

export interface InitializeRtlsPlayerParameters {
    version: string;
    time: number;
}

export interface UpdateRtlsPlayerParameters {
    time?: number;
    action: RtlsPlayerStates;
}

export interface ClearDeviceCareParameters {
    alerts: boolean;
    database: boolean;
}

export enum SupportActivityType {
    SYSTEM_REVIEW = 'system_review',
    PLANNING = 'planning',
    OTHER = 'other',
    UPDATE = 'update',
}

export interface SupportActivity {
    createdAt: number;
    author: string;
    type: SupportActivityType;
    link?: { url: string; name: string };
    content?: string;
}

export enum SiteFeature {
    DEVICE_CARE_DASHBOARD = 'device-care-dashboard',
    DEVICE_CARE_HISTORY = 'device-care-history',
    DEVICE_CARE_SETTINGS = 'device-care-settings',
    DEVICE_CARE_GRAFANA = 'device-care-grafana',
    DEVICE_CARE_INSTALLATION = 'device-care-installation',
    DEVICE_CARE_REPORT = 'device-care-report',
    SITE_USERS = 'site-users',
    RTLS_PLAYER = 'rtls-player',
    RTLS_PLAYER_EDIT = 'rtls-player-edit',
    LICENSE = 'license',
    SYSTEM_REVIEW = 'system-review',
    REPORT_TICKET = 'report-ticket',
    PHONE_CALL = 'phone-call',
    SITE_DEPLOYMENT = 'site-deployment',
    DEPLOYMENT_ONPREM = 'deployment-onprem',
    DEPLOYMENT_CLOUD = 'deployment-cloud',
    DEPLOYMENT_STUDIO = 'deployment-studio',
    SITE_OVERVIEW = 'site-overview',
    SITE_USERS_EDIT = 'site-users-edit',
    SITE_EDIT = 'site-edit',
}

export enum SitePermission {
    SITE_READ = 'site_read',
    SITE_EDIT = 'site_edit',
    SITE_USERS_READ = 'site_users_read',
    SITE_USERS_EDIT = 'site_users_edit',
    DC_READ = 'dc_read',
    DC_EDIT = 'dc_edit',
    PLAYERS_READ = 'players_read',
    PLAYERS_EDIT = 'players_edit',
    SUPPORT_READ = 'support_read',
    LICENSE_READ = 'license_read',
}

export enum CompanyPermission {
    SUPPORT_EDIT = 'support_edit',
    COMPANY_READ = 'company_read',
    TRAININGS_READ = 'trainings_read',
    USERS_READ = 'users_read',
    USERS_EDIT = 'users_edit',
    PROJECTS_READ = 'projects_read',
    PROJECTS_EDIT = 'projects_edit',
    INVOICES_READ = 'invoices_read',
    PLANS_READ = 'plans_read',
    PLANS_EDIT = 'plans_edit',
}

export type PermissionType = SitePermission | CompanyPermission;

export interface UserPermissionsRequest {
    sites_permissions: {
        [key in SitePermission]?: number[];
    };
    companies_permissions: {
        [key in PermissionType]?: number[];
    };
    sewio_permissions: PermissionType[];
}

export interface UserPermissions {
    sewio_users: {
        [email: string]: string[];
    };
    site_users: {
        [email: string]: {
            [permission: string]: number[];
        };
    };
}

export interface DeviceCareGrafanaToken {
    token: string;
}

export interface UserToken {
    token: string;
}

export interface SmtpStatus {
    enabled: boolean;
}
