<div mat-dialog-content class="h-full flex flex-col gap-4">
    <div class="flex flex-row justify-between items-start">
        <h2 mat-dialog-title>Offline Anchors</h2>
        <mat-form-field *ngIf="!loading && dataSource.data.length !== 0">
            <mat-label>Filter data</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Search..." #input />
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
    </div>
    <ng-container *ngIf="!loading; else loadingTemplate">
        <myrtls-info-card *ngIf="dataSource.data.length === 0" [type]="'success'">All anchors are online. No action is required.</myrtls-info-card>
        <div class="table-container flex-grow overflow-auto">
            <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort (matSortChange)="sortData($event)" *ngIf="dataSource.data.length !== 0">
                <ng-container matColumnDef="alias" sticky>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="whitespace-nowrap">Alias</th>
                    <td mat-cell *matCellDef="let element" class="whitespace-nowrap">{{ element.alias }}</td>
                </ng-container>

                <ng-container matColumnDef="ip">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>IP</th>
                    <td mat-cell *matCellDef="let element">{{ element.ip }}</td>
                </ng-container>

                <ng-container matColumnDef="mac">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>MAC</th>
                    <td mat-cell *matCellDef="let element">{{ element.mac }}</td>
                </ng-container>

                <ng-container matColumnDef="master">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Master</th>
                    <td mat-cell *matCellDef="let element">{{ element.master }}</td>
                </ng-container>

                <ng-container matColumnDef="offline since">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="whitespace-nowrap">Offline Since</th>
                    <td mat-cell *matCellDef="let element" class="whitespace-nowrap">{{ element.offlineSince }}</td>
                </ng-container>

                <ng-container matColumnDef="status" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="px-3 py-1 bg-error rounded-md text-white text-sm">{{ element.status | uppercase }}</span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columns" class="font-semibold"></tr>
                <tr mat-row *matRowDef="let element; columns: columns"></tr>
            </table>
        </div>
        <myrtls-info-card *ngIf="dataSource.data.length !== 0" [type]="'error'"> Please check if these anchors are powered on and reachable from the RTLS Studio server. </myrtls-info-card>
    </ng-container>
    <ng-template #loadingTemplate>
        <div class="w-full flex justify-center items-center">Loading...</div>
    </ng-template>
</div>
<div class="flex items-center justify-end" mat-dialog-actions>
    <button mat-button mat-dialog-close>CLOSE</button>
</div>
