import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'revertArray',
})
export class RevertArrayPipe implements PipeTransform {
    transform<T>(array: T[]): T[] {
        return [...array].reverse();
    }
}
