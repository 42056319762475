<div class="pt-8 px-4">
    <div class="m-auto prose p-8 bg-white shadow-md rounded-md" *ngIf="currentLicense$ | async as license">
        <p *ngIf="!isExpired(license.expiration)">
            Your site is guarded by <span class="text-success font-bold">{{ license.type | replaceString: versionRegexp : '' }}</span
            >. Your license is valid until <span class="font-bold" [ngClass]="{ 'text-success': !isExpiring(license.expiration), 'text-warning-600': isExpiring(license.expiration) }">{{ license.expiration | unixTimeToDate: 'YYYY/MM' }}</span
            >.
        </p>
        <p *ngIf="isExpiring(license.expiration) && !isExpired(license.expiration)">Once the license expires the reporting will stop and the site data will no longer be stored.</p>
        <p *ngIf="isExpired(license.expiration)">Your license is <span class="text-error font-bold">EXPIRED</span>. Reporting has been stopped and the data from your site are no longer stored.</p>
        <ul class="prose-sm">
            <li>For license details visit <a href="https://www.sewio.net/myrtls-care/" target="_blank" class="text-success">myRTLS section</a> on Sewio website.</li>
            <li>Valid prices can be found in the Price List on <a href="https://portal.sewio.net" target="_blank" class="text-success">Sewio Portal</a>.</li>
            <li>For upgrade / renewal, contact your sales contact or <a href="mailto:sales@sewio.net" class="text-success">sales&#64;sewio.net</a>.</li>
        </ul>
    </div>
</div>
