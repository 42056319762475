import { Component } from '@angular/core';
import { InfluxdbService } from '../../core/services/influxdb.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import {
    $deviceCareConfig,
    $metricWithData,
} from '../../store/sites/sites.selectors';
import { filter, withLatestFrom } from 'rxjs';
import * as moment from 'moment';

@Component({
    selector: 'myrtls-uptime-v1-modal',
    templateUrl: './uptime-v1.modal.html',
    styleUrls: [],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UptimeV1Modal {
    metric = 'uptime' as const;

    since = '';
    loading = true;

    private uptimeQuery = `from(bucket: "$BUCKET")
|> range(start: -2m)
|> filter(fn: (r) => r["_measurement"] == "system")
|> filter(fn: (r) => r["_field"] == "uptime")
|> last()`;

    constructor(
        private store: Store<AppState>,
        private influxdbService: InfluxdbService,
    ) {
        this.store
            .select($deviceCareConfig)
            .pipe(
                filter(config => !!config),
                withLatestFrom(this.store.select($metricWithData(this.metric))),
            )
            .subscribe(async ([config, metric]) => {
                if (!config || !metric) return;

                await this.influxdbService
                    .getDashboardChartData({
                        apiUrl: config.influx.host,
                        query: this.uptimeQuery,
                        org: config.influx.organization,
                        token: config.influx.readToken,
                        bucket: config.influx.bucket,
                        overrides:
                            metric.overrides?.map(override => override.value) ||
                            [],
                    })
                    .then(data => {
                        data.forEach(item => {
                            if (item._value) {
                                const now = moment();
                                const res = now.subtract(
                                    item._value,
                                    'seconds',
                                );
                                this.since = res.format('YYYY-MM-DD HH:mm:ss');
                                this.loading = false;
                            }
                        });
                    });
            });
    }
}
