import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { requireCheckboxesToBeCheckedValidator } from '../../shared/validators/require-checkboxes-to-be-chacked.validator';

@Component({
    selector: 'myrtls-clear-device-care-modal',
    templateUrl: './clear-device-care.modal.html',
    styleUrls: ['./clear-device-care.modal.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ClearDeviceCareModal {
    clearDeviceCareForm = new UntypedFormGroup(
        {
            alerts: new UntypedFormControl(false),
            database: new UntypedFormControl(false),
        },
        { validators: requireCheckboxesToBeCheckedValidator(1) },
    );

    constructor(public dialogRef: MatDialogRef<string>) {}

    clearData() {
        this.dialogRef.close({
            alerts: this.clearDeviceCareForm.get('alerts')?.value,
            database: this.clearDeviceCareForm.get('database')?.value,
        });
    }
}
