import { createSelector } from '@ngrx/store';
import { RoutePath } from '../../shared/models/route-path.enum';
import { AppState } from '../index';
import { RouteState } from './route.reducer';

const $routeState = (state: AppState): RouteState => state.route;

export const $page = createSelector($routeState, ({ page }): RoutePath => page);

export const $id = createSelector($routeState, ({ id }) => id);

export const $feature = createSelector($routeState, ({ feature }) => feature);

export const $hidePanels = createSelector(
    $routeState,
    ({ hidePanels }) => hidePanels,
);
