import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgxEchartsModule } from 'ngx-echarts';
import { CustomDatePickerModule } from '../custom-date-picker/custom-date-picker.module';
import { DeviceCareHistoryComponent } from './device-care-history.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { StateTimelineModule } from '../state-timeline/state-timeline.module';

@NgModule({
    declarations: [DeviceCareHistoryComponent],
    exports: [DeviceCareHistoryComponent],
    imports: [
        CommonModule,
        FormsModule,
        MatCardModule,
        MatButtonModule,
        MatExpansionModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatTableModule,
        MatSortModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatInputModule,
        NgxEchartsModule,
        MatSelectModule,
        CustomDatePickerModule,
        MatIconModule,
        StateTimelineModule,
    ],
    providers: [MatDatepickerModule],
})
export class DeviceCareHistoryModule {}
