import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Report, SiteFeature } from '@myrtls/api-interfaces';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import { getSiteReportRequestAction } from '../../store/sites/sites.actions';

@Component({
    selector: 'myrtls-dashboard-reports',
    templateUrl: './dashboard-reports.component.html',
    styleUrls: ['./dashboard-reports.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardReportsComponent {
    @Input() data: Report[] | null = [];

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly store: Store<AppState>,
    ) {}

    navigateToSettings(): void {
        this.router.navigate([`../${SiteFeature.DEVICE_CARE_SETTINGS}`], {
            relativeTo: this.route,
        });
    }

    downloadReport(id: string, name: string): void {
        this.store.dispatch(
            getSiteReportRequestAction({ reportId: id, reportName: name }),
        );
    }
}
