export enum SnackBarType {
    FAILED = 'failed',
    SUCCESSFUL = 'successful',
    WARNING = 'warning',
}

export interface SnackBarConfig {
    type: SnackBarType;
    description: string;
    actionLabel?: string;
    duration?: number;
}
