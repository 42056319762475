import { HttpClientModule } from '@angular/common/http';
import {
    ModuleWithProviders,
    NgModule,
    Optional,
    SkipSelf,
} from '@angular/core';
import { environment } from '../../environments/environment';

@NgModule({
    imports: [environment.auth.module, HttpClientModule],
    providers: [
        ...environment.auth.interceptors,
        ...environment.auth.providers,
    ],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() core: CoreModule) {
        if (core) {
            throw new Error('Core module cannot be imported multiple times!');
        }
    }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
        };
    }
}
