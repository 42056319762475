<div class="reset-container">
    <form class="card" *ngIf="token === undefined" [formGroup]="sendPasswordResetForm">
        <div class="logo-container mb-6">
            <img class="logo" src="assets/logos/sewio_full.png" />
        </div>
        <div class="welcome-container mb-4">Forgot Your Password?</div>
        <div class="message-container mb-4">Enter your email address and we will send you instructions to reset your password.</div>
        <mat-form-field class="mb-2" appearance="fill" [hideRequiredMarker]="true">
            <mat-label>Email address</mat-label>
            <input type="email" matInput name="reset-password-email" formControlName="email" />
            <mat-error *ngIf="sendPasswordResetForm.controls['email'].hasError('required'); else sendPasswordResetFormErrors2"> Not a valid email address. </mat-error>
            <ng-template #sendPasswordResetFormErrors2>
                <mat-error *ngIf="sendPasswordResetForm.controls['email'].hasError('email')"> Not a valid email address. </mat-error>
            </ng-template>
        </mat-form-field>
        <div class="forgot-container mb-4">
            <a href="" (click)="redirectToLogin($event)">Back to login</a>
        </div>
        <button mat-flat-button color="primary" [disabled]="!sendPasswordResetForm.valid" (click)="send()">Continue</button>
    </form>
    <form class="card" *ngIf="token !== undefined" [formGroup]="resetPasswordForm">
        <div class="logo-container mb-6">
            <img class="logo" src="assets/logos/sewio_full.png" />
        </div>
        <div class="welcome-container mb-4">Set New Password</div>
        <div class="message-container mb-4">Enter your new password.</div>
        <mat-form-field class="mb-2" appearance="fill" [hideRequiredMarker]="true">
            <mat-label>Password</mat-label>
            <input type="password" matInput name="new-password" formControlName="password" />
            <mat-error *ngIf="resetPasswordForm.controls['password'].hasError('required'); else resetPasswordFormErrors2"> Password is too short. (0 / 8)</mat-error>
            <ng-template #resetPasswordFormErrors2>
                <mat-error *ngIf="resetPasswordForm.controls['password'].hasError('minlength'); else resetPasswordFormErrors3">
                    Password is too short. ({{ resetPasswordForm.controls['password'].errors?.minlength.actualLength }} / {{ resetPasswordForm.controls['password'].errors?.minlength.requiredLength }})</mat-error
                >
                <ng-template #resetPasswordFormErrors3>
                    <mat-error *ngIf="resetPasswordForm.controls['password'].hasError('hasLowerCaseValidator'); else resetPasswordFormErrors4"> No lowercase character in password. </mat-error>
                    <ng-template #resetPasswordFormErrors4>
                        <mat-error *ngIf="resetPasswordForm.controls['password'].hasError('hasNumberValidator')"> No number in password. </mat-error>
                    </ng-template>
                </ng-template>
            </ng-template>
        </mat-form-field>
        <mat-form-field class="mb-2" appearance="fill" [hideRequiredMarker]="true">
            <mat-label>Confirm Password</mat-label>
            <input type="password" matInput name="new-password-confirm" formControlName="passwordConfirmation" />
            <mat-error *ngIf="resetPasswordForm.controls['passwordConfirmation'].hasError('passwordsMatchValidator')"> Passwords do not match. </mat-error>
        </mat-form-field>
        <div class="forgot-container mb-4">
            <a href="" (click)="redirectToLogin($event)">Back to login</a>
        </div>
        <button mat-flat-button color="primary" [disabled]="!resetPasswordForm.valid" (click)="reset()">Continue</button>
    </form>
</div>
