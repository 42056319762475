<div class="installation-page" *ngIf="site$ | async as site; else loading">
    <mat-tab-group class="stepper" [(selectedIndex)]="selectedTabIndex">
        <mat-tab label="myRTLS">
            <div *ngIf="selectedTabIndex === 0">
                <mat-vertical-stepper *ngIf="deploymentSelectionFormGroup; else loading" [linear]="false">
                    <mat-step *ngIf="site.deploymentType === null">
                        <form *ngIf="!deploymentSelectionLoading; else deploymentSelectionLoadingTemplate" [formGroup]="deploymentSelectionFormGroup">
                            <ng-template matStepLabel>Deployment Selection</ng-template>
                            <p>
                                Some myRTLS features require you to select what deployment would you like to run them in.
                                <br /><br />
                                <b>Cloud</b><br />
                                Cloud deployment is easier to setup and gives us visiblity over your project for a better and more proactive support, but requires a connection between your RTLS installation and our cloud.<br /><br />
                                <b>On-Premise (myRTLS Care+)</b><br />
                                On-Premise deployment is installed in your local network. Some features remains available only in cloud instance of myRTLS.
                                <br />
                            </p>
                            <br />
                            <div class="studio-type">
                                <label id="studio-installation-type">Select your deployment:</label>
                                <mat-radio-group class="studio-type-buttons" aria-labelledby="studio-installation-type" formControlName="deploymentType">
                                    <mat-radio-button *ngFor="let type of deploymentTypes" class="studio-type-button" [disabled]="(type.feature | async)?.state !== 'enabled'" [value]="type.value">{{ type.name }}</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="form-buttons">
                                <button mat-button color="primary" [disabled]="!deploymentSelectionFormGroup.valid" (click)="deploy()">Select</button>
                            </div>
                        </form>
                        <ng-template #deploymentSelectionLoadingTemplate>
                            <ng-template matStepLabel>Deployment Selection</ng-template>
                            <div class="w-full h-24 flex justify-center items-center">
                                <mat-progress-spinner [diameter]="30" [mode]="'indeterminate'"></mat-progress-spinner>
                            </div>
                        </ng-template>
                    </mat-step>
                    <mat-step *ngIf="site.deploymentType === SiteDeploymentType.CLOUD">
                        <ng-template matStepLabel>myRTLS Deployed</ng-template>
                        <p class="mb-2">Site is deployed in <b class="text-success">CLOUD</b> mode.</p>
                        <myrtls-info-card [type]="'info'"> Changing the deployment type can be done only by contacting our support team. </myrtls-info-card>
                        <div class="form-buttons">
                            <button mat-button color="primary" (click)="goToStudioDeployment()">Continue with RTLS Studio</button>
                        </div>
                    </mat-step>
                    <mat-step *ngIf="site.deploymentType === SiteDeploymentType.ON_PREM">
                        <ng-template matStepLabel>Download myRTLS On-Premise Bundle</ng-template>
                        <p class="mb-2">Site is deployed in <b class="text-success">ON-PREMISE</b> mode.</p>
                        <myrtls-info-card [type]="'info'"> Changing the deployment type can be done only by contacting our support team. </myrtls-info-card>
                        <br />
                        <p class="mb-2">Generate and download myRTLS installation bundle.</p>
                        <myrtls-info-card [type]="'warning'"> Your myRTLS license is included in the generated bundle. Do not share it with anyone. </myrtls-info-card>
                        <div class="mt-2"></div>
                        <div *ngIf="deployments['onprem'] && deployments['onprem'].length > 0">
                            <div class="bundle header">
                                <div class="font-bold">Version</div>
                                <div class="font-bold">User</div>
                                <div class="font-bold">Size</div>
                                <div class="font-bold"></div>
                            </div>
                            <div class="bundle" *ngFor="let deployment of deployments['onprem']">
                                <div>
                                    <span>{{ deployment.version }}</span>
                                </div>
                                <div>
                                    <span>{{ deployment.userEmail }}</span>
                                </div>
                                <div>
                                    <span>{{ deployment.size || '' }}</span>
                                </div>
                                <div>
                                    <button mat-button color="primary" [disabled]="deployment.state !== 'created' || (downloading | async)" (click)="download(deployment.uuid, 'onprem')">
                                        <mat-progress-spinner *ngIf="downloading | async" class="button-spinner" [diameter]="15" mode="indeterminate"></mat-progress-spinner> {{ deployment.state !== 'created' ? 'Generating' : 'Download' }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="mt-2"></div>
                        <div class="generate-layout">
                            <div *ngIf="version$ | async as version">
                                <button mat-stroked-button color="primary" class="multi-button-left" [disabled]="generateShouldBeDisabled('onprem')" (click)="generate(version, 'onprem')">Generate New Bundle</button
                                ><button mat-stroked-button color="primary" class="multi-button-right" [matMenuTriggerFor]="generateMore"><mat-icon>expand_more</mat-icon></button>
                                <mat-menu #generateMore="matMenu" xPosition="before">
                                    <button *ngIf="site.licenseRaw" mat-menu-item (click)="downloadLicense(site.id, site.licenseRaw)">
                                        <span>Download License File</span>
                                    </button>
                                </mat-menu>
                            </div>
                            <span *ngIf="version$ | async as version"> Latest Version: {{ version }} </span>
                        </div>
                        <div class="mt-2"></div>
                        <div class="form-buttons">
                            <button mat-button color="primary" matStepperNext>Next</button>
                        </div>
                    </mat-step>
                    <mat-step *ngIf="site.deploymentType === SiteDeploymentType.ON_PREM">
                        <ng-template matStepLabel>Deploy myRTLS On-Premise Bundle</ng-template>
                        <p class="mb-2">Upload downloaded bundle on a server visible to your RTLS Studio.</p>
                        <myrtls-info-card [type]="'warning'"> Using the same server for myRTLS and RTLS Studio is possible, but the performance may be reduced. </myrtls-info-card>
                        <p class="mt-2">Extract the package using the following commands from the directory with the uploaded package:</p>
                        <div class="terminal-line mt-2">
                            <div #t1 class="terminal">sudo mkdir -p /opt/sewio/myrtls</div>
                            <button mat-icon-button aria-label="Copy to Clipboard" [cdkCopyToClipboard]="t1.textContent || ''">
                                <mat-icon fontSet="material-icons-outlined" [matTooltip]="'Copy to Clipboard'">content_copy</mat-icon>
                            </button>
                        </div>
                        <div class="terminal-line mt-2">
                            <div #t2 class="terminal">sudo tar -xf myrtls-installer-{{ site.id }}.tar -C /opt/sewio/myrtls/ --overwrite</div>
                            <button mat-icon-button aria-label="Copy to Clipboard" [cdkCopyToClipboard]="t2.textContent || ''">
                                <mat-icon fontSet="material-icons-outlined" [matTooltip]="'Copy to Clipboard'">content_copy</mat-icon>
                            </button>
                        </div>
                        <p>Navigate to the directory, run the install command and follow the instructions of the installation script:</p>
                        <div class="terminal-line mt-2">
                            <div #t3 class="terminal">cd /opt/sewio/myrtls</div>
                            <button mat-icon-button aria-label="Copy to Clipboard" [cdkCopyToClipboard]="t3.textContent || ''">
                                <mat-icon fontSet="material-icons-outlined" [matTooltip]="'Copy to Clipboard'">content_copy</mat-icon>
                            </button>
                        </div>
                        <div class="terminal-line mt-2">
                            <div #t4 class="terminal">sudo bash ./install.sh</div>
                            <button mat-icon-button aria-label="Copy to Clipboard" [cdkCopyToClipboard]="t4.textContent || ''">
                                <mat-icon fontSet="material-icons-outlined" [matTooltip]="'Copy to Clipboard'">content_copy</mat-icon>
                            </button>
                        </div>
                        <p>If your license has been changed, download a license file from the previous step and move it to <span class="code">./licenses</span> folder:</p>
                        <div class="form-buttons">
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button color="primary" (click)="goToStudioDeployment()">Continue with RTLS Studio</button>
                        </div>
                    </mat-step>
                </mat-vertical-stepper>
            </div>
        </mat-tab>
        <mat-tab label="RTLS Studio">
            <div *ngIf="selectedTabIndex === 1">
                <mat-vertical-stepper *ngIf="deploymentSelectionFormGroup; else loading" [linear]="false">
                    <mat-step>
                        <ng-template matStepLabel>Download RTLS Studio Bundle</ng-template>
                        <p class="mb-2">Generate and download RTLS Studio bundle.</p>
                        <div class="flex flex-col gap-2">
                            <myrtls-info-card [type]="'warning'"> Your myRTLS license is included in the native and docker bundles.<br />Do not share it with anyone. </myrtls-info-card>
                            <myrtls-info-card [type]="'info'"> VirtualBox bundle comes without the license. It needs to be downloaded separately. </myrtls-info-card>
                        </div>
                        <div class="mt-2"></div>
                        <div>
                            <div class="bundle header">
                                <div class="font-bold studio-version-column">Version</div>
                                <div class="font-bold studio-user-column">User</div>
                                <div class="font-bold studio-size-column">Size</div>
                                <div class="font-bold studio-type-column">Type</div>
                                <div class="font-bold studio-actions-column"></div>
                            </div>
                            <div class="bundle none" *ngIf="!deployments['studio-vbox']?.length && !deployments['studio-update']?.length && !deployments['studio-install']?.length && !deployments['studio-docker']?.length">No generated bundles found.</div>
                            <div *ngFor="let type of studioTypes">
                                <div class="bundle" *ngFor="let deployment of deployments[type.value]">
                                    <div class="studio-version-column">
                                        <span>{{ deployment.version }}</span>
                                    </div>
                                    <div class="studio-user-column">
                                        <span>{{ deployment.userEmail }}</span>
                                    </div>
                                    <div class="studio-size-column">
                                        <span>{{ deployment.size || '' }}</span>
                                    </div>
                                    <div class="studio-type-column">
                                        <span>{{ type.name }}</span>
                                    </div>
                                    <div class="studio-actions-column">
                                        <button mat-button color="primary" [disabled]="deployment.state !== 'created' || (downloading | async)" (click)="download(deployment.uuid, type.value)">
                                            <mat-progress-spinner *ngIf="downloading | async" class="button-spinner" [diameter]="15" mode="indeterminate"></mat-progress-spinner> {{ deployment.state !== 'created' ? 'Generating' : 'Download' }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-2"></div>

                        <form class="generate-layout mt-2" [formGroup]="studioReleaseFormGroup">
                            <mat-form-field class="generate-dropdown">
                                <mat-label>Version</mat-label>
                                <mat-select formControlName="studioVersion" [disabled]="Object.keys(studioVersions).length === 0">
                                    <mat-option *ngFor="let version of Object.keys(studioVersions)" [value]="version">{{ version }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="generate-dropdown">
                                <mat-label>Type</mat-label>
                                <mat-select formControlName="studioType" [disabled]="Object.keys(studioVersions).length === 0">
                                    <mat-option *ngFor="let type of studioTypes" [value]="type.value">{{ type.name }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div>
                                <button
                                    mat-stroked-button
                                    color="primary"
                                    class="multi-button-left"
                                    [disabled]="generateShouldBeDisabled('studio-vbox') || generateShouldBeDisabled('studio-install') || generateShouldBeDisabled('studio-update') || generateShouldBeDisabled('studio-docker') || studioReleaseFormGroup.invalid"
                                    (click)="generate(studioVersion.value, studioType.value)"
                                >
                                    Generate New Bundle</button
                                ><button mat-stroked-button color="primary" class="multi-button-right" [matMenuTriggerFor]="generateMore"><mat-icon>expand_more</mat-icon></button>
                                <mat-menu #generateMore="matMenu" xPosition="before">
                                    <button *ngIf="site.licenseRaw" mat-menu-item (click)="downloadLicense(site.id, site.licenseRaw)">
                                        <span>Download License File</span>
                                    </button>
                                </mat-menu>
                            </div>
                            <div *ngIf="releasesLoading$ | async"><mat-spinner [diameter]="20"></mat-spinner></div>
                        </form>
                        <div class="mt-2"></div>
                        <p>Don't know which variant to choose? Visit our <a href="https://docs.sewio.net/docs/rtls-studio-install-guide-1018162.html" target="_blank">comparison page</a>.</p>
                        <div class="form-buttons">
                            <button mat-button color="primary" matStepperNext>Next</button>
                        </div>
                    </mat-step>
                    <mat-step>
                        <ng-template matStepLabel>Deploy RTLS Studio Bundle</ng-template>
                        <p>To deploy RTLS Studio please follow the <a href="https://docs.sewio.net/docs/rtls-studio-install-guide-1018162.html" target="_blank">installation guide</a>.</p>
                        <p>Connection with myRTLS may be established in <span class="code">Integrations</span> tab of RTLS Studio.</p>
                        <div class="form-buttons">
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button color="primary" matStepperNext [disabled]="site.deploymentType === SiteDeploymentType.ON_PREM" [matTooltip]="site.deploymentType === SiteDeploymentType.ON_PREM ? 'Continue in On-Premise version of myRTLS' : ''" (click)="finish()">Finish</button>
                        </div>
                    </mat-step>
                </mat-vertical-stepper>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
<ng-template #loading>Loading...</ng-template>
