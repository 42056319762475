import {
    Alerts,
    Feature,
    FeatureState,
    FormatType,
    SiteFeature,
} from '@myrtls/api-interfaces';
import {
    CustomSeriesRenderItemAPI,
    CustomSeriesRenderItemParams,
    graphic,
} from 'echarts';
import { CallbackDataParams, ZRRectLike } from 'echarts/types/src/util/types';
import { DateRange } from '../models/date-range.enum';
import { FileType } from '../models/file-type.enum';
import { MenuItem } from '../models/menu-item.model';
import { MetricDataFragment } from '../models/metric-data-fragment.model';
import { RoutePath } from '../models/route-path.enum';
import {
    DIM_END_TIME,
    DIM_START_TIME,
    DIM_STATE_ID,
    DIM_STATE_VALUE,
    HEIGHT_RATIO,
    X_AXIS_LABEL_FORMAT,
} from './constants';
import * as moment from 'moment';
import { extendMoment } from 'moment-range';

export function createMenuItems(
    features: Feature[],
    siteId: string,
): MenuItem[] {
    return features
        .map(({ name, state, hidden }): MenuItem | null => {
            switch (name) {
                case SiteFeature.SITE_DEPLOYMENT: {
                    return {
                        link: `/${RoutePath.SITE}/${siteId}/${SiteFeature.SITE_DEPLOYMENT}`,
                        icon: 'publish',
                        label: 'Deployment',
                        category: 'Site Management',
                        state,
                        hidden,
                    };
                }
                case SiteFeature.PHONE_CALL: {
                    return {
                        link: `/${RoutePath.SITE}/${siteId}/${SiteFeature.PHONE_CALL}`,
                        icon: 'call',
                        label: 'Phone Call',
                        category: 'Support',
                        state,
                        hidden,
                    };
                }
                case SiteFeature.DEVICE_CARE_INSTALLATION: {
                    return {
                        link: `/${RoutePath.SITE}/${siteId}/${SiteFeature.DEVICE_CARE_INSTALLATION}`,
                        icon: 'install_desktop',
                        label: 'Installation',
                        category: 'Device Care',
                        state,
                        hidden,
                    };
                }
                case SiteFeature.DEVICE_CARE_DASHBOARD: {
                    return {
                        link: `/${RoutePath.SITE}/${siteId}/${SiteFeature.DEVICE_CARE_DASHBOARD}`,
                        icon: 'dashboard',
                        label: 'Dashboard',
                        category: 'Device Care',
                        state,
                        hidden,
                    };
                }
                case SiteFeature.DEVICE_CARE_HISTORY: {
                    return {
                        link: `/${RoutePath.SITE}/${siteId}/${SiteFeature.DEVICE_CARE_HISTORY}`,
                        icon: 'history',
                        label: 'History',
                        category: 'Device Care',
                        state,
                        hidden,
                    };
                }
                case SiteFeature.DEVICE_CARE_SETTINGS: {
                    return {
                        link: `/${RoutePath.SITE}/${siteId}/${SiteFeature.DEVICE_CARE_SETTINGS}`,
                        icon: 'settings',
                        label: 'Settings',
                        category: 'Device Care',
                        state,
                        hidden,
                    };
                }
                case SiteFeature.DEVICE_CARE_GRAFANA: {
                    return {
                        link: `/${RoutePath.SITE}/${siteId}/${SiteFeature.DEVICE_CARE_GRAFANA}`,
                        icon: 'bar_chart',
                        label: 'Advanced Analytics',
                        category: 'Device Care',
                        state,
                        hidden,
                    };
                }
                case SiteFeature.SITE_USERS: {
                    return {
                        link: `/${RoutePath.SITE}/${siteId}/${SiteFeature.SITE_USERS}`,
                        icon: 'people',
                        label: 'Users',
                        category: 'Site Management',
                        state,
                        hidden,
                    };
                }
                case SiteFeature.RTLS_PLAYER: {
                    return {
                        link: `/${RoutePath.SITE}/${siteId}/${SiteFeature.RTLS_PLAYER}`,
                        icon: 'smart_display',
                        category: 'System Optimization',
                        label: 'RTLS Player',
                        state,
                        hidden,
                    };
                }
                case SiteFeature.SITE_OVERVIEW: {
                    return {
                        link: `/${RoutePath.SITE}/${siteId}/${SiteFeature.SITE_OVERVIEW}`,
                        icon: 'home',
                        category: 'Site Management',
                        label: 'Overview',
                        state: FeatureState.ENABLED,
                        hidden,
                    };
                }
                case SiteFeature.SYSTEM_REVIEW: {
                    return {
                        link: `/${RoutePath.SITE}/${siteId}/${SiteFeature.SYSTEM_REVIEW}`,
                        icon: 'checklist',
                        category: 'Support',
                        label: 'System Review',
                        state,
                        hidden,
                    };
                }
                case SiteFeature.REPORT_TICKET: {
                    return {
                        link: `https://portal.sewio.net/sites/${siteId}`,
                        icon: 'report_problem',
                        label: 'Report Ticket',
                        category: 'Support',
                        state,
                        hidden,
                        external: true,
                    };
                }
            }

            return null;
        })
        .filter((item): item is MenuItem => {
            return item !== null;
        });
}

export function getFieldAlias(
    fragment: MetricDataFragment,
    fields: {
        name: string;
        alias: string;
        tags: string[];
    }[],
): string {
    const field = fields.find(({ name }) => name === fragment._field);
    if (field?.tags.length) {
        let fieldAlias = field.alias;
        field.tags
            .filter(tag => (tag as keyof MetricDataFragment) != null)
            .forEach(tag => {
                fieldAlias = fieldAlias.replace(
                    `$tag_${tag}`,
                    (fragment[tag] || '') as string,
                );
            });
        return fieldAlias;
    }
    return field?.alias || '';
}

export function tooltipFormatter(
    params: CallbackDataParams[] | CallbackDataParams,
    metric: string,
    mappings: { from: number; to: string; color?: string }[],
    abbreviation?: { prefix: string; value: number },
    unit?: string,
    timeline = false,
    format?: FormatType,
): string {
    if (!Array.isArray(params)) {
        params = [params];
    }

    const tooltip = params
        .sort(
            (a, b) =>
                // this type conversion is required because of echarts bad type definitions
                parseFloat((b.data as string[])[1]) -
                parseFloat((a.data as string[])[1]),
        )
        .reduce(
            (acc: string, param, index) =>
                `${index !== 0 ? acc + '<br>' : ''}${param.marker}${
                    param.seriesName
                }: ${valueFormatter(
                    // this type conversion is required because of echarts bad type definitions
                    parseFloat(
                        timeline
                            ? (param.data as string[])[2]
                            : (param.data as string[])[1],
                    ),
                    mappings,
                    abbreviation,
                    unit,
                    format,
                )}`,
            '',
        );

    if (timeline) {
        const [startDate, endDate] = params[0].data as string[];
        const formattedStart = moment(startDate).format(X_AXIS_LABEL_FORMAT);
        const formattedEnd = moment(endDate).format(X_AXIS_LABEL_FORMAT);
        return `${tooltip} <br/> From: ${formattedStart} <br/> To: ${formattedEnd}`;
    }

    const time = (params[0].data as string[])[0];
    const formattedDate = moment(time).format(X_AXIS_LABEL_FORMAT);
    return `${tooltip} <br/> At: ${formattedDate}`;
}

export function valueFormatter(
    value: number,
    mappings: { from: number; to: string }[],
    abbreviation?: { prefix: string; value: number },
    unit?: string,
    format?: FormatType,
): string {
    if (format === FormatType.DURATION) {
        return moment.duration(value, 'seconds').humanize();
    }

    return (
        mappings.find(({ from }) => value === from)?.to ||
        `${((value || 0) / (abbreviation?.value || 1)).toFixed(0)} ${
            abbreviation?.prefix || ''
        }${unit || ''}`
    );
}

export function aggregateTimelineData(
    data: (number | string | undefined)[][],
    aggregate: number,
): [Date, Date, number, string][] {
    let startTime: moment.Moment;
    let endTime: moment.Moment;
    let currentStateValue: number | null = null;
    const results: [Date, Date, number, string][] = [];

    data.forEach((item, index) => {
        if (
            typeof item[0] !== 'string' ||
            typeof item[1] !== 'number' ||
            typeof item[2] !== 'string'
        ) {
            return;
        }

        if (currentStateValue === null) {
            startTime = moment(item[0]);
            endTime = moment(item[0]).add(aggregate, 'seconds');
            currentStateValue = item[1];
        }

        if (
            item[1] === currentStateValue &&
            moment(item[0]).unix() === endTime.unix()
        ) {
            endTime = moment(item[0]).add(aggregate, 'seconds');
        } else {
            results.push([
                startTime.toDate(),
                endTime.toDate(),
                currentStateValue,
                item[2],
            ]);

            startTime = moment(item[0]);
            endTime = moment(item[0]).add(aggregate, 'seconds');
            currentStateValue = item[1];
        }

        if (index === data.length - 1) {
            results.push([
                startTime.toDate(),
                endTime.toDate(),
                currentStateValue,
                item[2],
            ]);
        }
    });

    return results;
}

// custom return type created because of echarts bad type definitions
interface CoordSysParams {
    x: number;
    y: number;
    width: number;
    height: number;
}

function clipRectByRect(
    // this type extension is required because of echarts bad type definitions
    params: CustomSeriesRenderItemParams & { coordSys: CoordSysParams },
    rect: { x: number; y: number; width: number; height: number },
): ZRRectLike {
    return graphic.clipRectByRect(rect, {
        x: params.coordSys.x,
        y: params.coordSys.y,
        width: params.coordSys.width,
        height: params.coordSys.height,
    });
}

// custom return type created because of echarts bad type definitions
interface RenderRectResult {
    type: 'rect';
    ignore: boolean;
    shape: {
        x: number;
        y: number;
        width: number;
        height: number;
    };
    style: {
        fill: string;
        stroke: string;
        text: string;
        textFill: string;
    };
}

export function renderRectangle(
    params: CustomSeriesRenderItemParams,
    api: CustomSeriesRenderItemAPI,
    mappings: { from: number; to: string; color?: string }[],
): RenderRectResult {
    const categoryIndex = api.value(DIM_STATE_ID);
    const currentState = api.value(DIM_STATE_VALUE);
    const timeStart = api.coord([api.value(DIM_START_TIME), categoryIndex]);
    const timeEnd = api.coord([api.value(DIM_END_TIME), categoryIndex]);
    const rectWidth = timeEnd[0] - timeStart[0];

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const rectHeight = api.size([0, 1])[1] * HEIGHT_RATIO;

    const x = timeStart[0];
    const y = timeStart[1] - rectHeight / 2;

    const currentMapping = mappings.find(
        ({ from }) => from === Number(currentState),
    );
    const fill = currentMapping?.color || '#888888';
    const text = ''; //currentMapping?.to || currentState.toString();

    const rectText = clipRectByRect(
        params as CustomSeriesRenderItemParams & { coordSys: CoordSysParams },
        {
            x: x,
            y: y,
            width: rectWidth,
            height: rectHeight,
        },
    );

    return {
        type: 'rect',
        ignore: false,
        shape: rectText,
        style: {
            fill,
            text,
            stroke: 'transparent',
            textFill: '#ffffff',
        },
    };
}

export function getFilenameFromDateRange(
    dateRange: DateRange,
    from: Date,
    to: Date,
): string {
    let date: string;

    switch (dateRange) {
        case DateRange.DAY:
            date = moment(to).format('DD/MM/YYYY');
            break;
        case DateRange.WEEK:
            date = `${moment(from).format('DD/MM/YYYY')} - ${moment(to).format(
                'DD/MM/YYYY',
            )}`;
            break;
        case DateRange.MONTH:
            date = moment(to).format('MM/YYYY');
            break;
        case DateRange.YEAR:
            date = moment(to).format('YYYY');
            break;
    }
    return `${date}`;
}

export function exportFile(
    data: string,
    type: FileType,
    filename: string,
): void {
    const blob = new Blob([data], {
        type:
            type === FileType.CSV
                ? 'text/csv;charset=utf-8;'
                : 'application/pdf;charset=base64;',
    });
    downloadFile(blob, type, filename);
}

export function downloadFile(
    blob: Blob,
    type: FileType,
    filename: string,
): void {
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('target', '_blank');
    link.setAttribute('download', `${filename}.${type}`);
    link.click();
}

export function shouldAlert(
    alerts: Alerts,
    name: string,
    from?: Date,
    to?: Date,
): boolean {
    let found = false;
    const momentRange = extendMoment(moment);

    for (const date in alerts) {
        if (found) {
            break;
        }

        for (const interval of alerts[date].alerts) {
            if (interval.metric !== name) {
                continue;
            }

            const alertRange = momentRange.range(
                moment(interval.from),
                moment(interval.to === null ? undefined : interval.to),
            );

            const metricRange = momentRange.range(moment(from), moment(to));

            if (alertRange.overlaps(metricRange)) {
                found = true;
                break;
            }
        }
    }

    return found;
}

export function escapeRegExp(original: string) {
    return original.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function isInThePast(unixTime: number) {
    return moment.unix(unixTime).isBefore(moment());
}

export function isBeforeNDaysFromTheDate(unixTime: number, n: number) {
    return moment().isBefore(moment.unix(unixTime).subtract(n, 'days'));
}

export function isDns(hostname: string) {
    if (hostname === 'localhost' || hostname === '127.0.0.1') {
        return false;
    }

    const ipRegex =
        /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    if (ipRegex.test(hostname)) {
        return false;
    }

    return true;
}

export function stringDatesToDateRange(from: string, to: string): DateRange {
    if (from === to) {
        return DateRange.DAY;
    }

    if (moment(from).isSame(to, 'isoWeek')) {
        return DateRange.WEEK;
    }

    if (moment(from).isSame(to, 'month')) {
        return DateRange.MONTH;
    }

    return DateRange.YEAR;
}

export function isDateIntervalValid(from: string, to: string): boolean {
    const fromMoment = moment(from);

    if (!fromMoment.isValid()) {
        return false;
    }

    const toMoment = moment(to);

    if (!toMoment.isValid()) {
        return false;
    }

    if (toMoment.isBefore(fromMoment)) {
        return false;
    }

    if (toMoment.diff(fromMoment, 'days') > 365) {
        return false;
    }

    return true;
}

export function debug<T>(value: T): T {
    console.log(value);
    return value;
}

export function debugMessage<T>(message: string, value: T): T {
    console.log(message);
    return value;
}
