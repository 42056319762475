import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleStatWidgetComponent } from './single-stat-widget.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [SingleStatWidgetComponent],
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatTooltipModule,
    ],
    exports: [SingleStatWidgetComponent],
})
export class SingleStatWidgetModule {}
