import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertIntervalWithMetricSettings } from '../../shared/models/alerts.model';

interface AlertsModalData {
    date: string;
    alerts: AlertIntervalWithMetricSettings[];
}

@Component({
    selector: 'myrtls-alerts-modal',
    templateUrl: './alerts-modal.component.html',
    styleUrls: ['./alerts-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertsModalComponent {
    columnsToDisplay = ['id', 'resolved', 'count', 'duration'];

    constructor(
        public dialogRef: MatDialogRef<string>,
        @Inject(MAT_DIALOG_DATA) public data: AlertsModalData,
    ) {}

    count(alerts: AlertIntervalWithMetricSettings[]): {
        resolved: number;
        pending: number;
    } {
        return {
            resolved: alerts.filter(alert => alert.to !== null).length,
            pending: alerts.filter(alert => alert.to === null).length,
        };
    }
}
