<div class="phone-container" *ngIf="phoneCall$ | async as phone">
    <div *ngIf="phone !== null" class="phone-card">
        <div>For critical level issues only, you have an option to call Sewio Customer Experience team directly by phone. This service is available only within myRTLS Care+ plan.</div>
        <div class="mt-3"><b>Critical Level:</b> A problem that severely impacts your use of the system in a production environment. The situation halts your business operations and no procedural workaround exists.</div>
        <div class="mt-3 mb-2">Our support team is available at (CET - Europe/Prague):</div>
        <div *ngFor="let day of getDays(phone)">
            <div class="phone-day">{{ getDayByNumber(day) }}:</div>
            {{ parseTimeUnit(phone.businessHours[day].start[0]) }}
            :
            {{ parseTimeUnit(phone.businessHours[day].start[1]) }}
            -
            {{ parseTimeUnit(phone.businessHours[day].end[0]) }}
            :
            {{ parseTimeUnit(phone.businessHours[day].end[1]) }}
        </div>
        <div class="mt-3" *ngIf="phone.active"><b class="mr-2">Current Status:</b><mat-icon class="phone-icon text-brand" fontSet="material-icons-outlined">phone_enabled</mat-icon> Available</div>
        <div class="mt-3" *ngIf="!phone.active"><b class="mr-2">Current Status:</b><mat-icon class="phone-icon text-error" fontSet="material-icons-outlined">phone_disabled</mat-icon> Not available (outside of working hours)</div>
        <div class="mt-2" *ngIf="phone.active">
            <b class="mr-2">Phone Number:</b><a [href]="'tel:' + phone.number">{{ phone.number }}</a>
        </div>
        <div class="mt-2" *ngIf="!phone.active">Please report a ticket.</div>
    </div>
</div>
