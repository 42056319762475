<div class="flex flex-col p-5">
    <div>
        <myrtls-ratio-bar-widget [data]="stackedChartData$ | async"></myrtls-ratio-bar-widget>
    </div>
    <div>
        <myrtls-single-stat-widget [data]="singleStatWidgetData$ | async"></myrtls-single-stat-widget>
    </div>
    <div>
        <myrtls-alert-calendar-widget [alertData]="alerts$ | async"></myrtls-alert-calendar-widget>
    </div>
    <div>
        <myrtls-dashboard-reports [data]="reports$ | async"></myrtls-dashboard-reports>
    </div>
</div>
