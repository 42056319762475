import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import {
    SnackBarConfig,
    SnackBarType,
} from '../../shared/models/snack-bar-config.model';
import { AppState } from '../../store';
import { hideSnackBarAction } from '../../store/snack-bar/snack-bar.actions';

@Component({
    selector: 'myrtls-custom-snack-bar',
    templateUrl: './custom-snack-bar.component.html',
    styleUrls: ['./custom-snack-bar.component.scss'],
})
export class CustomSnackBarComponent {
    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarConfig,
        private readonly store: Store<AppState>,
    ) {}

    hideSnackBar(): void {
        this.store.dispatch(hideSnackBarAction());
    }

    getSnackBarColor(type: SnackBarType) {
        switch (type) {
            case SnackBarType.FAILED:
                return 'error';
            case SnackBarType.SUCCESSFUL:
                return 'brand';
            case SnackBarType.WARNING:
                return 'warning';
            default:
                return 'info';
        }
    }

    getSnackBarIcon(type: SnackBarType) {
        switch (type) {
            case SnackBarType.FAILED:
                return 'close';
            case SnackBarType.SUCCESSFUL:
                return 'done';
            case SnackBarType.WARNING:
                return 'priority_high';
            default:
                return 'close';
        }
    }
}
