import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'mapping' })
export class MappingPipe implements PipeTransform {
    transform(
        value: string | number,
        mapping: { [key: string | number]: string },
    ): string {
        if (!mapping[value]) {
            return value.toString();
        }

        return mapping[value];
    }
}
