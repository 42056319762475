<div
    class="p-2 rounded-md flex gap-4 items-center relative"
    [ngClass]="{
        'hover:bg-light-200 cursor-pointer': state === FeatureState.ENABLED,
        'text-dark-300 cursor-default': state !== FeatureState.ENABLED,
        'bg-brand-50 text-brand': active,
        'text-dark': !active
    }"
    [matTooltipDisabled]="expanded"
    [matTooltip]="getMenuItemTooltip(title, state)"
    [matTooltipPosition]="'right'"
>
    <myrtls-icon [icon]="icon || 'arrow_right'"></myrtls-icon>
    @if(expanded) {
    <div class="grow flex items-center font-light">
        {{ title }}
    </div>
    } @if(state !== FeatureState.ENABLED) {
    <myrtls-icon
        class="text-error"
        [ngClass]="{
        'absolute top-0 right-0 bg-white rounded-full p-1': !expanded,
    }"
        [matTooltipDisabled]="!expanded"
        [matTooltip]="getIconTooltip(state)"
        [matTooltipPosition]="'right'"
        [icon]="getIcon(state) || 'arrow_right'"
        [size]="expanded ? 'md' : 'sm'"
    ></myrtls-icon>
    }
</div>
