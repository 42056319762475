import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
    AlertInterval,
    Bundle,
    BundleType,
    ClearDeviceCareParameters,
    DeviceCare,
    DeviceCareInstallation,
    DeviceCarePutRequest,
    Feature,
    InitializeRtlsPlayerParameters,
    Metric,
    MetricOverridesPutRequest,
    MetricPutRequest,
    MetricsStatus,
    PhoneCall,
    Release,
    Report,
    RtlsPlayerOptions,
    Site,
    SiteDeploymentType,
    SiteRtlsPlayer,
    SmtpStatus,
    SupportActivity,
    UpdateRtlsPlayerParameters,
    User,
} from '@myrtls/api-interfaces';
import { DateRange } from '../../shared/models/date-range.enum';
import { MetricDataFetchType } from '../../shared/models/metric-data-fetch-type.enum';
import {
    MetricDashboardDataFragment,
    MetricDataFragment,
} from '../../shared/models/metric-data-fragment.model';
import { DeviceCarePeriod } from '../../shared/models/device-care-period.model';
import { DeviceCareInfo } from './sites.reducer';

export const getSiteRequestAction = createAction('[Sites] Get Site Request');

export const getSiteSuccessAction = createAction(
    '[Sites] Get Site Success',
    props<{ site: Site }>(),
);

export const getSiteFailureAction = createAction(
    '[Sites] Get Site Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const getSitesRequestAction = createAction('[Sites] Get Sites Request');

export const getSitesSuccessAction = createAction(
    '[Sites] Get Sites Success',
    props<{ sites: Site[] }>(),
);

export const getSitesFailureAction = createAction(
    '[Sites] Get Sites Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const getSiteUsersRequestAction = createAction(
    '[Sites] Get Site Users Request',
);

export const getSiteUsersSuccessAction = createAction(
    '[Sites] Get Site Users Success',
    props<{ siteId: string; users: User[] }>(),
);

export const getSiteUsersFailureAction = createAction(
    '[Sites] Get Site Users Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const selectSiteAction = createAction(
    '[Sites] Select Site',
    props<{ siteId: string | null }>(),
);

export const inviteUserRequestAction = createAction(
    '[Sites] Invite User Request',
    props<{ user: User }>(),
);

export const deploySiteRequestAction = createAction(
    '[Sites] Deploy Site Request',
    props<{ deploymentType: SiteDeploymentType }>(),
);

export const deploySiteSuccessAction = createAction(
    '[Sites] Deploy Site Success',
);

export const deploySiteFailureAction = createAction(
    '[Sites] Deploy Site Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const activateDeviceCareRequestAction = createAction(
    '[Sites] Activate Device Care Request',
);

export const activateDeviceCareSuccessAction = createAction(
    '[Sites] Activate Device Care Success',
    props<{ date: number; site: string }>(),
);

export const activateDeviceCareFailureAction = createAction(
    '[Sites] Activate Device Care Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const inviteUserSuccessAction = createAction(
    '[Sites] Invite User Success',
    props<{ siteId: string; user: User }>(),
);

export const inviteUserFailureAction = createAction(
    '[Sites] Invite User Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const editUserPermissionsRequestAction = createAction(
    '[Sites] Edit User Permissions Request',
    props<{ user: User }>(),
);

export const editUserPermissionsSuccessAction = createAction(
    '[Sites] Edit User Permissions Success',
    props<{ siteId: string; user: User }>(),
);

export const editUserPermissionsFailureAction = createAction(
    '[Sites] Edit User Permissions Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const startPoolingSiteMetricsStatusAction = createAction(
    '[Sites] Start Pooling Site Metrics Status',
    props<{ intervalTime: number }>(),
);

export const stopPoolingSiteMetricsStatusAction = createAction(
    '[Sites] Stop Pooling Site Metrics Status',
);

export const startPoolingSiteDeploymentBundlesAction = createAction(
    '[Sites] Start Pooling Site Deployment Bundles',
    props<{ intervalTime: number; bundleType: BundleType }>(),
);

export const stopPoolingSiteDeploymentBundlesAction = createAction(
    '[Sites] Stop Pooling Site Deployment Bundles',
);

export const getSiteMetricsStatusSuccessAction = createAction(
    '[Sites] Get Site Metrics Status Success',
    props<{ status: MetricsStatus }>(),
);

export const getSiteMetricsStatusFailureAction = createAction(
    '[Sites] Get Site Metrics Status Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const deleteUserRequestAction = createAction(
    '[Sites] Delete User Request',
    props<{ userId: string }>(),
);

export const deleteUserSuccessAction = createAction(
    '[Sites] Delete User Success',
    props<{ siteId: string; userId: string }>(),
);

export const deleteUserFailureAction = createAction(
    '[Sites] Delete User Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const getSiteFeaturesRequestAction = createAction(
    '[Sites] Get Site Features Request',
);

export const getSiteFeaturesSuccessAction = createAction(
    '[Sites] Get Site Features Success',
    props<{ siteId: string; features: Feature[] }>(),
);

export const getSiteFeaturesFailureAction = createAction(
    '[Sites] Get Site Features Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const getSiteLicenseRawRequestAction = createAction(
    '[Sites] Get Site License Raw Request',
);

export const getSiteLicenseRawSuccessAction = createAction(
    '[Sites] Get Site License Raw Success',
    props<{ siteId: string; licenseRaw: string }>(),
);

export const getSiteLicenseRawFailureAction = createAction(
    '[Sites] Get Site License Raw Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const getSiteMetricsDataRequestAction = createAction(
    '[Sites] Get Site Metrics Data Request',
    props<{ siteId: string; metrics: Metric[] }>(),
);

export const getSiteMetricDataRequestAction = createAction(
    '[Sites] Get Site Metric Data Request',
    props<{
        siteId: string;
        metricId: string;
        query: string;
        deviceCare: DeviceCareInfo;
        overrides: string[];
    }>(),
);

export const getSiteMetricDataSuccessAction = createAction(
    '[Sites] Get Site Metric Data Success',
    props<{
        metricId: string;
        siteId: string;
        data: MetricDataFragment[];
        range: DateRange;
        from: Date;
        to: Date;
    }>(),
);

export const getSiteMetricDataFailureAction = createAction(
    '[Sites] Get Site Metric Data Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const getSiteMetricDashboardDataRequestAction = createAction(
    '[Sites] Get Site Metric Dashboard Data Request',
    props<{
        siteId: string;
        metricId: string;
        query: string;
        deviceCare: DeviceCareInfo;
        overrides: string[];
    }>(),
);

export const getSiteMetricDashboardDataSuccessAction = createAction(
    '[Sites] Get Site Metric Dashboard Data Success',
    props<{
        metricId: string;
        siteId: string;
        data: MetricDashboardDataFragment[];
    }>(),
);

export const getSiteMetricDashboardDataFailureAction = createAction(
    '[Sites] Get Site Metric Dashboard Data Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const getSiteMetricFilteringDataRequestAction = createAction(
    '[Sites] Get Site Metric Filtering Data Request',
    props<{
        metricId: string;
    }>(),
);

export const getSiteMetricFilteringDataSuccessAction = createAction(
    '[Sites] Get Site Metric Filtering Data Success',
    props<{
        metricId: string;
        siteId: string;
        data: { [key: string]: string }[];
    }>(),
);

export const getSiteMetricFilteringDataFailureAction = createAction(
    '[Sites] Get Site Metric Filtering Data Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const getPhoneCallRequestAction = createAction(
    '[Sites] Get Phone Call Request',
);

export const getPhoneCallSuccessAction = createAction(
    '[Sites] Get Phone Call Success',
    props<{ phoneCall: PhoneCall }>(),
);

export const getPhoneCallFailureAction = createAction(
    '[Sites] Get Phone Call Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const getPlayerOptionsRequestAction = createAction(
    '[Sites] Get Player Options Request',
);

export const getPlayerOptionsSuccessAction = createAction(
    '[Sites] Get Player Options Success',
    props<{ options: RtlsPlayerOptions }>(),
);

export const getPlayerOptionsFailureAction = createAction(
    '[Sites] Get Player Options Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const getSitePlayerRequestAction = createAction(
    '[Sites] Get Site Player Request',
);

export const startPoolingSitePlayerRequestAction = createAction(
    '[Sites] Start Pooling Site Player Request',
    props<{ intervalTime: number }>(),
);

export const stopPoolingSitePlayerRequestAction = createAction(
    '[Sites] Stop Pooling Site Player Request',
);

export const getSitePlayerSuccessAction = createAction(
    '[Sites] Get Site Player Success',
    props<{ player: SiteRtlsPlayer }>(),
);

export const getSitePlayerFailureAction = createAction(
    '[Sites] Get Site Player Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const getSiteActivitiesRequestAction = createAction(
    '[Sites] Get Site Activities Request',
);

export const getSiteActivitiesSuccessAction = createAction(
    '[Sites] Get Site Activities Success',
    props<{ activities: SupportActivity[] }>(),
);

export const getSiteActivitiesFailureAction = createAction(
    '[Sites] Get Site Activities Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const initializeSitePlayerRequestAction = createAction(
    '[Sites] Initialize Site Player Request',
    props<{ parameters: InitializeRtlsPlayerParameters }>(),
);

export const initializeSitePlayerSuccessAction = createAction(
    '[Sites] Initialize Site Player Success',
    props<{ player: SiteRtlsPlayer }>(),
);

export const initializeSitePlayerFailureAction = createAction(
    '[Sites] Initialize Site Player Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const createSiteTemplatesRequestAction = createAction(
    '[Sites] Create Site Templates Request',
);

export const createSiteTemplatesSuccessAction = createAction(
    '[Sites] Create Site Templates Success',
);

export const createSiteTemplatesFailureAction = createAction(
    '[Sites] Create Site Templates Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const updateSitePlayerRequestAction = createAction(
    '[Sites] Update Site Player Request',
    props<{ parameters: UpdateRtlsPlayerParameters }>(),
);

export const updateSitePlayerSuccessAction = createAction(
    '[Sites] Update Site Player Success',
    props<{ player: SiteRtlsPlayer }>(),
);

export const updateSitePlayerFailureAction = createAction(
    '[Sites] Update Site Player Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const getSiteMetricsConfigRequestAction = createAction(
    '[Sites] Get Site Metrics Config Request',
    props<{ dataFetch: MetricDataFetchType }>(),
);

export const getSiteMetricsConfigSuccessAction = createAction(
    '[Sites] Get Site Metrics Config Success',
    props<{
        siteId: string;
        metrics: Metric[];
        dataFetch: MetricDataFetchType;
    }>(),
);

export const getSiteMetricsConfigFailureAction = createAction(
    '[Sites] Get Site Metrics Config Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const getSiteDeviceCareInstallationRequestAction = createAction(
    '[Sites] Get Site Device Care Installation Request',
);

export const getSiteDeviceCareInstallationSuccessAction = createAction(
    '[Sites] Get Site Device Care Installation Success',
    props<{
        installation: DeviceCareInstallation;
    }>(),
);

export const getSiteDeviceCareInstallationFailureAction = createAction(
    '[Sites] Get Site Device Care Installation Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const getSiteDeviceCareConfigRequestAction = createAction(
    '[Sites] Get Site Device Care Request',
    props<{
        focus?: string;
        period?: DeviceCarePeriod;
    }>(),
);

export const getSiteDeviceCareConfigSuccessAction = createAction(
    '[Sites] Get Site Device Care Success',
    props<{
        siteId: string;
        config: DeviceCare;
        period?: DeviceCarePeriod;
    }>(),
);

export const getSiteDeviceCareConfigFailureAction = createAction(
    '[Sites] Get Site Device Care Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const updateSiteMetricSettingsRequestAction = createAction(
    '[Sites] Update Metric Settings Request',
    props<{ metricPutRequest: MetricPutRequest[] }>(),
);

export const updateSiteMetricSettingsSuccessAction = createAction(
    '[Sites] Update Metric Settings Success',
    props<{ siteId: string; metrics: Metric[] }>(),
);

export const updateSiteMetricSettingsFailureAction = createAction(
    '[Sites] Update Metric Settings Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const updateSiteMetricOverridesRequestAction = createAction(
    '[Sites] Update Metric Overrides Request',
    props<{
        metricId: string;
        metricOverridesPutRequest: MetricOverridesPutRequest;
    }>(),
);

export const updateSiteMetricOverridesSuccessAction = createAction(
    '[Sites] Update Metric Overrides Success',
);

export const updateSiteMetricOverridesFailureAction = createAction(
    '[Sites] Update Metric Overrides Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const updateSiteReportSettingsRequestAction = createAction(
    '[Sites] Update Report Settings Request',
    props<{ deviceCarePutRequest: DeviceCarePutRequest }>(),
);

export const updateSiteReportSettingsSuccessAction = createAction(
    '[Sites] Update Report Settings Success',
    props<{ siteId: string; config: DeviceCare }>(),
);

export const updateSiteReportSettingsFailureAction = createAction(
    '[Sites] Update Report Settings Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const clearDeviceCareRequestAction = createAction(
    '[Sites] Clear Device Care Request',
    props<{ clearDeviceCareRequest: ClearDeviceCareParameters }>(),
);

export const clearDeviceCareSuccessAction = createAction(
    '[Sites] Clear Device Care Success',
);

export const clearDeviceCareFailureAction = createAction(
    '[Sites] Clear Device Care Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const selectPeriodDateRangeAction = createAction(
    '[Sites] Select Period Date Range',
    props<{
        siteId: string;
        dateRange: DateRange;
        from: Date;
        to: Date;
    }>(),
);

export const setAlertsOnly = createAction(
    '[Sites] Set Alerts Only',
    props<{ alertsOnly: boolean }>(),
);

export const setIsReport = createAction(
    '[Sites] Set Is Report',
    props<{ isReport: boolean }>(),
);

export const setMetricLoadingAction = createAction(
    '[Sites] Set Metric Loading',
    props<{ siteId: string; metricId: string; loading: boolean }>(),
);

export const exportCSVChartDataAction = createAction(
    '[Sites] Export CSV Chart Data',
    props<{ metricId: string }>(),
);

export const getSiteAlertsRequestAction = createAction(
    '[Sites] Get Site Alerts Request',
);

export const getCurrentSiteAlertsRequestAction = createAction(
    '[Sites] Get Current Site Alerts Request',
);

export const getSiteAlertsSuccessAction = createAction(
    '[Sites] Get Site Alerts Success',
    props<{ alerts: AlertInterval[] }>(),
);

export const getCurrentSiteAlertsSuccessAction = createAction(
    '[Sites] Get Current Site Alerts Success',
    props<{ alerts: AlertInterval[] }>(),
);

export const getSiteAlertsFailureAction = createAction(
    '[Sites] Get Site Alerts Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const getCurrentSiteAlertsFailureAction = createAction(
    '[Sites] Get Current Site Alerts Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const updateSiteReportMetricsAction = createAction(
    '[Sites] Update Site Report Metrics',
    props<{ metrics: string[]; siteId: string }>(),
);

export const getSiteReportsRequestAction = createAction(
    '[Sites] Get Site Reports Request',
);

export const getSiteReportsSuccessAction = createAction(
    '[Sites] Get Site Reports Success',
    props<{ reports: Report[] }>(),
);

export const getSiteReportsFailureAction = createAction(
    '[Sites] Get Site Reports Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const getSiteReportRequestAction = createAction(
    '[Sites] Get Site Report Request',
    props<{ reportId: string; reportName: string }>(),
);

export const getSiteSmtpStatusRequestAction = createAction(
    '[Sites] Get Site SMTP Status Request',
);

export const getSiteSmtpStatusSuccessAction = createAction(
    '[Sites] Get Site SMTP Status Success',
    props<{ siteId: string; smtpStatus: SmtpStatus }>(),
);

export const getSiteSmtpStatusFailureAction = createAction(
    '[Sites] Get Site SMTP Status Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const getSiteReleasesAction = createAction('[Sites] Get Site Releases');

export const getSiteReleasesSuccessAction = createAction(
    '[Sites] Get Site Releases Success',
    props<{ releases: Release[] }>(),
);

export const getSiteReleasesFailureAction = createAction(
    '[Sites] Get Site Releases Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const getSiteDeploymentBundlesAction = createAction(
    '[Sites] Get Site Deployment Bundles',
    props<{ bundleType: BundleType }>(),
);

export const getSiteDeploymentBundlesSuccessAction = createAction(
    '[Sites] Get Site Deployment Bundles Success',
    props<{ bundles: Bundle[]; bundleType: BundleType }>(),
);

export const getSiteDeploymentBundlesFailureAction = createAction(
    '[Sites] Get Site Deployment Bundles Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const createSiteDeploymentBundleAction = createAction(
    '[Sites] Create Site Deployment Bundle',
    props<{ bundleType: BundleType; version: string }>(),
);

export const createSiteDeploymentBundleSuccessAction = createAction(
    '[Sites] Create Site Deployment Bundle Success',
    props<{ bundle: Bundle }>(),
);

export const createSiteDeploymentBundleFailureAction = createAction(
    '[Sites] Create Site Deployment Bundle Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const downloadSiteDeploymentAction = createAction(
    '[Sites] Site Deployment Download',
    props<{ uuid: string; bundleType: BundleType }>(),
);

export const downloadSiteDeploymentSuccessAction = createAction(
    '[Sites] Site Deployment Download Success',
);

export const downloadSiteDeploymentFailureAction = createAction(
    '[Sites] Site Deployment Download Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const getVersionAction = createAction('[Sites] Get Version');

export const getVersionSuccessAction = createAction(
    '[Sites] Get Version Sucess',
    props<{ version: string }>(),
);

export const getVersionFailureAction = createAction(
    '[Sites] Get Version Failure',
    props<{ error: HttpErrorResponse }>(),
);
