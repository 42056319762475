import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { CustomSnackBarComponent } from '../../components/custom-snack-bar/custom-snack-bar.component';
import { hideSnackBarAction, showSnackBarAction } from './snack-bar.actions';

@Injectable()
export class SnackBarEffects {
    showSnackBar$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(showSnackBarAction),
                tap(({ config: { duration, ...data } }) =>
                    this.snackBar.openFromComponent(CustomSnackBarComponent, {
                        duration,
                        data,
                    }),
                ),
            ),
        { dispatch: false },
    );

    hideSnackBar$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(hideSnackBarAction),
                tap(() => this.snackBar.dismiss()),
            ),
        { dispatch: false },
    );

    constructor(
        private readonly actions$: Actions,
        private readonly snackBar: MatSnackBar,
    ) {}
}
