import { Company, Feature, FeatureState } from '@myrtls/api-interfaces';
import { createSelector } from '@ngrx/store';
import { MenuItem } from '../../shared/models/menu-item.model';
import { RoutePath } from '../../shared/models/route-path.enum';
import { createMenuItems } from '../../shared/utils/utils';
import {
    $companies,
    $companiesLoaded,
    $selectedCompanyId,
} from '../companies/companies.selectors';
import { SiteDetail } from '../sites/sites.reducer';
import {
    $selectedSiteId,
    $siteFeatures,
    $sitesList,
    $sitesLoaded,
} from '../sites/sites.selectors';

export const $menuCategories = createSelector(
    $selectedSiteId,
    (selectedSiteId: string | null) => {
        if (selectedSiteId === null) {
            return [{ name: '', icon: '' }];
        }

        return [
            { name: '', icon: '' },
            { name: 'Site Management', icon: '' },
            { name: 'Device Care', icon: '' },
            { name: 'System Optimization', icon: '' },
            { name: 'Support', icon: '' },
        ];
    },
);

export const $menuItems = createSelector(
    $companies,
    $sitesList,
    $selectedSiteId,
    $selectedCompanyId,
    $siteFeatures,
    $companiesLoaded,
    $sitesLoaded,
    (
        companies: Company[],
        sites: SiteDetail[],
        selectedSiteId: string | null,
        selectedCompanyId: string | null,
        siteFeatures: Feature[],
        companiesLoaded: boolean,
        sitesLoaded: boolean,
    ): MenuItem[] => {
        if (!companiesLoaded || !sitesLoaded) {
            return [];
        }

        const menuItems: MenuItem[] = [];

        menuItems.push({
            link: `/${RoutePath.COMPANIES}`,
            icon: 'account_balance',
            label: 'Companies',
            category: '',
            state: FeatureState.ENABLED,
            hidden: companies.length === 1,
        });

        menuItems.push({
            link: `/${RoutePath.SITES}/${selectedCompanyId}`,
            icon: 'public',
            label: 'Sites',
            category: '',
            state: FeatureState.ENABLED,
            hidden: companies.length === 1 && sites.length === 1,
        });

        const featuresMenuItems = selectedSiteId
            ? createMenuItems(siteFeatures, selectedSiteId)
            : [];

        return [...menuItems, ...featuresMenuItems];
    },
);
