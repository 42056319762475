import { Component } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { signIn } from 'supertokens-web-js/recipe/emailpassword';
import { RoutePath } from '../../shared/models/route-path.enum';
import { SnackBarType } from '../../shared/models/snack-bar-config.model';
import { AppState } from '../../store';
import { showSnackBarAction } from '../../store/snack-bar/snack-bar.actions';
import { $smtpStatus } from '../../store/sites/sites.selectors';

@Component({
    selector: 'myrtls-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
    hidePassword = true;

    loginForm!: UntypedFormGroup;

    smtpStatus$ = this.store.select($smtpStatus);

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly router: Router,
        private readonly store: Store<AppState>,
    ) {
        this.loginForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required]],
        });
    }

    async login() {
        const response = await signIn({
            formFields: [
                { id: 'email', value: this.loginForm.controls['email'].value },
                {
                    id: 'password',
                    value: this.loginForm.controls['password'].value,
                },
            ],
        });

        switch (response.status) {
            case 'OK':
                window.location.reload();
                return;
            case 'FIELD_ERROR':
            case 'WRONG_CREDENTIALS_ERROR':
                this.store.dispatch(
                    showSnackBarAction({
                        config: {
                            type: SnackBarType.FAILED,
                            description: 'Provided credentials are not valid.',
                            actionLabel: 'Dismiss',
                        },
                    }),
                );
                return;
        }
    }

    async redirectToPasswordReset($event: MouseEvent) {
        $event.preventDefault();
        await this.router.navigate([RoutePath.AUTH, RoutePath.RESET_PASSWORD]);
        window.location.reload();
    }
}
