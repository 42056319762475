import { Component } from '@angular/core';
import { PhoneCall } from '@myrtls/api-interfaces';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import { $sitePhoneCall } from '../../store/sites/sites.selectors';

@Component({
    selector: 'myrtls-phone-call',
    templateUrl: './phone-call.component.html',
    styleUrls: ['./phone-call.component.scss'],
})
export class PhoneCallComponent {
    phoneCall$ = this.store.select($sitePhoneCall);

    constructor(private readonly store: Store<AppState>) {}

    getDays(phoneCall: PhoneCall) {
        return Object.keys(phoneCall.businessHours).map(key =>
            parseInt(key, 10),
        );
    }

    parseTimeUnit(unit: number): string {
        return ('0' + unit).slice(-2);
    }

    getDayByNumber(day: number): string {
        switch (day) {
            case 1:
                return 'Monday';
            case 2:
                return 'Tuesday';
            case 3:
                return 'Wednesday';
            case 4:
                return 'Thursday';
            case 5:
                return 'Friday';
            case 6:
                return 'Saturday';
            case 7:
                return 'Sunday';
            default:
                return '';
        }
    }
}
