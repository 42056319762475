import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SupportActivityType } from '@myrtls/api-interfaces';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import {
    $nextReview,
    $siteActivities,
} from '../../store/sites/sites.selectors';

@Component({
    selector: 'myrtls-support-activities',
    templateUrl: './support-activities.component.html',
    styleUrls: ['./support-activities.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportActivitiesComponent {
    siteActivities$ = this.store.select($siteActivities);
    nextReview$ = this.store.select($nextReview);

    emptyParagraphsRegexp = /<p><\/p>/g;

    constructor(private readonly store: Store<AppState>) {}

    getTypeIcon(type: SupportActivityType) {
        switch (type) {
            case SupportActivityType.PLANNING:
                return 'event';
            case SupportActivityType.SYSTEM_REVIEW:
                return 'preview';
            case SupportActivityType.UPDATE:
                return 'lightbulb';
            default:
                return 'drag_indicator';
        }
    }

    getTypeName(type: SupportActivityType) {
        switch (type) {
            case SupportActivityType.PLANNING:
                return 'Planning';
            case SupportActivityType.SYSTEM_REVIEW:
                return 'System Review';
            case SupportActivityType.UPDATE:
                return 'Update';
            default:
                return 'Other';
        }
    }

    openLink(url: string) {
        window.open(url, '_blank');
    }
}
