import { Injectable } from '@angular/core';
import * as SuperTokens from 'supertokens-web-js';
import * as SuperTokenSession from 'supertokens-web-js/recipe/session';
import * as SuperTokenEmailPassword from 'supertokens-web-js/recipe/emailpassword';
import { environment } from '../../../../environments/environment';
import { SiteDeploymentType } from '@myrtls/api-interfaces';

@Injectable()
export class OnPremAuthService {
    constructor() {
        if (environment.deployment === SiteDeploymentType.ON_PREM) {
            SuperTokens.init({
                appInfo: environment.auth.supertokens.appInfo,
                recipeList: [
                    SuperTokenSession.init(),
                    SuperTokenEmailPassword.init(),
                ],
            });
        }
    }

    async logIn(email: string, password: string): Promise<void> {
        const result = await SuperTokenEmailPassword.signIn({
            formFields: [
                { id: 'email', value: email },
                { id: 'password', value: password },
            ],
        });

        if (result.status !== 'OK') {
            throw new Error(result.status);
        }
    }

    async logOut(): Promise<void> {
        if (await SuperTokenSession.doesSessionExist()) {
            await SuperTokenSession.signOut();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async getUser(): Promise<any> {
        if (await SuperTokenSession.doesSessionExist()) {
            return {
                id: await SuperTokenSession.getUserId(),
                ...(await SuperTokenSession.getAccessTokenPayloadSecurely()),
            };
        } else {
            return undefined;
        }
    }

    async getAccessToken(): Promise<string | null> {
        if (await SuperTokenSession.doesSessionExist()) {
            return (await SuperTokenSession.getAccessTokenPayloadSecurely())
                .jwt as string;
        }

        return null;
    }

    async isAuthenticated(): Promise<boolean> {
        const sessionExists = await SuperTokenSession.doesSessionExist();

        if (!sessionExists) {
            return false;
        }

        if ((await SuperTokenSession.validateClaims()).length > 0) {
            if (!(await SuperTokenSession.attemptRefreshingSession())) {
                return false;
            }
        }

        return true;
    }

    async refreshToken(): Promise<boolean> {
        return SuperTokenSession.attemptRefreshingSession();
    }
}
