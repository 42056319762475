<h2 mat-dialog-title>System Performance</h2>
<div mat-dialog-content class="h-full flex flex-col mt-4 gap-4">
    <ng-container *ngIf="!cores.loading && !cpuUsed.loading && !cpuState.loading && !ramUsed.loading && !ramUsedPercentage.loading && !ramTotal.loading; else loadingTemplate">
        <div class="flex flex-row justify-between items-start">
            <div class="flex flex-col">
                <p class="mb-6 text-2xl">Total Resources</p>
                <div class="flex h-10 flex-row">
                    <div class="w-20">CPU</div>
                    <div>{{ cores.value }} cores</div>
                </div>
                <div class="flex h-10 flex-row">
                    <div class="w-20">RAM</div>
                    <div>{{ ramTotal.value }}</div>
                </div>
            </div>

            <div class="flex flex-col">
                <p class="mb-6 text-2xl">Current Usage</p>
                <div class="flex h-10 flex-row">
                    <div class="w-20">CPU</div>
                    <div class="w-28">{{ cpuUsed.value }}</div>
                    <div>
                        <span class="px-3 py-1 text-sm rounded-md text-white bg-{{ cpuState.color }}">{{ cpuState.value }}</span>
                    </div>
                </div>
                <div class="flex h-10 flex-row">
                    <div class="w-20">RAM</div>
                    <div class="w-28">{{ ramUsedPercentage.value }} ({{ ramUsed.value }})</div>
                    <div>
                        <span class="px-3 py-1 text-sm rounded-md text-white bg-{{ ramState.color }}">{{ ramState.value }}</span>
                    </div>
                </div>
            </div>
        </div>
        <myrtls-info-card *ngIf="state !== 'ok'" [type]="state"> Please consult the usage with your system administrator. </myrtls-info-card>
    </ng-container>
    <ng-template #loadingTemplate>
        <div class="w-full flex justify-center items-center">Loading...</div>
    </ng-template>
</div>
<div class="flex items-center justify-end" mat-dialog-actions>
    <button mat-button mat-dialog-close>CLOSE</button>
</div>
