import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface InfoModalData {
    content: string;
}

@Component({
    selector: 'myrtls-info-modal',
    templateUrl: './info-modal.component.html',
    styleUrls: ['./info-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoModalComponent {
    constructor(
        public dialogRef: MatDialogRef<string>,
        @Inject(MAT_DIALOG_DATA) public data: InfoModalData,
    ) {}

    openDocumentation() {
        window.open('https://docs.sewio.net/x/fwM5Aw', '_blank');
    }
}
