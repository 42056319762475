<div class="reports-wrapper">
    <div class="header-container">
        <div class="title">Monthly Reports</div>
        <button mat-button color="primary" (click)="navigateToSettings()">Automatic Reports</button>
    </div>
    <div class="content-container">
        <div class="row-wrapper" *ngFor="let row of data">
            <div class="title">{{ row.date | reformatTime: 'YYYY MM':'YYYY MMMM' }}</div>
            <div>
                <span class="size-label">{{ row.size }}</span>
                <button mat-button color="primary" (click)="downloadReport(row.id, 'myRTLS Full Report - ' + row.date + '.pdf')">Download</button>
            </div>
        </div>
    </div>
</div>
