import { Component, Input } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { MatCardModule } from '@angular/material/card';
import { IconComponent } from '../icon/icon.component';

@Component({
    selector: 'myrtls-info-card[type]',
    templateUrl: './info-card.component.html',
    standalone: true,
    imports: [SharedModule, MatCardModule, IconComponent],
})
export class InfoCardComponent {
    icon = 'info';
    color = 'info';

    @Input()
    set type(value: 'info' | 'error' | 'warning' | 'success') {
        switch (value) {
            case 'error':
                this.icon = 'error';
                this.color = 'error';
                break;
            case 'warning':
                this.icon = 'warning';
                this.color = 'warning';
                break;
            case 'success':
                this.icon = 'check_circle';
                this.color = 'brand';
                break;
            default:
                this.icon = 'info';
                this.color = 'info';
        }
    }
}
