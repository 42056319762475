<div class="player-container" *ngIf="sitePlayer$ | async as player">
    <div class="player-header">
        <div class="player-headline">RTLS Player Controls</div>
        <div class="player-remaining">
            <div>
                <div class="font-bold mr-1">Remaining Time:</div>
                <div class="no-wrap vertical-center">{{ player.remainingTime > 1 ? (player.remainingTime * 60 | humanizeDuration) : 'none' }}</div>
            </div>
            <div>
                <div class="font-bold mr-1">Renewed In:</div>
                <div class="no-wrap vertical-center">{{ nextRenewal }} {{ nextRenewal === 1 ? 'day' : 'days' }}</div>
            </div>
        </div>
    </div>
    <div *ngIf="player.machine !== null; else notInitialized" class="player-card">
        <div class="player-credentials">
            <div class="player-name">
                <h2>{{ player.machine.id }}</h2>
                <div class="player-state ml-2 player-state-info">
                    {{ getPlayerVersion(player.machine.version) }}
                </div>
                <div class="player-state ml-2" [ngClass]="getPlayerStateClass(player.machine.state)">
                    {{ getPlayerStateText(player.machine.state) }}
                </div>
            </div>
            <div class="player-credential">
                <div class="player-credential-name mr-1">USERNAME:</div>
                <div #playerUser class="player-credential-content">{{ player.machine.user }}</div>
                <button mat-icon-button aria-label="Copy to Clipboard" [cdkCopyToClipboard]="playerUser.textContent || ''">
                    <mat-icon fontSet="material-icons-outlined" [matTooltip]="'Copy to Clipboard'">content_copy</mat-icon>
                </button>
            </div>
            <div class="player-credential">
                <div class="player-credential-name mr-1">PASSWORD:</div>
                <div #playerPassword class="player-credential-content">{{ player.machine.password }}</div>
                <button mat-icon-button aria-label="Copy to Clipboard" [cdkCopyToClipboard]="playerPassword.textContent || ''">
                    <mat-icon fontSet="material-icons-outlined" [matTooltip]="'Copy to Clipboard'">content_copy</mat-icon>
                </button>
            </div>
            <div class="player-credential">
                <div class="player-credential-name mr-1">OWNER:</div>
                <div>{{ player.machine.owner }}</div>
            </div>
            <div class="player-credential" *ngIf="player.machine.state === playerStates.RUNNING && player.machine.endTime">
                <div class="player-credential-name mr-1">STOPS AT:</div>
                <div>{{ player.machine.endTime | unixTimeToDate: 'YYYY-MM-DD HH:mm' }}</div>
            </div>
        </div>
        <div class="player-actions">
            <div class="mr-2">
                <button *ngIf="player.machine.state !== playerStates.STOPPED" [disabled]="player.machine.state !== playerStates.RUNNING" mat-fab color="primary" matTooltip="Open RTLS Player" (click)="openPlayer(player.machine.link)">
                    <mat-icon>open_in_browser</mat-icon>
                </button>
                <button *ngIf="player.machine.state === playerStates.STOPPED" [disabled]="(playerEditFeature$ | async)?.state !== 'enabled'" mat-fab color="primary" matTooltip="Start RTLS Player" (click)="openDialog({ versions: [] }, modalTypes.START, player.remainingTime)">
                    <mat-icon>play_arrow</mat-icon>
                </button>
            </div>
            <div class="player-buttons">
                <div class="player-buttons-header">OTHER ACTIONS</div>
                <div>
                    <button mat-icon-button matTooltip="Stop RTLS Player" [disabled]="player.machine.state !== playerStates.RUNNING || (playerEditFeature$ | async)?.state !== 'enabled'" (click)="updatePlayer(playerStates.STOP_REQUEST)">
                        <mat-icon class="player-button-icon">stop</mat-icon>
                    </button>
                    <button
                        mat-icon-button
                        matTooltip="Prolong RTLS Player"
                        [disabled]="player.machine.state !== playerStates.RUNNING || (playerEditFeature$ | async)?.state !== 'enabled'"
                        (click)="openDialog({ versions: [] }, modalTypes.PROLONG, player.remainingTime + player.machine.remainingTime / 60)"
                    >
                        <mat-icon class="player-button-icon">more_time</mat-icon>
                    </button>
                    <button
                        mat-icon-button
                        matTooltip="Delete RTLS Player"
                        [disabled]="!isPlayerStateActionFinished(player.machine.state) || (playerEditFeature$ | async)?.state !== 'enabled'"
                        (click)="updatePlayer(player.machine.state === playerStates.RUNNING ? playerStates.DELETE_RUNNING_REQUEST : playerStates.DELETE_STOPPED_REQUEST)"
                    >
                        <mat-icon class="player-button-icon">delete</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <ng-template #notInitialized>
        <div class="player-card">
            <div>RTLS Player is not initialized.</div>
            <div class="player-delimiter"></div>
            <div class="player-buttons">
                <button mat-fab color="primary" *ngIf="playerOptions$ | async as playerOptions" [disabled]="(playerEditFeature$ | async)?.state !== 'enabled'" matTooltip="Initialize RTLS Player" (click)="openDialog(playerOptions, modalTypes.INITIALIZE, player.remainingTime)">
                    <mat-icon>install_desktop</mat-icon>
                </button>
            </div>
        </div>
    </ng-template>
</div>
