<div
    class="flex justify-center items-center material-symbols-rounded"
    translate="no"
    [ngClass]="['text-[' + getSizeInPx(size) + ']', 'w-[' + getSizeInPx(size) + ']', 'h-[' + getSizeInPx(size) + ']']"
    [ngStyle]="{
        'font-variation-settings': '\'FILL\' ' + (fill ? '1' : '0')
    }"
>
    {{ icon }}
</div>
