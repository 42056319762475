import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { FeatureState, SiteFeature } from '@myrtls/api-interfaces';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { AppState } from '../../store';
import { $siteFeatures } from '../../store/sites/sites.selectors';
import { RoutePath } from '../models/route-path.enum';

@Injectable({ providedIn: 'root' })
export class FeaturesGuard {
    constructor(
        private readonly router: Router,
        private readonly store: Store<AppState>,
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean | Observable<boolean> {
        return this.store.pipe(
            select($siteFeatures),
            skipWhile(features => {
                return features.length === 0;
            }),
            map(features => {
                const id = state.root.firstChild?.params.id;

                if (!features || typeof id !== 'string') {
                    this.router.navigate([RoutePath.COMPANIES]);
                    return false;
                }

                if (
                    next.data.requiredFeatures === undefined ||
                    next.data.requiredFeatures.length === 0
                ) {
                    return true;
                }

                for (const requiredFeature of next.data
                    .requiredFeatures as SiteFeature[]) {
                    const feature = features.find(
                        foundFeature => foundFeature.name === requiredFeature,
                    );

                    if (feature === undefined) {
                        this.router.navigate([
                            RoutePath.SITE,
                            id,
                            SiteFeature.SITE_OVERVIEW,
                        ]);

                        return false;
                    }

                    if (
                        feature.state === FeatureState.LOCKED ||
                        feature.state === FeatureState.EXPIRED ||
                        feature.state === FeatureState.BLOCKED ||
                        feature.state === FeatureState.NOT_PERMITTED
                    ) {
                        this.router.navigate([
                            RoutePath.SITE,
                            id,
                            feature.fallback
                                ? feature.fallback
                                : SiteFeature.SITE_OVERVIEW,
                        ]);

                        return false;
                    } else if (
                        feature.state === FeatureState.OTHER_DEPLOYMENT
                    ) {
                        this.router.navigate([
                            RoutePath.SITE,
                            id,
                            SiteFeature.SITE_DEPLOYMENT,
                        ]);

                        return false;
                    }
                }

                return true;
            }),
        );
    }
}
