import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'myrtls-date-interval',
    templateUrl: './date-interval.component.html',
    styleUrls: ['./date-interval.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateIntervalComponent {
    fromDate = '';
    toDate = '';
    fromTime = '';
    toTime = '';
    fromDateDifferent = false;
    toDateDifferent = false;
    pending = false;

    @Input() set dateInterval(
        dateInterval: { from: Date; to: Date | null; date: string } | null,
    ) {
        if (dateInterval != null) {
            const from = moment(dateInterval.from);
            const date = moment(dateInterval.date);

            if (!from.isSame(date, 'day')) {
                this.fromDateDifferent = true;
            }

            this.fromDate = from.format('YYYY-MM-DD');
            this.fromTime = from.format('HH:mm:ss');

            if (dateInterval.to === null) {
                this.toTime = 'Now';
                this.pending = true;
                return;
            }

            const to = moment(dateInterval.to);

            if (!to.isSame(date, 'day')) {
                this.toDateDifferent = true;
            }

            this.toDate = to.format('YYYY-MM-DD');
            this.toTime = to.format('HH:mm:ss');
        }
    }
}
