<div class="installation-page">
    <mat-tab-group class="stepper" [(selectedIndex)]="selectedTabIndex">
        <mat-tab label="RTLS Studio 3.2.0 and newer">
            <div *ngIf="selectedTabIndex === 0">
                <mat-vertical-stepper>
                    <mat-step>
                        <ng-template matStepLabel>Establishing Connection</ng-template>
                        <p>If you have established a connection with myRTLS in <span class="code">Integrations</span> tab of RTLS Studio, the Device Care configuration will be downloaded automatically.</p>
                        <div class="form-buttons">
                            <button mat-button color="primary" matStepperNext>Next</button>
                        </div>
                    </mat-step>
                    <mat-step *ngIf="metricsStatus$ | async as metricsStatus">
                        <ng-template matStepLabel>Data Verification</ng-template>
                        <p>Once the data is loaded you will be able to activate the Device Care by pressing the Finish button.</p>
                        <br />
                        <div class="data-tests">
                            <div class="data-test">
                                System Data:
                                <mat-icon *ngIf="!metricsStatus.system" class="pending-icon" fontSet="material-icons-outlined">loop</mat-icon>
                                <mat-icon *ngIf="metricsStatus.system" class="text-success ml-3" fontSet="material-icons-outlined">done</mat-icon>
                            </div>
                            <div class="data-test">
                                RTLS Studio Data:
                                <mat-icon *ngIf="!metricsStatus.studio" class="pending-icon" fontSet="material-icons-outlined">loop</mat-icon>
                                <mat-icon *ngIf="metricsStatus.studio" class="text-success ml-3" fontSet="material-icons-outlined">done</mat-icon>
                            </div>
                        </div>
                        <br />
                        <p>If you've finished installing Device Care Data Probe and the data are still pending after a few minutes, please visit the <a target="_blank" href="https://docs.sewio.net/x/7QM5Aw">Troubleshooting</a> section or contact us.</p>
                        <div class="form-buttons">
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button color="primary" matStepperNext [disabled]="!metricsStatus.system || !metricsStatus.studio" (click)="finish()">Finish</button>
                        </div>
                    </mat-step>
                </mat-vertical-stepper>
            </div>
        </mat-tab>
        <mat-tab label="RTLS Studio 2.3.0 - 3.1.0">
            <div *ngIf="selectedTabIndex === 1">
                <mat-vertical-stepper [linear]="true">
                    <mat-step [stepControl]="installationTypeFormGroup">
                        <form [formGroup]="installationTypeFormGroup">
                            <ng-template matStepLabel>Installation Type</ng-template>
                            <p>
                                Device Care is a health monitoring service for our RTLS solution.
                                <br />
                            </p>
                            <div class="studio-type">
                                <label id="studio-installation-type">Please select the type of your RTLS Studio installation:</label>
                                <mat-radio-group class="studio-type-buttons" aria-labelledby="studio-installation-type" formControlName="studioType">
                                    <mat-radio-button class="studio-type-button" value="native">Native / Virtual Box</mat-radio-button>
                                    <mat-radio-button class="studio-type-button" value="docker">Docker (Linux)</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="form-buttons">
                                <button mat-button color="primary" matStepperNext [disabled]="!installationTypeFormGroup.valid">Next</button>
                            </div>
                        </form>
                    </mat-step>
                    <mat-step [stepControl]="installationRequirementsFormGroup">
                        <form [formGroup]="installationRequirementsFormGroup">
                            <ng-template matStepLabel>Installation Requirements</ng-template>
                            <p>Before installing Device Care Data Probe please confirm that your system meets the following requirements:</p>
                            <mat-checkbox *ngIf="installationTypeFormGroup.value.studioType === 'native'" [color]="'primary'" required formControlName="osVersion"> Ubuntu 20.04 (or 18.04) </mat-checkbox>
                            <mat-checkbox *ngIf="installationTypeFormGroup.value.studioType === 'docker'" [color]="'primary'" required formControlName="osVersion"> Linux with preinstalled Docker and Docker Compose. </mat-checkbox>
                            <br />
                            <mat-checkbox [color]="'primary'" required formControlName="rtlsStudioVersion"> RTLS Studio 2.3.0 or higher. </mat-checkbox>
                            <br />
                            <mat-checkbox *ngIf="deployment === SiteDeploymentType.CLOUD" [color]="'primary'" required formControlName="networkConnection">
                                Outgoing connection to host
                                <span class="code">https://influxdb.myrtls.com</span>
                                on port
                                <span class="code">443</span>.
                            </mat-checkbox>
                            <mat-checkbox *ngIf="deployment === SiteDeploymentType.ON_PREM" [color]="'primary'" required formControlName="networkConnection"> Connection between RTLS Studio and On-Premise myRTLS. </mat-checkbox>
                            <br />
                            <mat-checkbox [color]="'primary'" required formControlName="deviceLogsEnabled"> Enabled <span class="code">Device Logs</span> in RTLS Manager.</mat-checkbox>
                            <div class="box box-info mt-2">
                                <mat-icon class="box-icon" fontSet="material-icons-outlined">info</mat-icon>
                                <div>If you are not sure, please follow our <a href="https://docs.sewio.net/x/0wM5Aw">guide</a> on how to check if these requirements are met.</div>
                            </div>
                            <div class="form-buttons">
                                <button mat-button color="primary" matStepperNext [disabled]="!installationRequirementsFormGroup.valid">Next</button>
                            </div>
                        </form>
                    </mat-step>
                    <mat-step *ngIf="installationTypeFormGroup.value.studioType === 'docker'">
                        <ng-template matStepLabel>Install Device Care Data Probe</ng-template>
                        <p>Device Care Data Probe in the current state has to be installed manually using an installation script. The script will update the existing Docker configuration and then set up all the necessary packages.</p>
                        <p class="mt-4">Navigate to the folder with your Docker version of RTLS Studio:</p>
                        <div class="terminal-line">
                            <div #t1 class="terminal">cd &lt;/path/to/your/docker/rtls-studio/folder&gt;</div>
                            <button mat-icon-button aria-label="Copy to Clipboard" [cdkCopyToClipboard]="t1.textContent || ''">
                                <mat-icon fontSet="material-icons-outlined" [matTooltip]="'Copy to Clipboard'">content_copy</mat-icon>
                            </button>
                        </div>
                        <p>Request the installer from our support team and extract it to the folder from previous step:</p>
                        <div class="terminal-line">
                            <div #t3 class="terminal">unzip -o device-care-installer.zip</div>
                            <button mat-icon-button aria-label="Copy to Clipboard" [cdkCopyToClipboard]="t3.textContent || ''">
                                <mat-icon fontSet="material-icons-outlined" [matTooltip]="'Copy to Clipboard'">content_copy</mat-icon>
                            </button>
                        </div>
                        <p>Remove the unnecessary files:</p>
                        <div class="terminal-line">
                            <div #t5 class="terminal">rm -rf device-care-installer device-care-installer.zip</div>
                            <button mat-icon-button aria-label="Copy to Clipboard" [cdkCopyToClipboard]="t5.textContent || ''">
                                <mat-icon fontSet="material-icons-outlined" [matTooltip]="'Copy to Clipboard'">content_copy</mat-icon>
                            </button>
                        </div>
                        <p>Run the installation script:</p>
                        <div class="terminal-line">
                            <div #t6 class="terminal">sudo sh ./install.sh</div>
                            <button mat-icon-button aria-label="Copy to Clipboard" [cdkCopyToClipboard]="t6.textContent || ''">
                                <mat-icon fontSet="material-icons-outlined" [matTooltip]="'Copy to Clipboard'">content_copy</mat-icon>
                            </button>
                        </div>
                        <div class="box text-warning border-warning mb-2">
                            <mat-icon class="box-icon" fontSet="material-icons-outlined">warning</mat-icon>
                            <div>The Device Care Data Probe installer uses <a target="_blank" href="https://command-not-found.com/whiptail">whiptail</a> to make the installation experience smoother. Please install it if it is not already installed on your system.</div>
                        </div>
                        <div class="box text-warning border-warning mb-2">
                            <mat-icon class="box-icon" fontSet="material-icons-outlined">warning</mat-icon>
                            <div>This operation will replace your <span class="code">docker-compose.core.yml</span> with a new version. If you made any changes to the original file, you have to make them again. The old file is saved as <span class="code">docker-compose.core.old.XXX.yml</span></div>
                        </div>
                        <br />
                        <p>Follow the instructions of the installation script. During the installation, you will be prompted for the following credentials:</p>
                        <div class="terminal-line">
                            <div class="terminal-header">Site ID:</div>
                            <ng-container *ngIf="deviceCareInstallation$ | async as installation; else installationLoading5">
                                <div #t7 class="terminal">{{ installation.bucket }}</div>
                                <button mat-icon-button aria-label="Copy to Clipboard" [cdkCopyToClipboard]="t7.textContent || ''">
                                    <mat-icon fontSet="material-icons-outlined" [matTooltip]="'Copy to Clipboard'">content_copy</mat-icon>
                                </button>
                            </ng-container>
                            <ng-template #installationLoading5>
                                <div class="terminal no-permissions">INSUFFICIENT PERMISSIONS</div>
                                <div class="empty-space"></div>
                            </ng-template>
                        </div>
                        <div class="terminal-line">
                            <div class="terminal-header">Site Password:</div>
                            <ng-container *ngIf="deviceCareInstallation$ | async as installation; else installationLoading4">
                                <div #t8 class="terminal">{{ installation.writeToken }}</div>
                                <button mat-icon-button aria-label="Copy to Clipboard" [cdkCopyToClipboard]="t8.textContent || ''">
                                    <mat-icon fontSet="material-icons-outlined" [matTooltip]="'Copy to Clipboard'">content_copy</mat-icon>
                                </button>
                            </ng-container>
                            <ng-template #installationLoading4>
                                <div class="terminal no-permissions">INSUFFICIENT PERMISSIONS</div>
                                <div class="empty-space"></div>
                            </ng-template>
                        </div>
                        <div class="terminal-line">
                            <div class="terminal-header">Proxy:</div>
                            <div>Provide the installation with your proxy URL if needed (leave blank to skip).</div>
                        </div>
                        <div class="box text-warning border-warning mb-2">
                            <mat-icon class="box-icon" fontSet="material-icons-outlined">warning</mat-icon>
                            <div>These credentials are unique for your installation, and should not be shared with anyone.</div>
                        </div>
                        <br />
                        <p>Once the installation is complete, the script will terminate. The Device Care Data Probe should be running at this point. Go to the next step to validate incoming data.</p>
                        <div class="form-buttons">
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button color="primary" matStepperNext>Next</button>
                        </div>
                    </mat-step>
                    <mat-step *ngIf="installationTypeFormGroup.value.studioType === 'native'">
                        <ng-template matStepLabel>Install Device Care Data Probe</ng-template>
                        <p>Device Care Data Probe in the current state has to be installed manually using an installation script. The script will first install Docker (if not already installed) and then set up all the necessary packages.</p>
                        <p class="mt-4">Request the installer from our support team and move it to <span class="code">/home/sewiortls/</span> on the server with RTLS Studio:</p>
                        <p class="mt-4">Extract the downloaded zip file:</p>
                        <div class="terminal-line">
                            <div #t2 class="terminal">unzip -d /home/sewiortls /home/sewiortls/device-care-installer.zip</div>
                            <button mat-icon-button aria-label="Copy to Clipboard" [cdkCopyToClipboard]="t2.textContent || ''">
                                <mat-icon fontSet="material-icons-outlined" [matTooltip]="'Copy to Clipboard'">content_copy</mat-icon>
                            </button>
                        </div>
                        <p>Run the installation script from the installation directory:</p>
                        <div class="terminal-line">
                            <div #t6 class="terminal">cd /home/sewiortls/device-care-installer</div>
                            <button mat-icon-button aria-label="Copy to Clipboard" [cdkCopyToClipboard]="t6.textContent || ''">
                                <mat-icon fontSet="material-icons-outlined" [matTooltip]="'Copy to Clipboard'">content_copy</mat-icon>
                            </button>
                        </div>
                        <div class="terminal-line">
                            <div #t3 class="terminal">sudo sh ./install.sh</div>
                            <button mat-icon-button aria-label="Copy to Clipboard" [cdkCopyToClipboard]="t3.textContent || ''">
                                <mat-icon fontSet="material-icons-outlined" [matTooltip]="'Copy to Clipboard'">content_copy</mat-icon>
                            </button>
                        </div>
                        <br />
                        <p>Follow the instructions of the installation script. During the installation, you will be prompted for the following credentials:</p>
                        <div class="terminal-line">
                            <div class="terminal-header">Site ID:</div>
                            <ng-container *ngIf="deviceCareInstallation$ | async as installation; else installationLoading6">
                                <div #t4 class="terminal">{{ installation.bucket }}</div>
                                <button mat-icon-button aria-label="Copy to Clipboard" [cdkCopyToClipboard]="t4.textContent || ''">
                                    <mat-icon fontSet="material-icons-outlined" [matTooltip]="'Copy to Clipboard'">content_copy</mat-icon>
                                </button>
                            </ng-container>
                            <ng-template #installationLoading6>
                                <div class="terminal no-permissions">INSUFFICIENT PERMISSIONS</div>
                                <div class="empty-space"></div>
                            </ng-template>
                        </div>
                        <div class="terminal-line">
                            <div class="terminal-header">Site Password:</div>
                            <ng-container *ngIf="deviceCareInstallation$ | async as installation; else installationLoading4">
                                <div #t5 class="terminal">{{ installation.writeToken }}</div>
                                <button mat-icon-button aria-label="Copy to Clipboard" [cdkCopyToClipboard]="t5.textContent || ''">
                                    <mat-icon fontSet="material-icons-outlined" [matTooltip]="'Copy to Clipboard'">content_copy</mat-icon>
                                </button>
                            </ng-container>
                            <ng-template #installationLoading4>
                                <div class="terminal no-permissions">INSUFFICIENT PERMISSIONS</div>
                                <div class="empty-space"></div>
                            </ng-template>
                        </div>
                        <div class="terminal-line">
                            <div class="terminal-header">Proxy:</div>
                            <div>Provide the installation with your proxy URL if needed (leave blank to skip).</div>
                        </div>
                        <div class="box text-warning border-warning mb-2">
                            <mat-icon class="box-icon" fontSet="material-icons-outlined">warning</mat-icon>
                            <div>These credentials are unique for your installation, and should not be shared with anyone.</div>
                        </div>
                        <br />
                        <p>Once the installation is complete, the script will terminate. The Device Care Data Probe should be running at this point. Go to the next step to validate incoming data.</p>
                        <div class="form-buttons">
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button color="primary" matStepperNext>Next</button>
                        </div>
                    </mat-step>
                    <mat-step *ngIf="metricsStatus$ | async as metricsStatus">
                        <ng-template matStepLabel>Data Verification</ng-template>
                        <p>Once the data is loaded you will be able to activate the Device Care by pressing the Finish button.</p>
                        <br />
                        <div class="data-tests">
                            <div class="data-test">
                                System Data:
                                <mat-icon *ngIf="!metricsStatus.system" class="pending-icon" fontSet="material-icons-outlined">loop</mat-icon>
                                <mat-icon *ngIf="metricsStatus.system" class="text-success ml-3" fontSet="material-icons-outlined">done</mat-icon>
                            </div>
                            <div class="data-test">
                                RTLS Studio Data:
                                <mat-icon *ngIf="!metricsStatus.studio" class="pending-icon" fontSet="material-icons-outlined">loop</mat-icon>
                                <mat-icon *ngIf="metricsStatus.studio" class="text-success ml-3" fontSet="material-icons-outlined">done</mat-icon>
                            </div>
                        </div>
                        <br />
                        <p>If you've finished installing Device Care Data Probe and the data are still pending after a few minutes, please visit the <a target="_blank" href="https://docs.sewio.net/x/7QM5Aw">Troubleshooting</a> section or contact us.</p>
                        <div class="form-buttons">
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button color="primary" matStepperNext [disabled]="!metricsStatus.system || !metricsStatus.studio" (click)="finish()">Finish</button>
                        </div>
                    </mat-step>
                </mat-vertical-stepper>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
