import { NgModule } from '@angular/core';
import { AdvancedAnalyticsComponent } from './advanced-analytics.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
    declarations: [AdvancedAnalyticsComponent],
    exports: [AdvancedAnalyticsComponent],
    imports: [SharedModule],
})
export class AdvancedAnalyticsModule {}
