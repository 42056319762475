import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { FeatureState, SiteDeploymentType } from '@myrtls/api-interfaces';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { from, Observable, Subject } from 'rxjs';
import {
    bufferTime,
    delay,
    distinctUntilChanged,
    filter,
    map,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { Container, Engine } from 'tsparticles-engine';
import { environment } from '../environments/environment';
import { OnPremAuthService } from './core/modules/auth/on-prem-auth.service';
import { MenuItem } from './shared/models/menu-item.model';
import { RoutePath } from './shared/models/route-path.enum';
import { SnackBarType } from './shared/models/snack-bar-config.model';
import {
    Medium,
    NUMBER_OF_DAYS_TO_START_EXPIRING,
    PARTICLES_OPTIONS,
    Small,
    XSmall,
} from './shared/utils/constants';
import { isBeforeNDaysFromTheDate, isInThePast } from './shared/utils/utils';
import { AppState } from './store';
import { $selectedCompanyName } from './store/companies/companies.selectors';
import { $hidePanels } from './store/route/route.selectors';
import {
    $currentLicense,
    $selectedSiteName,
    $smtpStatus,
    $version,
} from './store/sites/sites.selectors';
import { showSnackBarAction } from './store/snack-bar/snack-bar.actions';
import { $menuCategories, $menuItems } from './store/ui/ui.selectors';
import { loadFull } from 'tsparticles';

const SIDE_MENU_EXPANDED_LS_KEY = 'myrtls_menu_expanded';

@Component({
    selector: 'myrtls-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
    private readonly unsubscribe$ = new Subject<void>();
    private readonly deploymentClicked$ = new Subject<MouseEvent>();

    particlesOptions = PARTICLES_OPTIONS;
    showEasterEgg = false;
    versionRegexp = /\d{4}\.\d{2}/;

    readonly menuItems$: Observable<MenuItem[]> = this.store.pipe(
        select($menuItems),
    );

    readonly menuCategories$ = this.store.pipe(select($menuCategories));

    readonly version$ = this.store.pipe(select($version));

    readonly selectedCompanyName$ = this.store.pipe(
        select($selectedCompanyName),
    );

    readonly selectedSiteName$ = this.store.pipe(select($selectedSiteName));

    readonly hidePanels$: Observable<boolean | undefined> = this.store.pipe(
        select($hidePanels),
    );

    readonly currentLicense$ = this.store.select($currentLicense);

    readonly smtpStatus$ = this.store.select($smtpStatus);

    readonly user$;

    production = environment.production;
    menuExpanded: boolean =
        localStorage.getItem(SIDE_MENU_EXPANDED_LS_KEY) != 'false' &&
        !this.breakpointObserver.isMatched(Small);

    isMenuOpen = !this.breakpointObserver.isMatched(XSmall);
    menuHidden = false;
    previousBreakpoint: string | undefined;

    private expirationNotification = false;
    private smtpNotification = false;

    readonly isMobile$: Observable<boolean> = this.breakpointObserver
        .observe([XSmall])
        .pipe(map(state => state.matches));

    FeatureState = FeatureState;
    onprem = environment.deployment === SiteDeploymentType.ON_PREM;

    particlesContainer?: Container;

    constructor(
        @Optional()
        private readonly cloudAuth: AuthService,
        @Optional() private readonly onPremAuth: OnPremAuthService,
        private readonly store: Store<AppState>,
        private readonly breakpointObserver: BreakpointObserver,
        private readonly router: Router,
    ) {
        if (this.cloudAuth) {
            this.user$ = this.cloudAuth.user$;
        } else if (this.onPremAuth) {
            this.user$ = from(this.onPremAuth.getUser());
        } else {
            throw new Error('');
        }

        this.breakpointObserver
            .observe([XSmall, Small])
            .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
            .subscribe(state => {
                if (state.breakpoints[XSmall]) {
                    // Mobile
                    this.menuHidden = true;
                    this.menuExpanded = true;
                    this.isMenuOpen = false;
                    this.previousBreakpoint = XSmall;
                    setTimeout(() => (this.menuHidden = false), 500);
                } else if (state.breakpoints[Small]) {
                    // Tablet
                    this.menuExpanded = false;
                    this.isMenuOpen = true;
                    if (this.previousBreakpoint === XSmall) {
                        this.menuHidden = true;
                        setTimeout(() => (this.menuHidden = false), 5);
                    }
                    this.previousBreakpoint = Small;
                } else {
                    // Web
                    this.menuExpanded =
                        localStorage.getItem(SIDE_MENU_EXPANDED_LS_KEY) !=
                        'false';
                    this.isMenuOpen = true;
                    this.previousBreakpoint = Medium;
                }
            });

        this.smtpStatus$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(smtpStatus => {
                if (
                    smtpStatus !== null &&
                    smtpStatus.enabled === false &&
                    this.smtpNotification === false
                ) {
                    this.smtpNotification = true;
                    this.store.dispatch(
                        showSnackBarAction({
                            config: {
                                description: `SMTP is not properly configured. Alerts / Reports are not sent and new users cannot be invited.`,
                                type: SnackBarType.WARNING,
                                actionLabel: 'Dismiss',
                            },
                        }),
                    );
                }
            });

        this.deploymentClicked$
            .pipe(
                takeUntil(this.unsubscribe$),
                bufferTime(2000),
                filter(event => event.length >= 5),
                tap(() => {
                    if (!this.showEasterEgg) {
                        this.showEasterEgg = true;
                    }
                }),
                delay(10000),
                tap(() => {
                    if (this.showEasterEgg) {
                        this.particlesContainer?.destroy();
                        this.showEasterEgg = false;
                    }
                }),
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    async logOut() {
        if (this.cloudAuth) {
            this.cloudAuth.logout({
                logoutParams: {
                    federated: true,
                },
            });
        } else if (this.onPremAuth) {
            await this.onPremAuth.logOut();
            this.router.navigate([RoutePath.AUTH, RoutePath.LOGIN]);
        }
    }

    toggleMenuExpand(): void {
        this.menuExpanded = !this.menuExpanded;
        localStorage.setItem(SIDE_MENU_EXPANDED_LS_KEY, `${this.menuExpanded}`);
    }

    toggleMenu(): void {
        this.isMenuOpen = !this.isMenuOpen;
    }

    closeMenuOnMobile(): void {
        if (this.breakpointObserver.isMatched(XSmall)) {
            this.isMenuOpen = false;
        }
    }

    menuItemClick(event: Event, menuItem: MenuItem): void {
        if (menuItem.state !== FeatureState.ENABLED) {
            return;
        }

        if (menuItem.external) {
            this.redirect(menuItem.link);
        } else {
            this.closeMenuOnMobile();
        }
    }

    iconClick(event: Event, disabled?: boolean): void {
        if (disabled) {
            event.stopPropagation();
        }
    }

    redirect(link: string): void {
        window.open(link, '_blank');
    }

    isLicenseExpired(expiration: number) {
        return isInThePast(expiration);
    }

    isLicenseExpiring(expiration: number, isMenuHidden?: boolean | null) {
        if (
            isBeforeNDaysFromTheDate(
                expiration,
                NUMBER_OF_DAYS_TO_START_EXPIRING,
            ) ||
            isInThePast(expiration)
        ) {
            return false;
        }

        if (!this.expirationNotification && !isMenuHidden) {
            this.expirationNotification = true;
            this.store.dispatch(
                showSnackBarAction({
                    config: {
                        description: `Your myRTLS license will expire in ${moment
                            .unix(expiration)
                            .diff(moment(), 'days')} days.`,
                        type: SnackBarType.WARNING,
                        actionLabel: 'Dismiss',
                    },
                }),
            );
        }

        return true;
    }

    deploymentClicked(event: MouseEvent) {
        this.deploymentClicked$.next(event);
    }

    async particlesInit(engine: Engine): Promise<void> {
        await loadFull(engine);
    }

    particlesLoaded(container: Container): void {
        this.particlesContainer = container;
    }
}
