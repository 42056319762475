import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateIntervalComponent } from './date-interval.component';

@NgModule({
    declarations: [DateIntervalComponent],
    imports: [CommonModule],
    exports: [DateIntervalComponent],
})
export class DateIntervalModule {}
