import { ActionReducerMap } from '@ngrx/store';
import { CompaniesEffects } from './companies/companies.effects';
import { ErrorsEffects } from './errors/errors.effects';
import { PermissionsEffects } from './permissions/permissions.effects';
import {
    permissionsReducer,
    PermissionsState,
} from './permissions/permissions.reducer';
import { RouteEffects } from './route/route.effects';
import { SitesEffects } from './sites/sites.effects';
import {
    companiesReducer,
    CompaniesState,
} from './companies/companies.reducer';
import { routeReducer, RouteState } from './route/route.reducer';
import { sitesReducer, SitesState } from './sites/sites.reducer';
import { SnackBarEffects } from './snack-bar/snack-bar.effects';

export const effects = [
    CompaniesEffects,
    SitesEffects,
    RouteEffects,
    PermissionsEffects,
    ErrorsEffects,
    SnackBarEffects,
];

export interface AppState {
    companies: CompaniesState;
    sites: SitesState;
    route: RouteState;
    permissions: PermissionsState;
}

export const reducers: ActionReducerMap<AppState> = {
    companies: companiesReducer,
    sites: sitesReducer,
    route: routeReducer,
    permissions: permissionsReducer,
};
