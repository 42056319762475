import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteComponent } from './site.component';

@NgModule({
    declarations: [SiteComponent],
    exports: [SiteComponent],
    imports: [CommonModule],
})
export class SiteModule {}
