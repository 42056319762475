<div class="flex flex-col p-5 container" *ngIf="permissionGroups$ | async as permissionGroups">
    <div class="table-container" *ngIf="users$ | async as users">
        <div class="search-container">
            <mat-form-field class="search-input">
                <mat-label>Search User</mat-label>
                <input matInput (keyup)="applyFilter($event)" #input />
                <button matSuffix mat-icon-button aria-label="Search">
                    <mat-icon>search</mat-icon>
                </button>
            </mat-form-field>
            <button *ngIf="smtpStatus$ | async as smtpStatus" color="primary" class="invite-button" [disabled]="smtpStatus === null || smtpStatus.enabled !== true || (editUsersFeature$ | async)?.state !== 'enabled'" mat-stroked-button (click)="openDialog(permissionGroups)">Invite User</button>
        </div>

        <div class="header-title">Site Users</div>
        <div class="users-card">
            <table mat-table [dataSource]="users">
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td class="user-email-column" mat-cell *matCellDef="let user">
                        <div class="user-email">
                            {{ user.email }}<span class="user-role">({{ computeUserRole(user, permissionGroups) }})</span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let user" class="edit-label">
                        <ng-container *ngIf="currentUser$ | async as currentUser">
                            <button *ngIf="user.isAdmin !== true && (currentUser['https://sewio.net/email'] || currentUser.email) !== user.email && (editUsersFeature$ | async)?.state === 'enabled'" mat-button class="edit-button" (click)="editUser(permissionGroups, user)">Edit</button>
                        </ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let user" class="delete-column">
                        <ng-container *ngIf="currentUser$ | async as currentUser">
                            <button *ngIf="user.isAdmin !== true && (currentUser['https://sewio.net/email'] || currentUser.email) !== user.email && (editUsersFeature$ | async)?.state === 'enabled'" class="delete-button" mat-button (click)="deleteUser(user.id)">Delete</button>
                        </ng-container>
                    </td>
                </ng-container>

                <tr mat-row *matRowDef="let user; columns: displayedColumns"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">{{ input.value.length === 0 ? 'No users found.' : 'No users matching the filter.' }}</td>
                </tr>
            </table>
        </div>
    </div>
</div>
