<div mat-dialog-title class="title">{{ getDialogTitle(type) }}</div>
<div mat-dialog-content>
    <div class="form">
        <mat-form-field *ngIf="type === modalTypes.INITIALIZE">
            <mat-label>Version</mat-label>
            <mat-select #typeSelect [formControl]="typeValidator" required>
                <mat-option *ngFor="let playerType of types" [value]="playerType">
                    {{ playerType }}
                </mat-option>
            </mat-select>
            <mat-hint align="start" class="hint"> RTLS Player is backwards compatible, meaning that newer version of RTLS Player can play captures made on older versions of RTLS Studios. </mat-hint>
        </mat-form-field>
        <div class="pr-3 mt-4">
            <div class="mb-2">Hours Available</div>
            <mat-slider class="w-full" [max]="maxTime" [min]="10" [step]="10" showTickMarks discrete [displayWith]="formatMinutes" (change)="onSliderChange()">
                <input matSliderThumb [(ngModel)]="value" />
            </mat-slider>
            <div class="columns">
                <div>Will be stopped at:</div>
                <div>{{ endTime }}</div>
            </div>
        </div>
    </div>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>CANCEL</button>
    <button mat-button color="primary" cdkFocusInitial (click)="create()">{{ getConfirmButtonText(type) }}</button>
</mat-dialog-actions>
