import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterByProperty' })
export class FilterByPropertyPipe<T> implements PipeTransform {
    transform(array: T[], property: string, value: unknown): T[] {
        const filtered = array.filter(
            item =>
                typeof item === 'object' &&
                (item as unknown as { [key: string]: unknown })[property] !==
                    undefined &&
                (item as unknown as { [key: string]: unknown })[property] ===
                    value,
        );

        return filtered;
    }
}
