import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'alertDateToString' })
export class AlertDateToStringPipe implements PipeTransform {
    transform(date: Date | null): string {
        return date === null
            ? 'Now'
            : moment(date).format('YYYY-MM-DD HH:mm:ss');
    }
}
