import { Component } from '@angular/core';
import { InfluxdbService } from '../../core/services/influxdb.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import {
    $deviceCareConfig,
    $metricWithData,
} from '../../store/sites/sites.selectors';
import { filter, withLatestFrom } from 'rxjs';

interface StorageData {
    free: number;
    total: number;
    prefix: string;
    value: number;
}
@Component({
    selector: 'myrtls-hdd-v1-modal',
    templateUrl: './hdd-v1.modal.html',
    styleUrls: [],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class HddV1Modal {
    metric = 'hdd' as const;

    freeStorage = '';
    totalStorage = '';
    warn = false;
    loading = true;

    private storageData: StorageData = {
        free: 0,
        total: 0,
        prefix: '',
        value: 0,
    };

    private hddQuery = `from(bucket: "$BUCKET")
    |> range(start: -1h)
    |> filter(fn: (r) => r["_field"] == "free" or r["_field"] == "total")
    |> filter(fn: (r) => r["path"] == "/")
    |> last()`;

    constructor(
        private store: Store<AppState>,
        private influxdbService: InfluxdbService,
    ) {
        this.store
            .select($deviceCareConfig)
            .pipe(
                filter(config => !!config),
                withLatestFrom(this.store.select($metricWithData(this.metric))),
            )
            .subscribe(async ([config, metric]) => {
                if (!config || !metric) return;

                const abbreviation =
                    metric.dashboardTemplate?.parameters.abbreviation;
                if (abbreviation) {
                    this.storageData.prefix = abbreviation.prefix;
                    this.storageData.value = abbreviation.value;
                }

                await this.influxdbService
                    .getDashboardChartData({
                        apiUrl: config.influx.host,
                        query: this.hddQuery,
                        org: config.influx.organization,
                        token: config.influx.readToken,
                        bucket: config.influx.bucket,
                        overrides:
                            metric.overrides?.map(override => override.value) ||
                            [],
                    })
                    .then(data => {
                        data.forEach(param => {
                            if (param._field && param._value) {
                                if (param._field === 'free') {
                                    this.storageData.free = param._value;
                                }
                                if (param._field === 'total') {
                                    this.storageData.total = param._value;
                                }
                            }
                        });
                    });

                if (metric.thresholdValue > this.storageData.free) {
                    this.warn = true;
                }

                this.freeStorage = this.getFreeStorage();
                this.totalStorage = this.getTotalStorage();
                this.loading = false;
            });
    }

    private getFreeStorage(): string {
        return (
            String(
                (this.storageData.free / this.storageData.value).toFixed(0),
            ) +
            ' ' +
            this.storageData.prefix +
            'B / ' +
            ((this.storageData.free * 100) / this.storageData.total).toFixed(
                0,
            ) +
            '%'
        );
    }

    private getTotalStorage(): string {
        return (
            String(
                (this.storageData.total / this.storageData.value).toFixed(0),
            ) +
            ' ' +
            this.storageData.prefix +
            'B'
        );
    }
}
