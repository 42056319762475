<div class="flex w-full rounded-md overflow-hidden">
    <div
        *ngFor="let state of groupedData"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
        class="h-4"
        [ngStyle]="{ 'flex-grow': state.count }"
        [ngClass]="state.value !== null ? 'bg-' + colorMapping[state.value] : 'bg-' + colorMapping.default"
        (mouseenter)="hovered = state.id"
        (mouseleave)="hovered = null"
    >
        <ng-template
            *ngIf="state.id === hovered"
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="true"
            [cdkConnectedOverlayPositions]="[
                {
                    originX: 'center',
                    originY: 'bottom',
                    overlayX: 'center',
                    overlayY: 'top',
                },
            ]"
        >
            <div class="mt-2 p-2 rounded-md shadow-sm text-sm text-dark-50 bg-dark-700">{{ state.from }} -<br />{{ state.to }}</div>
        </ng-template>
    </div>
</div>
