import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PermissionGroup } from '@myrtls/api-interfaces';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PERMISSIONS_API_URL } from '../../shared/utils/constants';
import { getPermissionsFailureAction, getPermissionsRequestAction, getPermissionsSuccessAction } from './permissions.actions';

@Injectable()
export class PermissionsEffects {
    getPermissions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getPermissionsRequestAction),
            switchMap(() =>
                this.http.get<PermissionGroup[]>(PERMISSIONS_API_URL).pipe(
                    map(groups => getPermissionsSuccessAction({groups}),),
                    catchError(error => of(getPermissionsFailureAction({error})))
                )
            )
        ));

    constructor(private readonly actions$: Actions,
                private readonly http: HttpClient) {
    }
}
