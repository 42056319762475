<h2 mat-dialog-title class="dialog-title">Delete Device Care Data</h2>
<form [formGroup]="clearDeviceCareForm">
    <div mat-dialog-content>
        <div class="mb-2">What parts of Device Care do you want to delete?</div>
        <mat-checkbox formControlName="alerts" color="primary">Delete Alert History</mat-checkbox>
        <mat-checkbox formControlName="database" color="primary">Delete All Metrics Data</mat-checkbox>
        <div class="mt-2">It may take a few minutes to delete all the data.</div>
        <div class="mt-2 text-warning">This operation cannot be undone!</div>
    </div>

    <div class="footer-wrapper" mat-dialog-actions>
        <button mat-button mat-dialog-close>CANCEL</button>
        <button color="warn" mat-button (click)="clearData()" [disabled]="!clearDeviceCareForm.valid">DELETE</button>
    </div>
</form>
