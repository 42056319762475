import {
    AbstractControl,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';

export function requireCheckboxesToBeCheckedValidator(
    minRequired = 1,
): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let checked = 0;

        if (!(control instanceof UntypedFormGroup)) {
            return {
                requireCheckboxesToBeChecked: true,
            };
        }

        Object.keys(control.controls).forEach(key => {
            const currentControl = control.controls[key];

            if (currentControl && currentControl.value === true) {
                checked++;
            }
        });

        if (checked < minRequired) {
            return {
                requireCheckboxesToBeChecked: true,
            };
        }

        return null;
    };
}
