import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';
import { DateRange } from '../../shared/models/date-range.enum';
import { DEFAULT_PERIOD } from '../../shared/utils/constants';

const DATE_RANGE_DAY_FORMAT = 'DD/MM/YYYY';
const DATE_RANGE_MONTH_FORMAT = 'MMMM YYYY';
const DATE_RANGE_YEAR_FORMAT = 'YYYY';

@Component({
    selector: 'myrtls-custom-date-picker',
    templateUrl: './custom-date-picker.component.html',
    styleUrls: ['./custom-date-picker.component.scss'],
})
export class CustomDatePickerComponent {
    @Output() periodSelectionChange = new EventEmitter<moment.Moment>();

    @Input() disabled = true;

    currentDate = moment();
    dateRange: DateRange = DEFAULT_PERIOD.dateRange;

    get periodLabel(): string | null {
        switch (this.dateRange) {
            case DateRange.DAY:
                return this.currentDate.format(DATE_RANGE_DAY_FORMAT);
            case DateRange.WEEK:
                return `${this.currentDate
                    .startOf('isoWeek')
                    .format(DATE_RANGE_DAY_FORMAT)} - ${moment(this.currentDate)
                    .endOf('isoWeek')
                    .format(DATE_RANGE_DAY_FORMAT)}`;
            case DateRange.MONTH:
                return this.currentDate.format(DATE_RANGE_MONTH_FORMAT);
            case DateRange.YEAR:
                return this.currentDate.format(DATE_RANGE_YEAR_FORMAT);
            default:
                return null;
        }
    }

    setPeriod(dateRange?: DateRange, date?: moment.Moment, update = true) {
        if (dateRange) {
            this.dateRange = dateRange;
            this.currentDate = moment();
        }

        if (date) {
            this.currentDate = date;
        }

        if (update) {
            this.changeSelectedPeriod();
        }
    }

    previousPeriod(dateRange: DateRange): void {
        switch (dateRange) {
            case DateRange.DAY:
                this.currentDate.subtract(1, 'days');
                break;
            case DateRange.WEEK:
                this.currentDate.startOf('isoWeek').subtract(1, 'weeks');
                break;
            case DateRange.MONTH:
                this.currentDate.subtract(1, 'months');
                break;
            case DateRange.YEAR: {
                this.currentDate.subtract(1, 'years');
                break;
            }
        }
        this.changeSelectedPeriod();
    }

    nextPeriod(dateRange: DateRange): void {
        switch (dateRange) {
            case DateRange.DAY:
                this.currentDate.add(1, 'days');
                break;
            case DateRange.WEEK:
                this.currentDate.startOf('isoWeek').add(1, 'weeks');
                break;
            case DateRange.MONTH:
                this.currentDate.add(1, 'months');
                break;
            case DateRange.YEAR: {
                this.currentDate.add(1, 'years');
                break;
            }
        }
        this.changeSelectedPeriod();
    }

    changeSelectedPeriod(): void {
        this.periodSelectionChange.emit(this.currentDate);
    }
}
