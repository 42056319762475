import {
    Component,
    ElementRef,
    OnDestroy,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { EChartsOption } from 'echarts';
import * as moment from 'moment';
import { Observable, Subject, takeUntil } from 'rxjs';
import { NUMBER_OF_DAYS_TO_START_EXPIRING } from '../../shared/utils/constants';
import {
    getFilenameFromDateRange,
    isBeforeNDaysFromTheDate,
    isInThePast,
} from '../../shared/utils/utils';
import { AppState } from '../../store';
import { $selectedCompanyName } from '../../store/companies/companies.selectors';
import {
    getSiteAlertsRequestAction,
    setAlertsOnly,
    setIsReport,
} from '../../store/sites/sites.actions';
import {
    $chartOptions,
    $currentLicense,
    $dashboardSingleStatData,
    $dashboardStackedChartData,
    $selectedPeriod,
    $selectedSiteName,
    $siteReportMetrics,
    TableOptions,
    $sitePendingAlerts,
} from '../../store/sites/sites.selectors';
import {
    DashboardSingleStatValueModel,
    DashboardRatioBarValueModel,
} from '../../shared/models/dashboard-value.model';
import { AlertInterval } from '@myrtls/api-interfaces';

@Component({
    selector: 'myrtls-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnDestroy {
    columns: string[] = ['metric', 'metadata', 'from'];

    private sumTables = 0;
    public pagesCount = 0;
    public paginatedData: AlertInterval[][] = [];

    private readonly unsubscribe$ = new Subject<void>();
    private reportRenderingSubject$ = new Subject<void>();

    readonly pendingAlerts$: Observable<AlertInterval[] | null> =
        this.store.pipe(select($sitePendingAlerts));

    readonly singleStatWidgetData$: Observable<
        DashboardSingleStatValueModel[]
    > = this.store.pipe(select($dashboardSingleStatData));

    readonly stackedChartData$: Observable<DashboardRatioBarValueModel[]> =
        this.store.pipe(select($dashboardStackedChartData));

    readonly siteReportMetrics$ = this.store.pipe(select($siteReportMetrics));
    readonly selectedSiteName$ = this.store.pipe(select($selectedSiteName));
    readonly selectedCompanyName$ = this.store.pipe(
        select($selectedCompanyName),
    );

    readonly currentLicense$ = this.store.select($currentLicense);

    renderedCharts: string[] = [];
    downloadedMetrics: (string | false)[] = [];

    readonly selectedPeriod$ = this.store.pipe(select($selectedPeriod));
    readonly chartOptions$ = this.store.pipe(
        select($chartOptions(false, true)),
    );

    readonly getFilenameFromDateRange = getFilenameFromDateRange;

    metricsLoaded = false;
    alertsLoaded = false;

    Array = Array;

    @ViewChildren('table') set tables(
        content: QueryList<ElementRef<HTMLDivElement>>,
    ) {
        for (const element of content) {
            const metricId = element.nativeElement.getAttribute('metricId');
            const reportMetricsString =
                element.nativeElement.getAttribute('reportMetrics');

            if (!metricId || !reportMetricsString) {
                continue;
            }

            const reportMetrics = reportMetricsString.split(',');

            if (!this.renderedCharts.includes(metricId)) {
                this.renderedCharts.push(metricId);
            }

            if (this.renderedCharts.length === reportMetrics.length) {
                this.reportRenderingSubject$.next();
            }
        }
    }

    constructor(private readonly store: Store<AppState>) {
        this.store.dispatch(setAlertsOnly({ alertsOnly: true }));
        this.store.dispatch(setIsReport({ isReport: true }));
        this.store.dispatch(getSiteAlertsRequestAction());

        this.listenOnDownload();

        this.pendingAlerts$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(data => {
                if (data === null) {
                    return;
                }

                this.makeChunksOfData(data, 10);
                this.alertsLoaded = true;
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    makeChunksOfData(data: AlertInterval[], size: number) {
        this.paginatedData = [];

        for (let i = 0; i < data.length; i += size) {
            const chunk = data.slice(i, i + size);
            this.paginatedData.push(chunk);
        }

        this.pagesCount = this.paginatedData.length;
    }

    onChartRendered(metricId: string, reportMetrics: string[]) {
        if (!this.renderedCharts.includes(metricId)) {
            this.renderedCharts.push(metricId);
        }

        if (this.renderedCharts.length === reportMetrics.length) {
            this.reportRenderingSubject$.next();
        }
    }

    listenOnDownload(): void {
        this.reportRenderingSubject$.asObservable().subscribe(() => {
            this.metricsLoaded = true;
        });
    }

    metricById(_index: number, metric: { id: string } | null | false) {
        return metric ? metric.id : undefined;
    }

    getChartHeight(metric: string, options: EChartsOption) {
        if (
            !Array.isArray(options.legend) &&
            metric === 'master-states' &&
            options.legend?.data?.length
        ) {
            return options.legend.data.length * 20 + 80 + 'px';
        }

        return '400px';
    }

    updateTableHeights() {}

    getColumns(options: TableOptions) {
        return options.columns.map(column => column.name);
    }

    getCurrentDate() {
        return moment().format('YYYY/MM/DD HH:mm');
    }

    isExpiring(expiration: number) {
        if (
            isBeforeNDaysFromTheDate(
                expiration,
                NUMBER_OF_DAYS_TO_START_EXPIRING,
            ) ||
            isInThePast(expiration)
        ) {
            return false;
        }

        return true;
    }
}
