import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnInit,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    Permission,
    PermissionGroup,
    Role,
    User,
} from '@myrtls/api-interfaces';

interface ModalInputData {
    permissionGroups: PermissionGroup[];
    userToEdit?: User;
}

@Component({
    selector: 'myrtls-invite-user-modal',
    templateUrl: './invite-user.modal.html',
    styleUrls: ['./invite-user.modal.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class InviteUserModal implements OnInit {
    readonly roles = Object.values(Role);
    editMode = false;
    selectedRoleButton: Role | null = null;
    activePermissionsIds: string[] = [];
    inviteUserForm = this.fb.group({
        email: ['', [Validators.email, Validators.required]],
        name: ['', [Validators.required]],
    });
    idsToRolesMap: { [role in Role]?: string[] } = {};

    constructor(
        private readonly fb: UntypedFormBuilder,
        public dialogRef: MatDialogRef<string>,
        @Inject(MAT_DIALOG_DATA) public data: ModalInputData,
    ) {}

    ngOnInit(): void {
        if (this.data.permissionGroups) {
            const permissions = this.data.permissionGroups.reduce<Permission[]>(
                (acc, { permissions }) => [...acc, ...permissions],
                [],
            );

            this.idsToRolesMap = Object.values(Role).reduce(
                (acc, role) => ({
                    ...acc,
                    [role]: permissions
                        .map(({ roles, id }) =>
                            roles.includes(role) ? id : null,
                        )
                        .filter(id => id != null),
                }),
                {},
            );

            const readSitePermissionId = permissions.find(
                permission => permission.name === 'site_read',
            )?.id;

            if (readSitePermissionId) {
                this.activePermissionsIds.push(readSitePermissionId);
            }
        }

        if (this.data.userToEdit?.permissions) {
            this.inviteUserForm.controls['email'].setValue(
                this.data.userToEdit.email,
            );
            this.inviteUserForm.controls['email'].disable();
            this.inviteUserForm.controls['name'].setValue(
                this.data.userToEdit.name,
            );
            this.editMode = true;
            this.activePermissionsIds = this.data.userToEdit.permissions.map(
                permission => permission.id,
            );
        }
        this.checkRoles();
    }

    changePermissions(): void {
        if (!this.inviteUserForm.valid) {
            this.inviteUserForm.markAsTouched();
            return;
        }

        if (this.editMode) {
            this.dialogRef.close({
                name: this.inviteUserForm.value.name,
                permissions: this.activePermissionsIds,
            });
            return;
        } else {
            this.dialogRef.close({
                ...this.inviteUserForm.value,
                permissions: this.activePermissionsIds,
            });
        }
    }

    toggleButtonChange({ value }: MatButtonToggleChange): void {
        const activeRole = value.toLowerCase() as Role;
        this.activePermissionsIds = this.idsToRolesMap[activeRole] || [];
        this.checkRoles();
    }

    checkboxChange({ checked, source: { id } }: MatCheckboxChange): void {
        if (checked) {
            this.activePermissionsIds = [...this.activePermissionsIds, id];
        } else {
            this.activePermissionsIds = this.activePermissionsIds.filter(
                permissionId => permissionId !== id,
            );
        }
        this.checkRoles();
    }

    checkRoles(): void {
        let roleHasChanged = false;
        this.roles
            .slice()
            .reverse()
            .forEach(role => {
                const currentRole = role.toLowerCase() as Role;
                const isRoleChecked =
                    !this.idsToRolesMap[currentRole]?.find(
                        id => !this.activePermissionsIds.includes(id),
                    ) &&
                    !this.activePermissionsIds.find(
                        id => !this.idsToRolesMap[currentRole]?.includes(id),
                    );
                if (isRoleChecked) {
                    this.selectedRoleButton = role;
                    roleHasChanged = true;
                } else if (!isRoleChecked && !roleHasChanged) {
                    this.selectedRoleButton = null;
                }
            });
    }
}
