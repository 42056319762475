import { Action, createReducer, on } from '@ngrx/store';
import { Company } from '@myrtls/api-interfaces';
import {
    getCompaniesFailureAction,
    getCompaniesRequestAction,
    getCompaniesSuccessAction,
    selectCompanyAction,
} from './companies.actions';

export interface CompaniesState {
    companies: Company[];
    selectedCompanyId: string | null;
    loading: boolean;
    loaded: boolean;
}

const initialState: CompaniesState = {
    companies: [],
    selectedCompanyId: null,
    loading: false,
    loaded: false,
};

const reducer = createReducer(
    initialState,
    on(getCompaniesRequestAction, state => ({
        ...state,
        loading: true,
    })),
    on(getCompaniesSuccessAction, (state, { companies }) => ({
        ...state,
        companies: [...companies],
        loading: false,
        loaded: true,
    })),
    on(getCompaniesFailureAction, state => ({
        ...state,
        loading: false,
        loaded: true,
    })),
    on(selectCompanyAction, (state, { companyId }) => ({
        ...state,
        selectedCompanyId: companyId,
    })),
);

export function companiesReducer(
    state: CompaniesState | undefined,
    action: Action,
): CompaniesState {
    return reducer(state, action);
}
