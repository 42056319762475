<div class="flex flex-col p-5 container">
    <div class="mb-4 flex justify-between items-center">
        <div class="text-2xl font-medium">Companies</div>
        <mat-form-field>
            <mat-label>Filter Companies</mat-label>
            <input matInput (keyup)="applyFilter($event)" />
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
    </div>
    <div>
        <mat-card class="mb-4 cursor-pointer shadow-md hover:shadow-lg transition-shadow p-4" *ngFor="let company of companies$ | async" (click)="selectCompany(company.id)">
            <div class="text-xl">{{company.name}}</div>
        </mat-card>
    </div>
</div>
