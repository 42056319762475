import { Component, ViewChild } from '@angular/core';
import { InfluxdbService } from '../../core/services/influxdb.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import {
    $deviceCareConfig,
    $metricWithData,
} from '../../store/sites/sites.selectors';
import { filter, withLatestFrom } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import * as moment from 'moment';

interface OfflineAnchor {
    id: string;
    ip: string;
    mac: string;
    alias: string;
    master: string;
    status: string;
    offlineSince: string;
}
@Component({
    selector: 'myrtls-anchor-count-v2-modal',
    templateUrl: './anchor-count-v2.modal.html',
    styleUrls: [],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AnchorCountV2Modal {
    @ViewChild(MatSort) sort!: MatSort;

    metric = 'anchor-count' as const;

    columns = ['alias', 'ip', 'mac', 'master', 'status', 'offline since'];
    dataSource = new MatTableDataSource<OfflineAnchor>([]);
    loading = true;

    private offlineAnchorsQuery = `from(bucket: "$BUCKET")
    |> range(start: -2h)
    |> filter(fn: (r) => r._measurement == "online_anchors")
    |> filter(fn: (r) => r._field == "online")
    |> last()
    |> group(columns: ["title"])
    |> sort(columns: ["_time"])
    |> last()
    |> filter(fn: (r) => not contains(value: r["title"], set: $OVERRIDES))`;

    private anchorDetailsQuery = `from(bucket: "$BUCKET")
    |> range(start: -2h)
    |> filter(fn: (r) => r._measurement == "anchor_details")
    |> last()
    |> pivot(
       rowKey:["title"],
       columnKey: ["_field"],
       valueColumn: "_value"
    )
    |> group()
    |> drop(columns: ["_start", "_stop", "_measurement", "host", "url"])`;

    private anchorsLastChangeQuery = `from(bucket: "$BUCKET")
    |> range(start: -1d)
    |> filter(fn: (r) => r._measurement == "online_anchors")
    |> filter(fn: (r) => r._field == "online")
    |> difference()
    |> filter(fn: (r) => r._value < 0)
    |> last()`;

    private offlineAnchorsIds: string[] = [];
    private offlineAnchors: OfflineAnchor[] = [];

    constructor(
        private store: Store<AppState>,
        private influxdbService: InfluxdbService,
    ) {
        this.store
            .select($deviceCareConfig)
            .pipe(
                filter(config => !!config),
                withLatestFrom(this.store.select($metricWithData(this.metric))),
            )
            .subscribe(async ([config, metric]) => {
                if (!config || !metric) return;

                this.offlineAnchors = [];
                this.offlineAnchorsIds = [];

                await this.influxdbService
                    .getDashboardChartData({
                        apiUrl: config.influx.host,
                        query: this.offlineAnchorsQuery,
                        org: config.influx.organization,
                        token: config.influx.readToken,
                        bucket: config.influx.bucket,
                        overrides:
                            metric.overrides?.map(override => override.value) ||
                            [],
                    })
                    .then(data => {
                        data.forEach(anchor => {
                            if (
                                anchor._value !== undefined &&
                                anchor._value < 1
                            ) {
                                this.offlineAnchorsIds.push(String(anchor.id));
                            }
                        });
                    });

                await this.influxdbService
                    .getDashboardChartData({
                        apiUrl: config.influx.host,
                        query: this.anchorDetailsQuery,
                        org: config.influx.organization,
                        token: config.influx.readToken,
                        bucket: config.influx.bucket,
                        overrides:
                            metric.overrides?.map(override => override.value) ||
                            [],
                    })
                    .then(data => {
                        data.forEach(anchor => {
                            if (
                                this.offlineAnchorsIds.includes(
                                    String(anchor.id),
                                )
                            ) {
                                const offlineAnchor: OfflineAnchor = {
                                    id: String(anchor.id),
                                    ip: String(anchor.ip),
                                    mac: String(anchor.title),
                                    alias: String(anchor.alias),
                                    master: String(anchor.master),
                                    status: 'offline',
                                    offlineSince: 'longer than 24 hours',
                                };

                                this.offlineAnchors.push(offlineAnchor);
                            }
                        });
                    });

                await this.influxdbService
                    .getDashboardChartData({
                        apiUrl: config.influx.host,
                        query: this.anchorsLastChangeQuery,
                        org: config.influx.organization,
                        token: config.influx.readToken,
                        bucket: config.influx.bucket,
                        overrides:
                            metric.overrides?.map(override => override.value) ||
                            [],
                    })
                    .then(data => {
                        data.forEach(anchor => {
                            if (anchor._time && anchor._time !== '') {
                                const foundAnchor = this.offlineAnchors.find(
                                    item => item.id === String(anchor.id),
                                );
                                if (foundAnchor) {
                                    foundAnchor.offlineSince = moment(
                                        anchor._time,
                                    ).format('YYYY-MM-DD HH:mm:ss');
                                }
                            }
                        });
                    });

                this.dataSource.data = [...this.offlineAnchors];
                this.sortData({
                    active: 'master',
                    direction: 'desc',
                });
                this.loading = false;
            });
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    sortData(sort: Sort) {
        const isAsc = sort.direction === 'asc';
        const key = sort.active as keyof OfflineAnchor;

        this.dataSource.data = this.offlineAnchors
            .sort((a, b) => {
                const aValue = a[key];
                const bValue = b[key];

                if (aValue == null && bValue == null) return 0;
                if (aValue == null) return isAsc ? -1 : 1;
                if (bValue == null) return isAsc ? 1 : -1;

                return this.compare(aValue, bValue, isAsc);
            })
            .slice();
    }

    private compare(
        a: string | boolean,
        b: string | boolean,
        isAsc: boolean,
    ): number {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
}
