import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from '../../shared/shared.module';
import { DateIntervalModule } from '../date-interval/date-interval.module';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { AnchorCountV2Modal } from './anchor-count-v2.modal';
import { TagBatteriesV2Modal } from './tag-batteries-v2.modal';
import { HddV1Modal } from './hdd-v1.modal';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UptimeV1Modal } from './uptime-v1.modal';
import { SystemPerformanceV1Modal } from './system-performance-v1.modal';
import { IconComponent } from '../icon/icon.component';
import { InfoCardComponent } from '../info-card/info-card.component';
import { InactiveTagsV2Modal } from './inactive-tags-v2.modal';

@NgModule({
    declarations: [
        AnchorCountV2Modal,
        TagBatteriesV2Modal,
        HddV1Modal,
        UptimeV1Modal,
        SystemPerformanceV1Modal,
        InactiveTagsV2Modal,
    ],
    exports: [
        AnchorCountV2Modal,
        TagBatteriesV2Modal,
        HddV1Modal,
        UptimeV1Modal,
        SystemPerformanceV1Modal,
        InactiveTagsV2Modal,
    ],
    imports: [
        SharedModule,
        MatDialogModule,
        MatButtonModule,
        DateIntervalModule,
        MatTableModule,
        MatExpansionModule,
        MatChipsModule,
        MatSortModule,
        MatFormFieldModule,
        MatInputModule,
        InfoCardComponent,
        IconComponent,
    ],
})
export class MetricModalSModule {}
