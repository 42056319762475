import {
    AbstractControl,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';

export function hasNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!/\d/.test(control.value)) {
            return {
                hasNumberValidator: true,
            };
        }

        return null;
    };
}

export function hasLowerCaseValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!/[a-z]/.test(control.value)) {
            return {
                hasLowerCaseValidator: true,
            };
        }

        return null;
    };
}

export function passwordsMatchValidator(
    controlName: string,
    matchingControlName: string,
): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
        if (!(formGroup instanceof UntypedFormGroup)) {
            return null;
        }

        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ passwordsMatchValidator: true });
        } else {
            matchingControl.setErrors(null);
        }

        return null;
    };
}
