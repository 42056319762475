import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import {
    sendPasswordResetEmail,
    submitNewPassword,
} from 'supertokens-web-js/recipe/emailpassword';
import { RoutePath } from '../../shared/models/route-path.enum';
import { SnackBarType } from '../../shared/models/snack-bar-config.model';
import {
    hasLowerCaseValidator,
    hasNumberValidator,
    passwordsMatchValidator,
} from '../../shared/validators/password.validator';
import { showSnackBarAction } from '../../store/snack-bar/snack-bar.actions';

@Component({
    selector: 'myrtls-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
    private readonly unsubscribe$ = new Subject<void>();

    token = undefined;

    resetPasswordForm!: UntypedFormGroup;
    sendPasswordResetForm!: UntypedFormGroup;

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly router: Router,
        private route: ActivatedRoute,
        private readonly store: Store<AppState>,
    ) {
        this.sendPasswordResetForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
        });

        this.resetPasswordForm = this.fb.group(
            {
                password: [
                    '',
                    [
                        Validators.required,
                        Validators.minLength(8),
                        hasNumberValidator(),
                        hasLowerCaseValidator(),
                    ],
                ],
                passwordConfirmation: ['', [Validators.required]],
            },
            {
                validators: [
                    passwordsMatchValidator('password', 'passwordConfirmation'),
                ],
            },
        );
    }

    ngOnInit(): void {
        this.route.queryParams
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(params => {
                if (params.token) {
                    this.token = params.token;
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    async send() {
        const response = await sendPasswordResetEmail({
            formFields: [
                {
                    id: 'email',
                    value: this.sendPasswordResetForm.controls['email'].value,
                },
            ],
        });

        switch (response.status) {
            case 'OK':
                this.store.dispatch(
                    showSnackBarAction({
                        config: {
                            type: SnackBarType.SUCCESSFUL,
                            description:
                                'Instructions sent to selected email address.',
                            actionLabel: 'Dismiss',
                        },
                    }),
                );
                await this.router.navigate([RoutePath.AUTH, RoutePath.LOGIN]);
                window.location.reload();
                return;
            case 'FIELD_ERROR':
                this.store.dispatch(
                    showSnackBarAction({
                        config: {
                            type: SnackBarType.FAILED,
                            description: 'Email address is not valid.',
                            actionLabel: 'Dismiss',
                        },
                    }),
                );
                return;
        }
    }

    async reset() {
        const response = await submitNewPassword({
            formFields: [
                {
                    id: 'password',
                    value: this.resetPasswordForm.controls['password'].value,
                },
            ],
        });

        switch (response.status) {
            case 'OK':
                this.store.dispatch(
                    showSnackBarAction({
                        config: {
                            type: SnackBarType.SUCCESSFUL,
                            description: 'New password has been set.',
                            actionLabel: 'Dismiss',
                        },
                    }),
                );
                await this.router.navigate([RoutePath.AUTH, RoutePath.LOGIN]);
                window.location.reload();
                return;
            case 'RESET_PASSWORD_INVALID_TOKEN_ERROR':
                this.store.dispatch(
                    showSnackBarAction({
                        config: {
                            type: SnackBarType.FAILED,
                            description:
                                'Reset token is not valid. Please try again.',
                            actionLabel: 'Dismiss',
                        },
                    }),
                );
                await this.router.navigate([RoutePath.AUTH, RoutePath.LOGIN]);
                window.location.reload();
                return;
            case 'FIELD_ERROR':
                this.store.dispatch(
                    showSnackBarAction({
                        config: {
                            type: SnackBarType.FAILED,
                            description:
                                "New password doesn't meet the criteria. Please try again.",
                            actionLabel: 'Dismiss',
                        },
                    }),
                );
                return;
        }
    }

    async redirectToLogin($event: MouseEvent) {
        $event.preventDefault();
        await this.router.navigate([RoutePath.AUTH, RoutePath.LOGIN]);
        window.location.reload();
    }
}
