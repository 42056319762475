<div class="report-wrapper" *ngIf="selectedPeriod$ | async as period">
    <ng-container *ngIf="getFilenameFromDateRange(period.dateRange, period.from, period.to) as reportDate">
        <div class="page-wrapper start-background flex flex-col p-14 justify-between">
            <img src="assets/logos/sewio-hid-logo-color-safe-zone-rgb.png" class="w-[200px]" />
            <div class="flex flex-col h-1/2 px-14">
                <ng-container *ngIf="selectedSiteName$ | async as selectedSiteName">
                    <ng-container *ngIf="selectedCompanyName$ | async as selectedCompanyName">
                        <div class="text-brand font-medium text-[50px] mb-14">myRTLS Report</div>
                        <div class="flex flex-col text-[20px]">
                            <div>{{ selectedSiteName }}</div>
                            <div>{{ selectedCompanyName }}</div>
                            <div>{{ reportDate }}</div>
                            <ng-container *ngIf="currentLicense$ | async as license">
                                <div *ngIf="isExpiring(license.expiration)" class="text-warning mt-7">Your myRTLS license will expire soon.</div>
                            </ng-container>
                        </div>
                        <div *ngIf="metricsLoaded && alertsLoaded" id="report-title"></div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <div class="page-break p-8 flex flex-col min-h-[100vh]">
            <div class="flex pb-4 mb-4 justify-between border-b border-dark-300">
                <div>System Overview</div>
                <div>{{ reportDate }}</div>
            </div>
            <div class="flex-grow">
                <myrtls-ratio-bar-widget [data]="stackedChartData$ | async" [showInfo]="false"></myrtls-ratio-bar-widget>
                <myrtls-single-stat-widget [data]="singleStatWidgetData$ | async" [showInfo]="false"></myrtls-single-stat-widget>
            </div>
            <div class="flex pt-4 mt-4 justify-between border-t border-dark-300">
                <div></div>
                <img src="assets/logos/sewio-hid-logo-color-safe-zone-rgb.png" class="w-[100px]" />
            </div>
        </div>

        <ng-container *ngIf="paginatedData.length > 0; else noAlerts">
            <ng-container *ngFor="let page of paginatedData; let pageIndex = index">
                <div class="page-break p-8 min-h-[100vh] flex flex-col">
                    <div class="flex pb-4 mb-4 justify-between border-b border-dark-300">
                        <div>
                            Pending Alerts<span *ngIf="pagesCount !== 1" class="text-xs mx-2 text-dark-400"> page {{ pageIndex + 1 }} out of {{ pagesCount }}</span>
                        </div>
                        <div>{{ reportDate }}</div>
                    </div>
                    <div class="flex-grow">
                        <table #alerts mat-table [dataSource]="page" class="break-inside-auto">
                            <ng-container matColumnDef="metric">
                                <th mat-header-cell *matHeaderCellDef>Metric</th>
                                <td mat-cell *matCellDef="let element">
                                    <span class="text-error">{{ element.metric }}</span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="metadata">
                                <th mat-header-cell *matHeaderCellDef>ID</th>
                                <td mat-cell *matCellDef="let element">{{ element.metadata }}</td>
                            </ng-container>

                            <ng-container matColumnDef="from">
                                <th mat-header-cell *matHeaderCellDef>Alert pending since</th>
                                <td mat-cell *matCellDef="let element">{{ element.from | date: 'yyyy-MM-dd HH:mm:ss' }}</td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="columns"></tr>
                            <tr mat-row *matRowDef="let row; columns: columns"></tr>
                        </table>
                    </div>

                    <div class="flex pt-4 mt-4 justify-between border-t border-dark-300">
                        <div></div>
                        <img src="assets/logos/sewio-hid-logo-color-safe-zone-rgb.png" class="w-[100px]" />
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-template #noAlerts>
            <div class="page-break p-8 min-h-[100vh] flex flex-col">
                <div class="flex pb-4 mb-4 justify-between border-b border-dark-300">
                    <div>Pending Alerts</div>
                    <div>{{ reportDate }}</div>
                </div>
                <div class="flex-grow">
                    <div class="text-center text-base">No pending alerts.</div>
                </div>
                <div class="flex pt-4 mt-4 justify-between border-t border-dark-300">
                    <div></div>
                    <img src="assets/logos/sewio-hid-logo-color-safe-zone-rgb.png" class="w-[100px]" />
                </div>
            </div>
        </ng-template>

        <ng-container *ngIf="siteReportMetrics$ | async as reportMetrics">
            <ng-container *ngIf="chartOptions$ | async as metrics">
                <ng-container *ngFor="let metric of metrics | sortMetricsByIdsArray: reportMetrics; trackBy: metricById">
                    <div class="page-break p-8 min-h-[100vh] flex flex-col" *ngIf="metric && reportMetrics.includes(metric.id)">
                        <div class="flex pb-4 mb-4 justify-between border-b border-dark-300">
                            <div>
                                {{ metric.name }}<span class="text-xs mx-2 text-dark-400">{{ metric.nameSuffix }}</span>
                            </div>
                            <div>{{ reportDate }}</div>
                        </div>
                        <div class="flex-grow">
                            <div *ngIf="metric.options as options" class="canvas-wrapper" echarts [options]="options" [style.height]="getChartHeight(metric.id, options)" (chartFinished)="onChartRendered(metric.id, reportMetrics)"></div>
                            <div #table class="table-container" *ngIf="metric.tableOptions as options" [attr.metricId]="metric.id" [attr.reportMetrics]="reportMetrics">
                                <table class="break-inside-auto" mat-table [dataSource]="options.rows">
                                    <ng-container *ngFor="let column of options.columns" [matColumnDef]="column.name">
                                        <th mat-header-cell *matHeaderCellDef>{{ column.alias }}</th>
                                        <td mat-cell *matCellDef="let element" [style.color]="element[column.name].color ? element[column.name].color : '#000'">
                                            <myrtls-state-timeline *ngIf="Array.isArray(element[column.name].value)" class="w-[500px]" [data]="element[column.name].value" [colorMapping]="element[column.name].colorMapping"></myrtls-state-timeline>
                                            <div *ngIf="!Array.isArray(element[column.name].value)">{{ element[column.name].value }}</div>
                                        </td>
                                    </ng-container>

                                    <tr class="no-data-row" *matNoDataRow>
                                        <td class="mat-cell no-data" colspan="9999">{{ options.noDataText || 'No data found.' }}</td>
                                    </tr>
                                    <tr mat-header-row *matHeaderRowDef="getColumns(options); sticky: false"></tr>
                                    <tr class="break-inside-avoid break-after-auto h-6 odd:bg-dark-50" mat-row *matRowDef="let row; columns: getColumns(options)"></tr>
                                </table>
                            </div>
                        </div>
                        <div class="flex pt-4 mt-4 justify-between border-t border-dark-300">
                            <div></div>
                            <img src="assets/logos/sewio-hid-logo-color-safe-zone-rgb.png" class="w-[100px]" />
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
        <div class="page-wrapper end-background flex flex-col p-14 justify-between">
            <img src="assets/logos/sewio-hid-logo-color-safe-zone-rgb.png" class="w-[200px]" />
            <div class="flex flex-col h-1/2 px-14">
                <div class="text-brand font-medium text-[50px] mb-14">Thank you<br />for using our product</div>
                <div class="flex flex-col text-[20px]">
                    <div>If you have any questions or feedback, please <a target="_blank" href="https://docs.sewio.net/docs/how-to-make-a-support-ticket-25593561.html">open a ticket</a> and reach out to our CX team.</div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
