<div class="login-container">
    <form class="card" [formGroup]="loginForm">
        <div class="logo-container mb-6">
            <img class="logo" src="assets/logos/sewio_full.png" />
        </div>
        <div class="welcome-container mb-4">Welcome</div>
        <div class="message-container mb-4">Log in to Sewio Networks s.r.o. to continue to myRTLS (On-Prem).</div>
        <mat-form-field class="mb-2" appearance="fill" [hideRequiredMarker]="true">
            <mat-label>Email address</mat-label>
            <input type="email" matInput name="reset-password-email" formControlName="email" />
            <mat-error *ngIf="loginForm.controls['email'].hasError('required'); else loginFormErrors2"> Not a valid email address. </mat-error>
            <ng-template #loginFormErrors2>
                <mat-error *ngIf="loginForm.controls['email'].hasError('email')"> Not a valid email address. </mat-error>
            </ng-template>
        </mat-form-field>
        <mat-form-field class="mb-2" appearance="fill" [hideRequiredMarker]="true">
            <mat-label>Password</mat-label>
            <input [type]="hidePassword ? 'password' : 'text'" matInput formControlName="password" />
            <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
            <mat-error *ngIf="loginForm.controls['password'].hasError('required')"> Password is required. </mat-error>
        </mat-form-field>
        <div class="forgot-container mb-4">
            <a *ngIf="(smtpStatus$ | async)?.enabled; else noSmtp" href="#" (click)="redirectToPasswordReset($event)">Forgot password?</a>
            <ng-template #noSmtp>
                <span class="text-sm text-gray-400 cursor-default" title="SMTP server is not configured.">Forgot password?</span>
            </ng-template>
        </div>
        <button mat-flat-button color="primary" type="submit" [disabled]="loginForm.invalid && loginForm.touched" (click)="login()">Continue</button>
    </form>
</div>
