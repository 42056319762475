import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'myrtls-chip',
    templateUrl: './chip.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipComponent {
    @Input() text = '';
    @Input() type: 'error' | 'info' | 'success' | 'default' = 'default';
}
