import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'humanizeDuration' })
export class HumanizeDurationPipe implements PipeTransform {
    transform(duration: number): string {
        const momentDuration = moment.duration(duration, 'seconds');
        let durationString = '';

        if (momentDuration.hours() > 0 || momentDuration.days() > 0) {
            let hours = 0;

            hours += momentDuration.days() * 24;
            hours += momentDuration.hours();

            durationString += ' ' + hours + (hours > 1 ? ' hours' : ' hour');
        }

        if (momentDuration.minutes() > 0) {
            durationString +=
                ' ' +
                momentDuration.minutes() +
                (momentDuration.minutes() > 1 ? ' minutes' : ' minute');
        }

        return durationString.trim();
    }
}
