import { HttpErrorResponse } from '@angular/common/http';
import { PermissionGroup } from '@myrtls/api-interfaces';
import { createAction, props } from '@ngrx/store';

export const getPermissionsRequestAction = createAction(
    '[Permissions] Get Permissions Request',
);

export const getPermissionsSuccessAction = createAction(
    '[Permissions] Get Permissions Success',
    props<{ groups: PermissionGroup[] }>(),
);

export const getPermissionsFailureAction = createAction(
    '[Permissions] Get Permissions Failure',
    props<{ error: HttpErrorResponse }>(),
);
