import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DeviceCareInstallationComponent } from './device-care-installation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClipboardModule } from '@angular/cdk/clipboard';
import {
    MatRadioModule,
    MAT_RADIO_DEFAULT_OPTIONS,
} from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
    declarations: [DeviceCareInstallationComponent],
    exports: [DeviceCareInstallationComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatRadioModule,
        MatIconModule,
        ClipboardModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatStepperModule,
        MatProgressSpinnerModule,
        MatTabsModule,
    ],
    providers: [
        {
            provide: MAT_RADIO_DEFAULT_OPTIONS,
            useValue: { color: 'primary' },
        },
    ],
})
export class DeviceCareInstallationModule {}
