<div class="flex flex-col p-5 container">
    <div class="mb-4 flex justify-between items-center">
        <div class="text-2xl font-medium">Sites</div>
        <mat-form-field>
            <mat-label>Filter Sites</mat-label>
            <input matInput (keyup)="applyFilter($event)" />
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
    </div>
    <div>
        <mat-card class="flex gap-2 flex-row items-center flex-wrap justify-between mb-4 cursor-pointer shadow-md hover:shadow-lg transition-shadow p-4" (click)="navigateToSite(site.id)" *ngFor="let site of sites$ | async">
            <div class="text-xl">S{{ site.id }} - {{site.name}}</div>
            <div class="text-base" [ngClass]="{ 'text-success': !isExpired(site.license.expiration) && !isExpiring(site.license.expiration),'text-warning': isExpiring(site.license.expiration) && !isExpired(site.license.expiration),'text-error': isExpired(site.license.expiration),  }">
                {{formatLicense(site.license)}}
            </div>
        </mat-card>
    </div>
</div>
