import { Component, Input, OnInit } from '@angular/core';

export type ProfilePictureSize = 'small' | 'medium' | 'large';

@Component({
    selector: 'myrtls-profile-picture',
    templateUrl: './profile-picture.component.html',
    styleUrls: ['./profile-picture.component.css'],
})
export class ProfilePictureComponent implements OnInit {
    @Input() src!: string;
    @Input() size!: ProfilePictureSize;
    @Input() rounded!: boolean;

    ngOnInit(): void {
        this.src = this.src || 'assets/favicon.png';
        this.size = this.size || 'small';
        this.rounded = this.rounded === false ? false : true;
    }
}
