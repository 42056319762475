import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { SiteDeploymentType } from '@myrtls/api-interfaces';

if (environment.deployment !== SiteDeploymentType.ON_PREM) {
    Sentry.init({
        dsn: 'https://db250f258209198210f6719017ba1328@o4506863256338432.ingest.us.sentry.io/4506864266969088',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        environment: environment.production ? 'prod' : 'dev',
        tracesSampleRate: 1.0,
        tracePropagationTargets: [
            'localhost',
            /^https:\/\/(dev\.)?myrtls\.com\/api/,
        ],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
