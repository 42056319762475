import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
    catchError,
    filter,
    map,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { COMPANIES_API_URL } from '../../shared/utils/constants';
import { AppState } from '../index';
import { selectSiteAction } from '../sites/sites.actions';
import { $selectedSite } from '../sites/sites.selectors';
import {
    getCompaniesFailureAction,
    getCompaniesRequestAction,
    getCompaniesSuccessAction,
    selectCompanyAction,
} from './companies.actions';
import { Company } from '@myrtls/api-interfaces';
import { ActivatedRoute, ActivationStart, Router } from '@angular/router';

@Injectable()
export class CompaniesEffects {
    private httpWithoutAuth: HttpClient;

    getCompanies$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(getCompaniesRequestAction),
                withLatestFrom(
                    this.router.events.pipe(
                        filter(
                            (event): event is ActivationStart =>
                                event instanceof ActivationStart,
                        ),
                    ),
                ),
            )
            .pipe(
                switchMap(([, event]) => {
                    const params = event.snapshot.queryParams;
                    let httpCompanies;

                    if (params.serverToken) {
                        httpCompanies = this.httpWithoutAuth.get<Company[]>(
                            COMPANIES_API_URL,
                            {
                                headers: {
                                    Authorization:
                                        'Bearer ' + params.serverToken,
                                },
                            },
                        );
                    } else {
                        httpCompanies =
                            this.http.get<Company[]>(COMPANIES_API_URL);
                    }

                    return httpCompanies.pipe(
                        map(companies => {
                            if (companies.length === 1) {
                                this.store.dispatch(
                                    selectCompanyAction({
                                        companyId: companies[0].id || null,
                                        resetSite: true,
                                    }),
                                );
                            }
                            return getCompaniesSuccessAction({ companies });
                        }),
                        catchError(error =>
                            of(getCompaniesFailureAction({ error })),
                        ),
                    );
                }),
            ),
    );

    resetSiteOnCompanySelect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectCompanyAction),
            withLatestFrom(
                this.store.pipe(
                    select($selectedSite),
                    filter(site => site != null),
                ),
            ),
            filter(([{ companyId, resetSite }, site]) => {
                return (
                    resetSite &&
                    site?.companyId !== undefined &&
                    site.companyId !== companyId
                );
            }),
            map(() => selectSiteAction({ siteId: null })),
        ),
    );

    constructor(
        private readonly http: HttpClient,
        readonly backend: HttpBackend,
        private readonly actions$: Actions,
        private readonly store: Store<AppState>,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
    ) {
        this.httpWithoutAuth = new HttpClient(backend);
    }
}
